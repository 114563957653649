import React, { Fragment } from 'react';
import { Button } from 'antd';

const styles = { marginTop: '8px', padding: '0', width: '80px' };

interface FilterOptionsProps {
  confirmFilter: Function;
  resetFilter: Function;
}

export const FilterOptions = ({ confirmFilter, resetFilter }: FilterOptionsProps) => {
  return (
    <Fragment>
      <Button
        type="link"
        size="small"
        onClick={() => confirmFilter()}
        style={{ ...styles, textAlign: 'left' }}
      >
        OK
      </Button>
      <Button
        type="link"
        size="small"
        onClick={() => resetFilter()}
        style={{
          ...styles,
          textAlign: 'right',
          float: 'right'
        }}
      >
        Reset
      </Button>
    </Fragment>
  );
};

FilterOptions.displayName = 'FilterOptions';
