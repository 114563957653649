import React from 'react';
import PropTypes from 'prop-types';
import { Form, Row, Col, Input } from 'antd';

const formItemStyle = { marginBottom: '1px' };

const AddAircraftFormInput = ({
  name,
  label,
  getFieldDecorator,
  validationRule = { required: false },
  placeholder,
  handleFieldChange
}) => (
  <Form.Item name={name} label={label} style={formItemStyle}>
    <Row>
      <Col span={16}>
        {getFieldDecorator(name, {
          rules: [validationRule]
        })(
          <Input
            autoComplete="off"
            placeholder={placeholder}
            style={{ width: '300px', height: '40px' }}
            onChange={handleFieldChange}
          />
        )}
      </Col>
    </Row>
  </Form.Item>
);

AddAircraftFormInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  getFieldDecorator: PropTypes.func.isRequired,
  validationRule: PropTypes.object,
  placeholder: PropTypes.string,
  handleFieldChange: PropTypes.func
};

export default AddAircraftFormInput;
