import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { PageHeader, Button, Modal } from 'antd';
import ExclamationCircleFilled from '@ant-design/icons/ExclamationCircleFilled';
import { useParams } from 'react-router';
import GeofenceTable from './GeofenceTable';
import { getInstance } from 'common/api/spidertracks-sdk';
import { getAltitudeUnit } from '../../../redux/selectors/userData';
import { getGeofenceInfoAltitude, displayEventType } from '../../Flying/Map/utils/helper';
import { GeofenceDrawer } from './GeofenceDrawer';
import { geofenceDeleteSuccess, geofenceDeleteError } from '../../../helpers/globalNotifications';

export const Geofence = () => {
  const { organisationId } = useParams();
  const SpidertracksSDK = getInstance();
  const [geofences, setGeofences] = useState([]);
  const [geofenceNameFilterList, setGeofenceNameFilterList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const altitudeUnit = useSelector(getAltitudeUnit);
  const [createGeofenceDrawerVisibility, setCreateGeofenceDrawerVisibility] = useState(false);

  const openDrawer = () => setCreateGeofenceDrawerVisibility(true);
  const closeDrawer = () => setCreateGeofenceDrawerVisibility(false);

  const fetchGeofences = useCallback(async () => {
    setIsLoading(true);

    try {
      const geofenceData = await SpidertracksSDK.getGeofencesService().getGeofences(organisationId);
      const data = geofenceData.features.map(feature => {
        const featureProperties = feature.properties;
        return {
          feature,
          key: featureProperties.id,
          name: featureProperties.name,
          altitude: getGeofenceInfoAltitude({
            altitudeUnit,
            altitudeContext: featureProperties.altitudeContext,
            altitudeLimit: featureProperties.altitudeLimit
          }),
          eventType: displayEventType(featureProperties.triggeringEvent),
          rawEventType: featureProperties.triggeringEvent || 'breach_any',
          action: 'Delete'
        };
      });
      setGeofences(data);
      setGeofenceNameFilterList(
        Object.values(
          geofenceData.features.reduce((acc, feat) => {
            acc[feat.properties.name] = {
              value: feat.properties.name,
              label: feat.properties.name,
              key: feat.properties.name
            };
            return acc;
          }, {})
        )
      );
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  }, [SpidertracksSDK, organisationId, altitudeUnit]);

  const save = useCallback(
    async geofence => {
      if (organisationId) {
        geofence.properties.organisationId = organisationId;
        const { code, data } = await SpidertracksSDK.getGeofencesService().saveGeofence(geofence);

        if (code === 600201 || code === 600206) {
          fetchGeofences();
          return data;
        } else {
          throw new Error(`API Error with code: ${code}`);
        }
      } else {
        throw new Error('No Organisation Id');
      }
    },
    [SpidertracksSDK, fetchGeofences, organisationId]
  );

  const deleteGeofence = useCallback(
    async geofenceId => {
      setIsLoading(true);
      try {
        if (geofenceId) {
          await SpidertracksSDK.getGeofencesService().deleteGeofence(geofenceId);
          geofenceDeleteSuccess();
          fetchGeofences();
        } else {
          throw new Error('Error getting geofence ID');
        }
      } catch (e) {
        console.error(e);
        geofenceDeleteError();
      } finally {
        setIsLoading(false);
      }
    },
    [SpidertracksSDK, fetchGeofences]
  );

  const onDeleteClick = useCallback(
    async geofenceId => {
      Modal.confirm({
        title: 'Delete Geofence',
        icon: <ExclamationCircleFilled />,
        content: 'Are you sure you want to delete this geofence?',
        okText: 'Delete',
        onOk: () => {
          deleteGeofence(geofenceId);
        }
      });
    },
    [deleteGeofence]
  );

  useEffect(() => {
    if (organisationId && altitudeUnit && fetchGeofences) {
      fetchGeofences();
    }
  }, [organisationId, altitudeUnit, fetchGeofences]);

  return (
    <React.Fragment>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <PageHeader className="site-page-header" style={{ paddingLeft: '0px' }} title="Geofence" />
        <Button
          size="large"
          onClick={openDrawer}
          type="primary"
          ghost
          style={{ padding: '0 50px' }}
        >
          Add
        </Button>
      </div>
      <GeofenceTable
        geofenceNameFilterList={geofenceNameFilterList}
        geofences={geofences}
        isLoading={isLoading}
        deleteGeofence={onDeleteClick}
      />
      {createGeofenceDrawerVisibility && (
        <GeofenceDrawer
          visibility={createGeofenceDrawerVisibility}
          onClose={closeDrawer}
          save={save}
        />
      )}
    </React.Fragment>
  );
};

Geofence.displayName = 'Geofence';

export default Geofence;
