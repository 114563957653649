import Flight3DPlayer from '@spidertracks/flight-3d-player';
import { helpers } from '@spidertracks/flight-3d-player';
import { getInstance } from 'common/api/spidertracks-sdk';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { getCZML } from './getCZML';
import { getCzmlParts } from './getCzmlParts';
import { LoadingBackground } from '../LoadingBackground';
import { replayError } from '../../helpers/globalNotifications';

const MAX_POINTS = 500;

/**
 * Checks if the API response provided is not sufficient to build the part
 * @param {Object} part FlightDataPart
 * @param {Object} reponse FlightData API response
 */
const reponseNotCompletePart = (part, reponse) =>
  part.to > reponse.to &&
  !!reponse.nextToken &&
  (!!reponse.nextToken.ahrs || !!reponse.nextToken.position);

const Flight3dPlayerContainer = ({ trackId, stId, aircraftType }) => {
  const FLIGHT_DATA_SERVICE_INSTANCE = getInstance().getFlightDataService();

  const [terrainProvider, setTerrainProvider] = useState();

  const [czmlParts, setCzmlParts] = useState();

  const buildCZMLPartFromResponse = async (response, part, isFirstPart = false) => {
    let current = response;
    while (reponseNotCompletePart(part, current)) {
      //We didn't get all the data for the part in the response provided, so need to keep calling the api
      current = await FLIGHT_DATA_SERVICE_INSTANCE.get(
        trackId,
        stId,
        part.from,
        part.to,
        MAX_POINTS,
        current.nextToken.ahrs,
        current.nextToken.position
      );

      response.ahrs = [...response.ahrs, ...current.ahrs];
      response.position = [...response.position, ...current.position];
    }

    return getCZML(response, aircraftType, terrainProvider, isFirstPart);
  };

  const requestCzmlPart = async partIndex => {
    const part = czmlParts[partIndex];

    try {
      const response = await FLIGHT_DATA_SERVICE_INSTANCE.get(
        trackId,
        stId,
        part.from,
        part.to,
        MAX_POINTS
      );

      const czml = await buildCZMLPartFromResponse(response, part);

      setCzmlParts({
        ...czmlParts,
        [partIndex]: {
          ...part,
          czml
        }
      });
    } catch (err) {
      console.error(err);
      replayError();
    }
  };

  useEffect(() => {
    setTerrainProvider(helpers.getTerrainProvider());
  }, []);

  useEffect(() => {
    async function init() {
      if (!terrainProvider) {
        return;
      }
      try {
        let response = await FLIGHT_DATA_SERVICE_INSTANCE.get(
          trackId,
          stId,
          undefined,
          undefined,
          900
        );

        if (!czmlParts) {
          const parts = getCzmlParts(response);
          const firstPart = Object.values(parts)[0];
          firstPart.czml = await buildCZMLPartFromResponse(response, firstPart, true);
          setCzmlParts(parts);
        }
      } catch (e) {
        console.error(e);
        replayError();
      }
    }
    init();
  }, [terrainProvider]);
  return terrainProvider && czmlParts ? (
    <Flight3DPlayer
      czmlParts={czmlParts}
      requestCzmlPart={requestCzmlPart}
      terrainProvider={terrainProvider}
    />
  ) : (
    <LoadingBackground />
  );
};

Flight3dPlayerContainer.propTypes = {
  trackId: PropTypes.string.isRequired,
  stId: PropTypes.string.isRequired,
  aircraftType: PropTypes.string.isRequired
};

export default Flight3dPlayerContainer;
