import React from 'react';
import PropTypes from 'prop-types';
import { Form, Row, Col, Input, Checkbox } from 'antd';

const formItemStyle = { marginBottom: '1px' };

const centerStyle = {
  textAlign: 'center',
  padding: '6px 10px'
};

const SpiderSettingsInput = ({
  name,
  label,
  unit,
  getFieldDecorator,
  validation,
  setFieldsValue,
  getFieldValue,
  setSaveButtonDisabled
}) => {
  const fieldValue = getFieldValue(name);
  const checked = fieldValue !== 0;
  const checkboxStyle = { transform: 'scale(1.8)', marginLeft: '9px', paddingBottom: '4px' };
  const inputStyle = fieldValue === 0 ? { color: 'rgba(0,0,0,0)' } : {};

  const setInput = e => {
    setSaveButtonDisabled(false);
    setFieldsValue({ [name]: e.target.value });
  };

  const setCheckbox = e => {
    if (e.target.checked) {
      setFieldsValue({ [name]: undefined });
    } else {
      setFieldsValue({ [name]: 0 });
      setSaveButtonDisabled(false);
    }
  };

  const validator = (rule, value, callback) => {
    if (checked && value === '0') {
      return callback(true);
    }
    return callback();
  };

  return (
    <Form.Item name={name} label={label} style={formItemStyle}>
      <Row>
        <Col span={16}>
          <Checkbox checked={checked} style={checkboxStyle} onChange={setCheckbox} />
          {getFieldDecorator(name, {
            rules: [
              {
                required: true,
                pattern: new RegExp(validation.validationRegexPattern),
                message: validation.errorMessage
              },
              {
                validator,
                message: validation.errorMessage
              }
            ]
          })(
            <Input
              disabled={!checked}
              style={{ width: '78%', marginLeft: '20px', height: '40px', ...inputStyle }}
              autoComplete="off"
              onChange={setInput}
            />
          )}
        </Col>
        <Col className="d-flex" span={8} style={centerStyle}>
          {unit}
        </Col>
      </Row>
    </Form.Item>
  );
};

SpiderSettingsInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  getFieldDecorator: PropTypes.func.isRequired,
  getFieldValue: PropTypes.func.isRequired,
  setFieldsValue: PropTypes.func.isRequired,
  unit: PropTypes.string,
  validation: PropTypes.object,
  setSaveButtonDisabled: PropTypes.func
};

export default SpiderSettingsInput;
