import { History, Location } from 'history';
import { initializeInstance } from '../common/api/spidertracks-sdk';

export const onSuccessfulLogin = (
  history: History,
  { state = { referrer: '/' } }: Location<{ referrer: string }>
) => async () => {
  initializeInstance({ baseUrl: window['env'].STL_NODE_ORIGIN, isPublic: false });

  // NOTE: Remove any existing Sisense cookie, required in situations where two users access the
  // same browser tab.
  try {
    const logoutResult = await fetch(`${window.env.sisense.endpoint}/api/auth/logout`, {
      credentials: 'include'
    });
  } catch (e) {
    // If the Sisense /logout route hits this block, it won't be with a 403... this must be another
    // login error. The best thing here is to re-throw: this will hit the catch block in the signin
    // method, which can handle it appropriately (if it chooses to swallow the error that's cool
    // too).
    console.error('onSuccessfulLogin :: Sisense logout error', e);
    throw e;
  }

  history.push(state.referrer);
};

export default onSuccessfulLogin;
