interface SisenseDashbaord {
  name: string;
  id: string;
  widgets?: {
    name: string;
    id: string;
  }[];
}

interface GlobalConfig {
  firebaseConfig: {
    apiKey: string;
    authDomain: string;
    databaseURL: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
    measurementId: string;
  };
  sisense: {
    endpoint: string;
    dashboards: SisenseDashbaord[];
  };
  api: { name: string; endpoint: string; region: string };
  STL_GOOGLE_ANALYTICS_TRACKING: string;
  STL_GOOGLE_MAPS_API: string;
  STL_GO_ORIGIN: string;
  STL_NODE_ORIGIN: string;
  pendo: {
    disableGuides: boolean;
  };
}

export const getConfig = () => {
  // Typescript hack to make the window object behave with a known non standard property
  return ((window as unknown) as { env: GlobalConfig }).env;
};
