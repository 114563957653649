import { ServiceAbstract } from '../../ServiceAbstract';

interface Target {
  id: string;
  uri: string;
}

interface NotificationConfig {
  id: string;
  eventClass: string;
  eventType: string;
  eventTypeLabel: string;
  deliveryChannels: string[];
  users: string[];
  aircraft: string[];
  targets: Target[];
}

const mockResponse: NotificationConfig[] = [
  {
    id: '9e68441-2834-49b8-8e86-a36b158f9024',
    eventClass: 'geofence',
    eventType: 'breach_in',
    eventTypeLabel: 'Fence 1',
    deliveryChannels: ['sms', 'email'],
    users: ['user1', 'user2'],
    aircraft: ['aircraftId1', 'aircraftId2'],
    targets: [
      {
        id: '1a44477c-6579-4eb6-a884-33bda8500db3',
        uri: 'sms:+642102424091'
      },
      {
        id: '74e4db30-b653-4e3d-bcb1-3b90a39e1ebc',
        uri: 'mailto:cole.diffin@spidertracks.co.nz'
      }
    ]
  },
  {
    id: '7715f120-5f3b-4182-97ee-8ddd13d4bfef',
    eventClass: 'geofence',
    eventType: 'breach_in',
    eventTypeLabel: 'Fence 2',
    deliveryChannels: ['email'],
    users: ['user1', 'user2'],
    aircraft: ['aircraftId1', 'aircraftId2'],
    targets: [
      {
        id: '7f64d7f9-b975-4fb5-a996-b4b17ad28cd5',
        uri: 'sms:+642102424091'
      },
      {
        id: '0d94460f-7712-4fe2-8d00-91305b49b2d9',
        uri: 'mailto:cole.diffin@spidertracks.co.nz'
      }
    ]
  },
  {
    id: 'a4e85da9-71b5-47dd-ba59-afdeaee847f9',
    eventClass: 'geofence',
    eventType: 'breach_out',
    eventTypeLabel: 'Fence 3',
    deliveryChannels: ['sms'],
    users: ['user1', 'user2'],
    aircraft: ['aircraftId1', 'aircraftId2'],
    targets: [
      {
        id: '1d0c5409-c5f3-487d-9d05-6a89b92f4e5c',
        uri: 'sms:+642102424091'
      },
      {
        id: 'a6d779ef-387c-4fa3-8613-7f6e1c5f9a26',
        uri: 'mailto:cole.diffin@spidertracks.co.nz'
      }
    ]
  },
  {
    id: '76864e0a-1a2b-4ae7-9d7c-ba8e0e430d26',
    eventClass: 'geofence',
    eventType: 'breach_out',
    eventTypeLabel: 'Fence 4',
    deliveryChannels: ['sms'],
    users: ['user1', 'user2'],
    aircraft: ['aircraftId1', 'aircraftId2'],
    targets: [
      {
        id: '6eff8c24-86d3-4a6f-89e5-15b89d6c36fe',
        uri: 'sms:+642102424091'
      },
      {
        id: '680efb7c-10e7-4127-84be-4923ee038469',
        uri: 'mailto:cole.diffin@spidertracks.co.nz'
      }
    ]
  }
];

export const getAircraftRegistrationLabel = (aircraft: string[]) => {
  if (aircraft[0] === '*') {
    return 'All Aircraft';
  }
  return `${aircraft.length} Aircraft`;
};

export const getUsersLabel = (users: string[]) => {
  if (users[0] === '*') {
    return 'All Users';
  }
  if (users.length === 1) {
    return '1 User';
  }
  return `${users.length} Users`;
};

export const getDeliveryChannelsLabel = (channels: string[]) => {
  return channels.map(ch => `${ch}`).join(' ');
};

export class EventNotificationsService extends ServiceAbstract {
  public async get(): Promise<object> {
    const notificationsData = mockResponse.map(config => {
      return {
        key: config.id,
        event: config.eventTypeLabel,
        eventClass: config.eventClass,
        eventType: config.eventType,
        aircraft: getAircraftRegistrationLabel(config.aircraft),
        users: getUsersLabel(config.users),
        how: getDeliveryChannelsLabel(config.deliveryChannels)
      };
    });
    return notificationsData;
  }
}
