import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Row, Button, Spin } from 'antd';
import { useParams } from 'react-router';
import AddAircraftFormInput from './AddAircraftFormInput';
import AddAircraftFormDropdown from './AddAircraftFormDropdown';

export const AircraftForm = ({
  form,
  onClose,
  skipped,
  save,
  currentAircraftSpider,
  submitButtonValue,
  isSubmitButtonDisabled,
  handleFieldChange
}) => {
  const { organisationId } = useParams();
  const { getFieldDecorator, validateFields, setFieldsValue } = form;
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (currentAircraftSpider && currentAircraftSpider.aircraftId) {
      setFieldsValue(currentAircraftSpider);
    }
  }, []);

  const saveAircraft = async e => {
    e.preventDefault();
    if (submitButtonValue === 'Skip') {
      skipped();
    } else {
      validateFields(async (err, values) => {
        if (!err) {
          setIsSaving(true);
          const aircraft = {
            organisationId,
            registration: values.registration,
            make: values.make ? values.make : '',
            model: values.model ? values.model : '',
            type: values.type ? values.type : 'UNDEFINED',
            icon: values.icon ? values.icon : 'DEFAULT',
            iconColour: values.iconColour ? values.iconColour : 'DEFAULT'
          };

          await save(aircraft);
          setIsSaving(false);
        }
      });
    }
  };

  return (
    <Form
      onSubmit={saveAircraft}
      layout="vertical"
      hideRequiredMark={false}
      style={{ width: '308px', margin: '30px 0 0 32px' }}
    >
      <AddAircraftFormInput
        name="registration"
        label="Registration"
        getFieldDecorator={getFieldDecorator}
        validationRule={{
          required: true,
          message: 'An Aircraft registration is required'
        }}
        placeholder="Enter aircraft registration"
        handleFieldChange={handleFieldChange}
      />
      <AddAircraftFormDropdown
        name="type"
        label="Type"
        getFieldDecorator={getFieldDecorator}
        handleFieldChange={handleFieldChange}
        dropdownOptions={[
          { actualValue: 'FIXED_WING', displayedValue: 'Fixed wing' },
          { actualValue: 'ROTARY_WING', displayedValue: 'Rotary wing' }
        ]}
        placeholder="Select aircraft type"
        validationRule={{
          required: true,
          message: 'An Aircraft type is required'
        }}
      />
      <AddAircraftFormInput
        name="make"
        label="Make"
        getFieldDecorator={getFieldDecorator}
        handleFieldChange={handleFieldChange}
      />
      <AddAircraftFormInput
        name="model"
        label="Model"
        getFieldDecorator={getFieldDecorator}
        handleFieldChange={handleFieldChange}
      />
      <AddAircraftFormDropdown
        name="icon"
        label="Icon"
        getFieldDecorator={getFieldDecorator}
        dropdownOptions={[
          { actualValue: 'DEFAULT', displayedValue: 'Default' },
          { actualValue: 'FIXED_WING_SINGLE', displayedValue: 'Fixed wing single' },
          { actualValue: 'FIXED_WING_TWIN', displayedValue: 'Fixed wing twin' },
          { actualValue: 'ROTARY_WING', displayedValue: 'Rotary wing' }
        ]}
        placeholder="Select aircraft icon"
        handleFieldChange={handleFieldChange}
      />
      <AddAircraftFormDropdown
        name="iconColour"
        label="Icon colour"
        getFieldDecorator={getFieldDecorator}
        dropdownOptions={[
          { actualValue: 'DEFAULT', displayedValue: 'Default' },
          { actualValue: 'BLUE', displayedValue: 'Blue' },
          { actualValue: 'GREEN', displayedValue: 'Green' },
          { actualValue: 'YELLOW', displayedValue: 'Yellow' },
          { actualValue: 'ORANGE', displayedValue: 'Orange' },
          { actualValue: 'RED', displayedValue: 'Red' }
        ]}
        placeholder="Select aircraft icon colour"
        handleFieldChange={handleFieldChange}
      />
      <Form.Item style={{ textAlign: 'right' }} className="mt-3">
        <Row>
          <Button
            className="mx-2 px-3"
            type="secondary"
            size="large"
            onClick={onClose}
            style={{ width: '85px' }}
          >
            Cancel
          </Button>
          <Button
            className="mx-2 px-4"
            disabled={isSaving || isSubmitButtonDisabled}
            type="primary"
            htmlType="submit"
            size="large"
            style={{ width: '85px' }}
          >
            {isSaving ? (
              <span className="px-2">
                <Spin size="small" />
              </span>
            ) : (
              submitButtonValue
            )}
          </Button>
        </Row>
      </Form.Item>
    </Form>
  );
};

AircraftForm.propTypes = {
  form: PropTypes.object,
  onClose: PropTypes.func,
  skipped: PropTypes.func,
  save: PropTypes.func.isRequired,
  submitButtonDefaultName: PropTypes.string,
  currentAircraftSpider: PropTypes.object,
  submitButtonValue: PropTypes.string.isRequired,
  isSubmitButtonDisabled: PropTypes.bool.isRequired,
  handleFieldChange: PropTypes.func.isRequired
};

export default Form.create()(AircraftForm);
