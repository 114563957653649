const renderActiveKmlLayer = kml => {
  const url =
    kml.url && kml.url.length ? kml.url : `${window.env.STL_GO_ORIGIN}/kmlResource/${kml.id}`;
  return new window.google.maps.KmlLayer({
    url,
    map: window.gmap,
    preserveViewport: true
  });
};

const clearKmlLayer = kml => {
  if (kml && kml.kmlLayer !== undefined) {
    kml.kmlLayer.setMap(null);
  }
};

export const renderKmlLayers = ({ kmlData, activeKmls, kmlLayersOnMap }) => {
  const newKmlLayersOnMap = kmlLayersOnMap.reduce((acc, kmlLayerOnMap) => {
    if (!activeKmls.includes(kmlLayerOnMap.id)) {
      clearKmlLayer(kmlLayerOnMap);
    } else {
      acc.push(kmlLayerOnMap);
    }
    return acc;
  }, []);

  activeKmls.forEach(kmlId => {
    if (!newKmlLayersOnMap.find(({ id }) => id === kmlId)) {
      newKmlLayersOnMap.push({
        id: kmlId,
        kmlLayer: renderActiveKmlLayer(kmlData.find(({ id }) => id === kmlId))
      });
    }
  });

  return newKmlLayersOnMap;
};

export default {
  renderKmlLayers
};
