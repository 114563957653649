import { Organisation } from './Organisation';

export interface UserData {
  altitudeUnit: 'ft';
  city: string;
  countryCode: string;
  createdDate: string;
  dateFormat: string;
  displayName: string;
  distanceUnit: 'Nautical Miles';
  email: string;
  firstName: string;
  flyingPagePromptShown: boolean;
  gender: null;
  hideMapRadiusShading: boolean;
  id: string;
  lastName: string;
  latLongFormat: 'degreesminutesseconds';
  orgs: Organisation[];
  phones: UserPhone[];
  speedUnit: 'knots';
  spiderTxtEnabled: boolean;
  spiderTxtId: number;
  states: {
    groupTrackPoints: boolean;
    mapLayer: 'MAP';
    showAllPoints: boolean;
    pointGroupingOption: 'LAST_POINT' | 'FULL_TRACK';
    sidebarTab: 'ALPHABETICAL';
    sidebarVisible: boolean;
    tcVersion: string;
  };
  timeOffset: number;
  timezone: string;
}

interface UserPhone {
  countryCode: string;
  countryId: string;
  fullNumber: string;
  number: string;
  type: 'MOBILE';
}

export enum UserPreferenceKey {
  CLUSTERING_OPTION,
  SHOW_TRACKS,
  MAP_LAYER,
  AIRCRAFT_SORT,
  KML_LAYER,
  GROUPING_OPTION
}

export interface UserPreference {
  [UserPreferenceKey.CLUSTERING_OPTION]: {
    groupTrackPoints: boolean;
  };
  [UserPreferenceKey.SHOW_TRACKS]: {
    showAllPoints: boolean;
  };
  [UserPreferenceKey.GROUPING_OPTION]: {
    pointGroupingOption: string;
  };
  [UserPreferenceKey.MAP_LAYER]: {
    mapLayer: string;
  };
  [UserPreferenceKey.AIRCRAFT_SORT]: {
    sortType: string;
  };
  [UserPreferenceKey.KML_LAYER]: {
    selectedKml: string;
    isActive: boolean;
  };
}
