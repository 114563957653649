import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { getDateFormat, getDistanceUnit, getTimezone } from 'redux/selectors/userData';
import styled from 'styled-components';
import RequireSisenseJS from '../../components/common/navigation/RequireSisenseJS';
import FlightReportEventContainer from '../../components/Flying/Map/FlightReportEvent/FlightReportEventContainer';
import FlightReportMultipleEventContainer from '../../components/Flying/Map/FlightReportEvent/FlightReportMultipleEventContainer';
import FlightReportSummaryContainer from '../../components/Flying/Map/FlightReportSummary/FlightReportSummaryContainer';
import { GOOGLE_MAP_CONTAINER } from '../../components/Flying/Map/GoogleMap/constants';
import MapContainer from '../../components/Flying/Map/MapContainer';
import MapControls from '../../components/Flying/Map/MapControls';
import EventsPointInfoPanelContainer from '../../components/Flying/Map/PointInfoPanel/EventsPointInfoPanelContainer';
import { EventClass } from '../../constants';
import { MapProvider } from '../../context/MapContext';
import { useFeatureFlag } from '../../hooks';
import {
  getSelectedTrackId,
  getSelectedTracks,
  getTracksData
} from '../../redux/selectors/aircraftData';
import { getDisplayPoints } from '../../redux/selectors/eventData';
import { getUserData } from '../../redux/selectors/userData';
import { EventsMapWrapper } from './EventsMapWrapper';
import './styles/scss/styles.scss';

const StandardContainer = styled.div`
  padding: 10px;
  height: 100%;
`;

const TopContainer = styled.div`
  margin-bottom: 10px;
  height: ${({ hasChart, isMultiview }) => (hasChart && !isMultiview ? '65%' : '100%')};
`;

const SisenseChartContainer = styled.div`
  align-items: end;
  flex-direction: column;
`;

export const HistoryFlightReport = ({
  userData,
  tracks,
  timezone,
  dateFormat,
  distanceUnit,
  points,
  selectedTrackId
}) => {
  const { orgs = [] } = userData;
  const userOrgIds = orgs.reduce((acc, o) => acc.concat(o.org.id), []);
  const hasChart = useFeatureFlag('flight-report-chart', userOrgIds);
  const hasMultiview = useFeatureFlag('flight-report-multi-view', userOrgIds);

  const mapContext = {
    events: { displayEventClasses: [EventClass.GEOFENCE, EventClass.FSI] },
    strictPointGrouping: { displayFullTrack: true }
  };

  const isMultiview = hasMultiview && tracks.length > 1;

  return (
    <StandardContainer>
      <div className="flight-report d-flex flex-direction-col">
        <TopContainer
          id="history-flight-report-top-container"
          className="d-flex flex-1"
          hasChart={hasChart}
          isMultiview={isMultiview}
        >
          {isMultiview ? (
            <FlightReportMultipleEventContainer
              tracks={tracks}
              displayPoints={points}
              timezone={timezone}
              dateFormat={dateFormat}
              distanceUnit={distanceUnit}
              selectedTrackId={selectedTrackId}
            />
          ) : (
            <FlightReportEventContainer
              selectedTrack={tracks[0]}
              timezone={timezone}
              dateFormat={dateFormat}
              displayPoints={points[tracks[0].trackId]}
              className="shadow d-flex flex-direction-col"
            />
          )}

          <div id={GOOGLE_MAP_CONTAINER} className="map shadow d-flex flex-1">
            <MapProvider context={mapContext}>
              <MapContainer>
                <EventsMapWrapper>
                  <EventsPointInfoPanelContainer hidePanelOffset={true} />
                  <MapControls
                    favoritesEnabled={false}
                    pointGroupingEnabled={false}
                    weatherEnabled={false}
                  />
                </EventsMapWrapper>
              </MapContainer>
            </MapProvider>
          </div>
        </TopContainer>
        {hasChart && !isMultiview && (
          <SisenseChartContainer className="bottom shadow d-flex">
            <RequireSisenseJS>
              <FlightReportSummaryContainer />
            </RequireSisenseJS>
          </SisenseChartContainer>
        )}
      </div>
    </StandardContainer>
  );
};

HistoryFlightReport.propTypes = {
  userData: PropTypes.object,
  timezone: PropTypes.string,
  dateFormat: PropTypes.string,
  distanceUnit: PropTypes.string,
  tracks: PropTypes.array,
  points: PropTypes.object,
  selectedTrack: PropTypes.object,
  selectedTrackId: PropTypes.string
};

const mapStateToProps = state => {
  const selectedTracks = getSelectedTracks(state);
  const allTracks = getTracksData(state);
  const tracks = selectedTracks.map(track => allTracks[track.trackId]);

  return {
    tracks: tracks,
    userData: getUserData(state),
    timezone: getTimezone(state),
    dateFormat: getDateFormat(state),
    distanceUnit: getDistanceUnit(state),
    points: getDisplayPoints(state),
    selectedTrackId: getSelectedTrackId(state)
  };
};

export default connect(mapStateToProps)(HistoryFlightReport);
