import React from 'react';
import { Provider as SisenseProvider } from '../../context/SisenseContext';
import { InsightsDashboard } from './InsightsDashboard';
import { InsightsHeader } from './InsightsHeader';
import { RequireOrgAdmin } from '../common/navigation/RequireOrgAdmin';

export const Insights = () => {
  return (
    <div className="px-5">
      <InsightsHeader />
      <InsightsDashboard />
    </div>
  );
};

const InsightsRoot = () => (
  <RequireOrgAdmin>
    <SisenseProvider>
      <Insights />
    </SisenseProvider>
  </RequireOrgAdmin>
);

export default InsightsRoot;
