import {
  spiderAddedSuccess,
  spiderAssignedSuccess,
  partialRegistrationError,
  unableToSaveError
} from '../../../helpers/globalNotifications';
import { getInstance } from 'common/api/spidertracks-sdk';

export const DEFAULT_SX_PLAN_NAME = 'Core Tango - Monthly';

export const registerThenAssignSpiderFlow = async ({
  serialNumber,
  aircraftId,
  registration,
  organisationId,
  callback
}) => {
  const SpidertracksSDK = getInstance();

  let response;
  try {
    response = await SpidertracksSDK.getSpiderSettingsService().registerSpider({
      serialNumber: serialNumber,
      planName: DEFAULT_SX_PLAN_NAME,
      organisationId: organisationId
    });
    await SpidertracksSDK.getSpiderSettingsService().assignAircraftToSpider({
      aircraftId: aircraftId,
      serialNumber: serialNumber
    });

    callback(serialNumber);

    if (response.status === 400608) {
      partialRegistrationError();
    } else {
      spiderAssignedSuccess(registration);
    }
  } catch (e) {
    unableToSaveError();
    console.error(e);
  }
};

export const registerSpiderFlow = async ({ serialNumber, organisationId, callback }) => {
  const SpidertracksSDK = getInstance();

  let response;
  try {
    response = await SpidertracksSDK.getSpiderSettingsService().registerSpider({
      serialNumber,
      planName: DEFAULT_SX_PLAN_NAME,
      organisationId
    });
    callback(serialNumber);
    if (response.status === 400608) {
      partialRegistrationError();
    } else {
      spiderAddedSuccess();
    }
  } catch (e) {
    unableToSaveError();
    console.error(e);
  }
};

export const assignAircraftToSpiderFlow = async ({
  serialNumber,
  aircraftId,
  registration,
  callback
}) => {
  const SpidertracksSDK = getInstance();

  try {
    await SpidertracksSDK.getSpiderSettingsService().assignAircraftToSpider({
      aircraftId,
      serialNumber
    });
    callback(serialNumber, registration);
  } catch (e) {
    unableToSaveError();
    console.error(e);
  }
};
