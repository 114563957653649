import { ServiceAbstract } from '../../ServiceAbstract';
import { Aircraft } from '../../types/Aircraft';
import { Amplify } from '@spidertracks/common';

export class AircraftService extends ServiceAbstract {
  public async get(aircraftId: string): Promise<object> {
    return Amplify.API.get('authenticated', `aircrafts/${aircraftId}`);
  }

  public async addAircraft(aircraft: Aircraft): Promise<object> {
    return Amplify.API.post('authenticated', 'aircrafts', {
      body: aircraft
    });
  }

  public async editAircraft(aircraft: Aircraft): Promise<object> {
    const body: Partial<Aircraft> = { ...aircraft };
    delete body.aircraftId;
    return Amplify.API.put('authenticated', `aircrafts/${aircraft.aircraftId}`, {
      body
    });
  }
}
