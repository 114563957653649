import { MultiSelect, SolidTooltip } from '@spidertracks/components';
import { CheckboxOptionType, CheckboxValueType } from 'antd/lib/checkbox/Group';
import React, { useEffect, useState } from 'react';
import { SisenseConstants } from '../../../Map/FlightReportSummary/SisenseConstants';

// Maximum number of characters allowed in the closed state of the drop down.
// If the number of characters is more than this number, then the words after the 40th character are replaced by a "..."
const MAX_CLOSED_LABEL_LENGTH = 35;

// The format is key = option name, value = text to display when disabled
interface DisabledOptions {
  [key: string]: string;
}

interface DoubleSelectDropdownProps {
  dataSource: {
    [key: string]: string;
    [key: number]: string;
  };
  onChange: ({}: { left?: CheckboxValueType; right?: CheckboxValueType }) => void;
  onConfirm?: () => void;
  onCancel?: () => void;
  defaultLeft?: CheckboxValueType;
  defaultRight?: CheckboxValueType;
  disabledOptions?: DisabledOptions;
}

const getTruncatedLabel = (label: string) => {
  if (label.length < MAX_CLOSED_LABEL_LENGTH) {
    return label;
  }

  let truncatedString = label.substring(0, MAX_CLOSED_LABEL_LENGTH);
  if (label.charAt(MAX_CLOSED_LABEL_LENGTH) !== ' ') {
    truncatedString = truncatedString.substring(0, truncatedString.lastIndexOf(' '));
  }

  return truncatedString.trim() + '...';
};

const DoubleSelectDropdown = ({
  dataSource,
  onChange,
  onConfirm,
  onCancel,
  defaultLeft,
  defaultRight,
  disabledOptions
}: DoubleSelectDropdownProps) => {
  const defaultSelected = defaultLeft && defaultRight ? [defaultLeft, defaultRight] : [];
  const [options, setOptions] = useState<CheckboxOptionType[]>(
    Object.entries(dataSource).map(([value, label]) => ({ label, value }))
  );
  const [selected, setSelected] = useState<CheckboxValueType[]>(defaultSelected);
  const [closedLabel, setClosedLabel] = useState('Select parameters');
  const onReset = () => {
    setSelected(defaultSelected);
    const [left, right] = defaultSelected.map(String);
    onChange({ left, right });
    onConfirm && onConfirm();
  };

  useEffect(() => {
    const [left, right] = selected.map(String);
    onChange({ left, right });

    const label = selected.length == 2 ? `${left} and ${right}` : left ? left : 'Select parameters';

    setClosedLabel(getTruncatedLabel(label));

    setOptions(
      options.map(option => {
        const optionString = option.value.toString();
        const indexOf = selected.indexOf(option.value);
        const isSelectionLimitReached = selected.length === 2 && indexOf === -1;
        const isOptionDisabled =
          (disabledOptions && Object.keys(disabledOptions).includes(optionString)) ?? false;
        option.disabled = isSelectionLimitReached || isOptionDisabled;

        option.label = dataSource[optionString];

        if (isSelectionLimitReached) {
          option.label = (
            <SolidTooltip title={'You can only select two parameters at one time'} trigger="click">
              {option.label}
            </SolidTooltip>
          );
        } else if (disabledOptions && isOptionDisabled) {
          option.label = (
            <SolidTooltip title={disabledOptions[optionString]} trigger="hover">
              {optionString}
            </SolidTooltip>
          );
        }

        if (indexOf !== -1) {
          option.label = (
            <span key={option.label as React.Key}>
              {option.label}
              <span
                style={{
                  color: SisenseConstants.GREY_COLOR,
                  fontStyle: 'italic'
                }}
              >
                {[' (left)', ' (right)'][indexOf]}
              </span>
            </span>
          );
        }

        return option;
      })
    );
  }, [selected, disabledOptions]);

  const onSelect = (items: CheckboxValueType[]) => {
    // Remove anything that no longer belongs in `selected`
    const filtered = selected.filter(s => items.includes(s));
    // Concatenate what remains with any newly-selected items. This bit is important to preserve the
    // existing order of selection, which AntD's CheckboxGroup doesn't know about.
    setSelected([...filtered, ...items.filter(i => !filtered.includes(i))]);
  };

  return (
    <MultiSelect
      closedLabel={closedLabel}
      options={options}
      onReset={onReset}
      onSelect={onSelect}
      onConfirm={onConfirm}
      onCancel={onCancel}
      closeOnReset={true}
      selected={selected}
    />
  );
};

export default DoubleSelectDropdown;
