import React, { useState, useRef, useCallback } from 'react';
import { CustomFilterDropdownIconTable } from '@spidertracks/components';
import { getFilterDropdown } from '../../common/filters/getFilterDropdown';

export const onFilter = (value, record) => record.how.includes(value);
// eslint-disable-next-line react/prop-types
export const NotificationsTable = ({ notificationsData, eventFilterList }) => {
  const ref = useRef(() => {});
  const [selectedItems, setSelectedItems] = useState([]);

  const clearSelection = () => setSelectedItems([]);

  const remoteTrigger = useCallback(clearEventFilter => {
    ref.current = clearEventFilter;
  }, []);

  const eventFilterDropdownProps = getFilterDropdown({
    selectedItems,
    onFilter: setSelectedItems,
    clearSelection,
    remoteTrigger,
    displayName: 'events',
    showSearch: true
  });

  const columns = [
    {
      title: 'EVENT',
      dataIndex: 'event',
      filters: eventFilterList,
      ...eventFilterDropdownProps(),
      filteredValue: selectedItems,
      filterMultiple: true,
      onFilter: (value, record) => record.event === value
    },
    {
      title: 'AIRCRAFT',
      dataIndex: 'aircraft'
    },
    {
      title: 'PEOPLE',
      dataIndex: 'users'
    },
    {
      title: 'HOW',
      dataIndex: 'how',
      filters: [
        { text: 'SMS', value: 'sms' },
        { text: 'Email', value: 'email' }
      ],
      onFilter
    }
  ];
  const handleTableChange = (pagination, data) => {
    console.log('please handle this table', pagination, data);
  };
  return (
    <CustomFilterDropdownIconTable
      bordered
      dataSource={notificationsData}
      columns={columns}
      onChange={handleTableChange}
      size="middle"
    />
  );
};

NotificationsTable.displayName = 'NotificationsTable';

export default NotificationsTable;
