import React from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';

export const SpiderStatus = ({ hide, loading, serialNumber, status }) =>
  hide && (
    <div style={{ textAlign: 'left', paddingTop: '25px', color: '#A9A9A9' }}>
      {loading ? (
        <span
          style={{
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
            width: '40%'
          }}
        >
          <Spin size="small" tip="Loading Status..." />
        </span>
      ) : (
        <React.Fragment>
          <p>{`Serial Number: ${serialNumber}`}</p>
          <p>{!!status.type.length && `Spider Type: ${status.type}`}</p>
          <p>{!!status.imei.length && `IMEI: ${status.imei}`}</p>
          <p>{!!status.cellular.length && `Cellular Status: ${status.cellular}`}</p>
          <p>{!!status.iridium && `Iridium Status: ${status.iridium}`}</p>
        </React.Fragment>
      )}
    </div>
  );

SpiderStatus.propTypes = {
  hide: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  serialNumber: PropTypes.string.isRequired,
  status: PropTypes.object.isRequired
};

SpiderStatus.displayName = 'SpiderStatus';

export default SpiderStatus;
