import { SDK } from '../SDK';
import { PublicAircraftData } from '../types/AircraftData';
import { PublicTrackData } from '../types/TrackData';
import { HistoryTableTracksAPIResponse, TracksAPIResponse } from '../types/TracksAPIResponse';
import { PublicOrganisationAPIResponse } from '../types/PublicOrganisationAPIResponse';
import { KMLData } from '../types/KMLData';
import { UserData, UserPreference } from '../types/UserData';
import { HTTPError } from '../types/HTTPError';
import { UnknownOrganisationError } from '../types/UnknownOrganisationError';
import { FULL_TRACK } from '../../../../components/Flying/Map/GoogleMap/constants';
import { LatestTracksService } from './services/LatestTracksService';
import { TrackHistoryRequest } from '../types/TrackHistoryRequest';

export class PublicAPI extends SDK {
  protected isPublic: boolean = true;

  protected pathSegments = {
    AIRCRAFT: '/',
    TRACKS: '/orgs/<ORGANISATION_ID>/public-flying',
    KML: '/',
    KML_STATE: '/',
    USER: '/',
    USER_PREFERENCES: '/',
    ORGANISATION: '/orgs/public/<ORGANISATION_SLUG>',
    ORG_AIRCRAFT: '/'
  };

  private getOrganisationRequest(organisationSlug: string): Request {
    return new Request(
      this.buildUrl(this.getPath('ORGANISATION').replace('<ORGANISATION_SLUG>', organisationSlug))
    );
  }

  private async getOrganisationId(organisationSlug: string): Promise<string> {
    const organisationInfo = await this.call<PublicOrganisationAPIResponse>(
      this.getOrganisationRequest(organisationSlug)
    );
    return organisationInfo.id;
  }

  private async getTracksRequest(organisationSlug: string): Promise<Request> {
    try {
      const organisationId = await this.getOrganisationId(organisationSlug);

      return new Request(
        this.buildUrl(this.getPath('TRACKS').replace('<ORGANISATION_ID>', organisationId))
      );
    } catch (error) {
      if (error instanceof HTTPError && error.httpResponse.status === 404) {
        throw new UnknownOrganisationError(organisationSlug, error.httpResponse);
      }
      throw error;
    }
  }

  public async getAircraft(organisationSlug: string): Promise<PublicAircraftData[]> {
    try {
      const trackData = await this.call<TracksAPIResponse<PublicTrackData>>(
        await this.getTracksRequest(organisationSlug)
      );
      return trackData.items.map((track: any) => track.aircraft);
    } catch (error) {
      if (this.convertErrorToEvent(error)) {
        return [];
      } else {
        throw error;
      }
    }
  }

  public async getLatestTracks(organisationSlug: string): Promise<PublicTrackData[]> {
    try {
      const trackData = await this.call<TracksAPIResponse<PublicTrackData>>(
        await this.getTracksRequest(organisationSlug)
      );
      return trackData.items;
    } catch (error) {
      if (this.convertErrorToEvent(error)) {
        return [];
      } else {
        throw error;
      }
    }
  }

  public getTrackHistory(
    params: TrackHistoryRequest | null
  ): Promise<TracksAPIResponse<PublicTrackData[]>> {
    throw new Error('Method not implemented.');
  }
  public getHistoryTableTracks(
    params: URLSearchParams | null
  ): Promise<HistoryTableTracksAPIResponse<PublicTrackData[]>> {
    throw new Error('Method not implemented.');
  }

  public getLatestTracksService(): LatestTracksService {
    if (!this.serviceInstances['LatestTracksService']) {
      this.serviceInstances['LatestTracksService'] = new LatestTracksService(
        window.env.api.endpoint
      );
    }

    return this.serviceInstances['LatestTracksService'] as LatestTracksService;
  }

  public async getKML(): Promise<KMLData[]> {
    // Not Implemented, no KMLS for Public
    return [];
  }

  public async getUsers(): Promise<UserData> {
    return {
      dateFormat: 'DD_MM_YYYY',
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone, // default to browser Timezone

      //Empty Defaults
      altitudeUnit: 'ft',
      city: '',
      countryCode: '',
      createdDate: '',
      displayName: '',
      distanceUnit: 'Nautical Miles',
      email: '',
      firstName: ',',
      flyingPagePromptShown: true,
      gender: null,
      hideMapRadiusShading: true,
      id: '',
      lastName: '',
      latLongFormat: 'degreesminutesseconds',
      orgs: [],
      phones: [],
      speedUnit: 'knots',
      spiderTxtEnabled: false,
      spiderTxtId: 0,
      states: {
        mapLayer: 'MAP',
        showAllPoints: true,
        groupTrackPoints: true,
        pointGroupingOption: FULL_TRACK,
        sidebarTab: 'ALPHABETICAL',
        sidebarVisible: true,
        tcVersion: ''
      },
      timeOffset: 0
    } as UserData;
  }

  public async saveUserPreference<K extends keyof UserPreference, V extends UserPreference[K]>(
    preferenceKey: K,
    preferenceValue: V
  ): Promise<void> {
    // No-op implementation. Public SDK need not save user preferences.
  }

  public getFavouritesService(): any {}

  public getSpiderSettingsService(): any {}

  public getGeofencesService(): any {}

  public getAircraftService(): any {}

  public getFlightDataService(): any {}

  public getSisenseService(): any {}

  public getFlightEventConfigurationsService(): any {}

  public getEventRulesService(): any {}

  public getEventService(): any {}

  public getFeatureConfigService(): any {}
}
