import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { diff } from 'deep-object-diff';

import MapNavigation from '../../components/Flying/Map/MapNavigation';
import { vfr } from '../../components/Flying/Map/utils/sectionalMap';
import { highMap } from '../../components/Flying/Map/utils/highMap';
import { lowMap } from '../../components/Flying/Map/utils/lowMap';
import { basicMap } from '../../components/Flying/Map/utils/basicMap';
import { getNormalizedCoorda } from '../../components/Flying/Map/utils/vectorUtils';
import { selectedMarkerPoint, sosData } from '../../redux/reducers/mapReducer/actions/map';
import {
  getSelectedMarkerPoint,
  getGoogleView,
  getSkyVectorView,
  getUiSettings,
  getSelectedFilter
} from '../../redux/selectors/mapData';
import { getAircraftList } from '../../redux/selectors/aircraftData';
import { clearSelectedFlight } from '../../redux/reducers/aircraftReducer/thunk';

import '../../components/Flying/Map/styles/scss/map.scss';
import { mapInitialLoaded } from '../../redux/selectors/mapData';
import { GoogleMapContainer } from '../../components/Flying/Map/GoogleMap';
import PointInfoPanelContainer from '../../components/Flying/Map/PointInfoPanel/PointInfoPanelContainer';
import MapControls from '../../components/Flying/Map/MapControls';

export const { google } = window;

let zoom = 7;

class PublicMapContainer extends Component {
  static mapDetails(skyVectorView) {
    const mapConfig = {
      getNormalizedCoorda,
      skyVectorView
    };

    if (skyVectorView === 'vfr') {
      vfr(mapConfig);
    }
    if (skyVectorView === 'hi') {
      highMap(mapConfig);
    }
    if (skyVectorView === 'lo') {
      lowMap(mapConfig);
    }
    if (skyVectorView === 'basic') {
      basicMap(mapConfig);
    }
  }

  static getLatLng() {
    return window.gmap && window.gmap.center ? window.gmap.center : { lat: 0, lng: 0 };
  }

  static getZoom() {
    return window.gmap && window.gmap.zoom ? window.gmap.zoom : 7;
  }

  constructor(props) {
    super(props);
    this.state = {
      slide: false
    };
    this.onToggle = this.onToggle.bind(this);
    this.saveActivePoint = this.saveActivePoint.bind(this);
    this.setMapView = this.setMapView.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    const {
      aircraftListReducer,
      googleView,
      skyVectorView,
      selectedPoint,
      slide,
      uiSettings,
      selectedFilter
    } = this.props;

    const aircraftListNext = nextProps.aircraftListReducer.aircraftList;
    const aircraftListCurrent = aircraftListReducer.aircraftList;
    const trackDataNext = nextProps.aircraftListReducer.trackData;
    const trackDataCurrent = aircraftListReducer.trackData;
    if (
      googleView !== nextProps.googleView ||
      skyVectorView !== nextProps.skyVectorView ||
      selectedPoint !== nextProps.selectedPoint ||
      slide !== nextState.slide ||
      uiSettings !== nextProps.uiSettings ||
      selectedFilter !== nextProps.selectedFilter
    ) {
      this.setMapView();
      return true;
    }

    if (window.gmap.zoom !== zoom) {
      // eslint-disable-next-line
      zoom = window.gmap.zoom;
      return true;
    }

    if (
      (Object.keys(diff(aircraftListNext, aircraftListCurrent)).length > 0 ||
        Object.keys(diff(trackDataNext, trackDataCurrent)).length > 0) &&
      aircraftListReducer.aircraftList.length > 0 &&
      aircraftListReducer.trackData.length > 0
    ) {
      return true;
    }

    return false;
  }

  componentDidUpdate(prevProps) {
    const { googleView, skyVectorView } = this.props;
    if (googleView !== prevProps.googleView || skyVectorView !== prevProps.skyVectorView) {
      this.setMapView();
    }

    //Once map is initially Loaded
    if (!prevProps.mapInitialLoaded && this.props.mapInitialLoaded) {
      this.setMapView();
    }
  }

  componentWillUnmount() {
    this.props.clearSelectedFlight();
  }

  onToggle() {
    this.setState(prevState => {
      const slideState = !prevState.slide;
      return {
        slide: slideState
      };
    });
  }

  setMapView() {
    const { googleView, skyVectorView } = this.props;
    if (googleView.length && window.gmap) {
      window.gmap.setMapTypeId(googleView);
    }
    PublicMapContainer.mapDetails(skyVectorView);
  }

  saveActivePoint(selectedPoint) {
    this.props.selectedMarkerPoint(selectedPoint);
  }

  render() {
    const { saveActivePoint, onToggle } = this;
    const { selectedPoint, getSosData } = this.props;
    const { slide } = this.state;

    const navProps = {
      selectedPoint,
      slide,
      getSosData,
      sosData,
      methods: {
        saveActivePoint,
        onToggle
      }
    };

    return (
      <GoogleMapContainer className="map-wrapper__map">
        <MapNavigation {...navProps} />
        <MapControls weatherEnabled={false} favoritesEnabled={false} />
        <PointInfoPanelContainer />
      </GoogleMapContainer>
    );
  }
}

PublicMapContainer.defaultProps = {
  googleView: '',
  skyVectorView: ''
};

PublicMapContainer.propTypes = {
  googleView: PropTypes.string,
  skyVectorView: PropTypes.string
};

const mapState = state => ({
  googleView: getGoogleView(state),
  skyVectorView: getSkyVectorView(state),
  aircraftListReducer: getAircraftList(state),
  selectedPoint: getSelectedMarkerPoint(state),
  uiSettings: getUiSettings(state),
  selectedFilter: getSelectedFilter(state),
  mapInitialLoaded: mapInitialLoaded(state)
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      selectedMarkerPoint,
      clearSelectedFlight
    },
    dispatch
  );

export default connect(mapState, mapDispatchToProps)(PublicMapContainer);
