/* eslint-disable @typescript-eslint/camelcase */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import { useParams } from 'react-router';
import { getInstance } from 'common/api/spidertracks-sdk';
import { serialFormError, serialFormValidationInfo } from '../../../helpers/globalNotifications';
import SerialNumberInput from './SerialNumberInput';
import SpiderStatus from './SpiderStatus';
import { ConfirmationButtons } from '../../common/form';

export const SpiderForm = ({
  form,
  onClose,
  currentAircraftSpider,
  editing = false,
  submitButtonValue,
  save,
  spidersAircraftsData,
  savedSerialNumber
}) => {
  const {
    getFieldDecorator,
    isFieldTouched,
    getFieldError,
    getFieldsError,
    getFieldValue,
    setFieldsValue,
    validateFields
  } = form;

  const SpidertracksSDK = getInstance();
  const [open, setOpen] = useState(false);
  const [onSaveIsDisabled, setOnSaveIsDisabled] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [isLoadingStatus, setIsLoadingStatus] = useState(false);
  const [inputDisabled, setInputDisabled] = useState(false);
  const [serialNumberInput, setSerialNumberInput] = useState('');
  const [selectedSerialNumber, setSelectedSerialNumber] = useState(
    currentAircraftSpider.serialNumber
  );
  const [
    selectedSerialNumberAssociatedAircraft,
    setSelectedSerialNumberAssociatedAircraft
  ] = useState('Unassigned');
  const [status, setStatus] = useState({ cellular: '', iridium: '', imei: '', type: '', go: '' });
  const { organisationId } = useParams();

  const serialsWithAssignedAircrafts = spidersAircraftsData
    .filter(spiderAircraft => spiderAircraft.serialNumber !== 'No Device assigned')
    .map(spiderAircraft => {
      return {
        serialNumber: spiderAircraft.serialNumber,
        associatedAircraft: spiderAircraft.aircraftId ? spiderAircraft.registration : 'Unassigned'
      };
    });

  const fieldsError = getFieldsError();

  let hasErrors = false;

  if (Object.entries(fieldsError).length !== 0) {
    hasErrors = Object.values(fieldsError).some(value => value);
  }

  const serialNumberTouched = isFieldTouched('serialNumber');
  const isFieldValidated =
    [!!getFieldValue('serialNumber') && getFieldValue('serialNumber').length === 10].filter(
      field => !!field === true
    ).length === 1;

  const isSaveButtonDisabled =
    isSaving || hasErrors || !isFieldValidated || (editing && onSaveIsDisabled);
  const serialNumberError = serialNumberTouched && getFieldError('serialNumber');
  let lock = null;

  const isDeviceAssigned = currentAircraftSpider.serialNumber !== 'No Device assigned';
  const isSelectedSerialNumberDifferentFromSaved = selectedSerialNumber === savedSerialNumber;

  useEffect(() => {
    if (
      currentAircraftSpider.serialNumber &&
      currentAircraftSpider.serialNumber !== 'No Device assigned'
    ) {
      setFieldsValue({ serialNumber: currentAircraftSpider.serialNumber });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const init = async () => {
      if (savedSerialNumber !== 'No Device assigned') {
        let serialNumber = savedSerialNumber || selectedSerialNumber;
        setIsLoadingStatus(true);
        const results = await SpidertracksSDK.getSpiderSettingsService().getStatus(
          serialNumber,
          organisationId
        );
        setStatus(results);
        setIsLoadingStatus(false);
      }
    };
    try {
      init();
    } catch (e) {
      console.log(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedSerialNumber, selectedSerialNumber, currentAircraftSpider]);

  const lockClose = () => {
    clearTimeout(lock);
    lock = setTimeout(() => {
      lock = null;
    }, 100);
  };

  const onDropdownVisibleChange = open => {
    if (lock) return;
    setOpen(open);
  };

  const handleFieldChange = event => {
    setSerialNumberInput(event.target.value.toUpperCase());
  };

  const validateSerialNumber = async () => {
    setInputDisabled(true);
    try {
      const isAddedSerialInTheList = serialsWithAssignedAircrafts.some(
        spiderAircraftItem => spiderAircraftItem.serialNumber === serialNumberInput
      );
      // if the serial number being added was Not part of the serial numbers in the dropdown, add it to the dropdown
      if (!isAddedSerialInTheList) {
        setIsLoadingStatus(true);
        const results = await SpidertracksSDK.getSpiderSettingsService().getStatus(
          serialNumberInput,
          organisationId
        );
        setIsLoadingStatus(false);

        if (results.type !== 'SX') {
          serialFormError();
          setFieldsValue({ serialNumber: undefined });
          setSerialNumberInput('');
          setOnSaveIsDisabled(true);
        } else if (results.go === 'Available') {
          setFieldsValue({ serialNumber: serialNumberInput });
          setSelectedSerialNumber(serialNumberInput);
          setOnSaveIsDisabled(false);
        } else if (results.go === 'Active') {
          serialFormValidationInfo();
          setFieldsValue({ serialNumber: serialNumberInput });
          setSelectedSerialNumber(serialNumberInput);
          setOnSaveIsDisabled(false);
        } else if (results.go === 'Unknown') {
          serialFormError();
          setFieldsValue({ serialNumber: undefined });
          setSerialNumberInput('');
          setOnSaveIsDisabled(true);
        }
      } else {
        setFieldsValue({ serialNumber: serialNumberInput });
        setOnSaveIsDisabled(true);
      }
    } catch (e) {
      console.log(e);
      setFieldsValue({ serialNumber: undefined });
      setSerialNumberInput('');
      serialFormError();
      setInputDisabled(false);
      setOnSaveIsDisabled(true);
    } finally {
      setInputDisabled(false);
      setOpen(false);
    }
  };

  const onSubmit = async e => {
    e.preventDefault();
    validateFields(async (err, values) => {
      if (!err) {
        setIsSaving(true);

        const params = {
          aircraftId: currentAircraftSpider.aircraftId,
          serialNumber: values.serialNumber,
          associatedAircraft: selectedSerialNumberAssociatedAircraft
        };

        await save(params);
        setIsSaving(false);
      }
    });
  };

  const sortedSerialsNumbers = serialsWithAssignedAircrafts.sort((a, b) =>
    a.serialNumber.localeCompare(b.serialNumber)
  );

  return (
    <Form
      onSubmit={onSubmit}
      layout="vertical"
      hideRequiredMark={true}
      style={{ width: '308px', margin: '30px 0 0 32px' }}
    >
      <p style={{ fontSize: '1.1em', marginBottom: '30px' }}>
        {currentAircraftSpider.registration && (
          <span style={{ fontWeight: 'bold' }}>{currentAircraftSpider.registration}</span>
        )}
        {!currentAircraftSpider.registration && `No Aircraft assigned`}
      </p>
      <p>Add or select from existing spiders</p>
      <SerialNumberInput
        open={open}
        lockClose={lockClose}
        onDropdownVisibleChange={onDropdownVisibleChange}
        disabled={isSaving || hasErrors || inputDisabled}
        validation={{
          validationRegexPattern: '^[a-zA-Z0-9]{10}$',
          errorMessage: 'Please enter a valid Spider Serial Number'
        }}
        serialNumberInput={serialNumberInput}
        serialNumberError={serialNumberError}
        serialNumbers={sortedSerialsNumbers}
        handleFieldChange={handleFieldChange}
        getFieldDecorator={getFieldDecorator}
        validateSerialNumber={validateSerialNumber}
        setOnSaveIsDisabled={setOnSaveIsDisabled}
        setSelectedSerialNumber={setSelectedSerialNumber}
        setSelectedSerialNumberAssociatedAircraft={setSelectedSerialNumberAssociatedAircraft}
        getFieldValue={getFieldValue}
        preselectedSerialNumber={currentAircraftSpider.serialNumber}
      />
      <ConfirmationButtons
        onClose={onClose}
        isSaving={isSaving}
        text={submitButtonValue}
        disabled={isSaveButtonDisabled}
      />
      <SpiderStatus
        hide={editing && isDeviceAssigned && isSelectedSerialNumberDifferentFromSaved}
        loading={isLoadingStatus}
        status={status}
        serialNumber={savedSerialNumber}
      />
    </Form>
  );
};

SpiderForm.propTypes = {
  editing: PropTypes.bool,
  save: PropTypes.func,
  submitButtonValue: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  form: PropTypes.object,
  spidersAircraftsData: PropTypes.array,
  currentAircraftSpider: PropTypes.object,
  savedSerialNumber: PropTypes.string
};

export default Form.create()(SpiderForm);
