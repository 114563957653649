import { setKmlData, setActiveKmls } from './index';
import { getInstance } from 'common/api/spidertracks-sdk';

export const populateKmlData = () => async dispatch => {
  const SpidertracksSDK = getInstance();

  const kmls = await SpidertracksSDK.getKML();

  dispatch(setKmlData(kmls.map(kml => Object.assign({}, kml, { mapReference: null }))));

  let activeKmls = JSON.parse(localStorage.getItem('activeKmls')) || [];

  // Check if active KML from local storage are still returned from the api (e.g. were not deleted)
  const newActiveKmls = [...activeKmls];
  if (activeKmls.length) {
    let activeKmlChanged = false;
    for (const activeKml of activeKmls) {
      if (activeKml !== 'geofence' && !kmls.some(kml => kml.id === activeKml)) {
        newActiveKmls.splice(newActiveKmls.indexOf(activeKml), 1);
        activeKmlChanged = true;
      }
    }
    if (activeKmlChanged) {
      localStorage.setItem('activeKmls', JSON.stringify(newActiveKmls));
    }
  }

  dispatch(setActiveKmls(newActiveKmls));
};

export const updateKmlData = ({ kmlData, activeKmls }) => dispatch => {
  dispatch(setKmlData(kmlData));
  dispatch(setActiveKmls(activeKmls));
};

export default {
  populateKmlData,
  updateKmlData
};
