import { SDK } from '../SDK';
import { PrivateAircraftData } from '../types/AircraftData';
import { PrivateTrackData, PublicTrackData } from '../types/TrackData';
import { HistoryTableTracksAPIResponse, TracksAPIResponse } from '../types/TracksAPIResponse';
import { KMLData } from '../types/KMLData';
import { AircraftAPIResponse } from '../types/AircraftAPIResponse';
import { HistoryTableData, TrackHistoryData } from '../types/TrackHistoryData';
import { KMLAPIResponse } from '../types/KMLAPIResponse';
import { UserData, UserPreference, UserPreferenceKey } from '../types/UserData';
import { DownloadRequest } from '../types/DownloadRequest';
import { TrackHistoryRequest } from '../types/TrackHistoryRequest';
import { UsersAPIResponse } from '../types/UsersAPIResponse';
import { FavouritesService } from './services/FavouritesService';
import { SpiderSettingsService } from './services/SpiderSettingsService';
import { GeofencesService } from './services/GeofencesService';
import { AircraftService } from './services/AircraftService';
import fileDownload from 'js-file-download';
import { FlightDataService } from './services/FlightDataService';
import { SisenseService } from './services/SisenseService';
import { FlightEventConfigurationsService } from './services/FlightEventConfigurationsService';
import { EventRulesService } from './services/EventRulesService';
import { EventNotificationsService } from './services/EventNotificationsService';
import { EventService } from './services/EventService';
import { FeatureConfigService } from './services/FeatureConfigService';

export class PrivateAPI extends SDK {
  protected isPublic: boolean = false;

  protected pathSegments = {
    TRACKS: '/tracks',
    AIRCRAFT: '/users/aircraft',
    KML: '/kmls',
    KML_STATE: '/kmls/activeState/',
    USER: '/users',
    USER_PREFERENCES: '/users/states?impersonateEmail=',
    ORGANISATION: '/',
    ORG_AIRCRAFT: '/orgs/<ORGANISATION_ID>/aircraft'
  };
  private addAuthToRequest(request: Request): Request {
    const newHeaders = new Headers(request.headers);
    newHeaders.append('Authorization', `${window.sessionStorage.hash}`);

    return new Request(request, {
      headers: newHeaders
    });
  }

  private getAircraftRequest(): Request {
    const request = new Request(this.buildUrl(this.getPath('AIRCRAFT')), {});

    return this.addAuthToRequest(request);
  }

  public async getAircraft(): Promise<PrivateAircraftData[]> {
    try {
      const aircraftData = await this.call<AircraftAPIResponse<PrivateAircraftData[]>>(
        this.getAircraftRequest()
      );

      return aircraftData.items;
    } catch (error) {
      if (this.convertErrorToEvent(error)) {
        return [];
      } else {
        throw error;
      }
    }
  }

  private getOrgAircraftRequest(orgId: string): Request {
    const request = new Request(
      this.buildUrl(this.getPath('ORG_AIRCRAFT').replace('<ORGANISATION_ID>', orgId)),
      {}
    );

    return this.addAuthToRequest(request);
  }

  public async getOrgAircraft(orgId: string): Promise<PrivateAircraftData[]> {
    try {
      const aircraftData = await this.call<AircraftAPIResponse<PrivateAircraftData[]>>(
        this.getOrgAircraftRequest(orgId)
      );

      return aircraftData.items;
    } catch (error) {
      if (this.convertErrorToEvent(error)) {
        return [];
      } else {
        throw error;
      }
    }
  }

  private getLatestTrackRequest(): Request {
    const request = new Request(
      this.buildUrl(`${this.getPath('TRACKS')}/latest?impersonateEmail=&pointsPerTrack=10000`),
      {}
    );

    return this.addAuthToRequest(request);
  }

  private getTrackHistoryRequest({
    trackIds = '',
    page,
    resultsPerPage,
    aircraftIds = '',
    startTime,
    endTime,
    sos = '',
    zeroMinFlights = true,
    zeroDistanceFlights = true,
    serialNumber,
    bootcount
  }: TrackHistoryRequest): Request {
    const path = `${this.getPath(
      'TRACKS'
    )}?aircraftIds=${aircraftIds}&contacts=false&impersonateEmail=&startTime=${startTime}&endTime=${endTime}&page=${page}&resultsPerPage=${resultsPerPage}&sos=${sos}&zeroMinFlights=${zeroMinFlights}&zeroDistanceFlights=${zeroDistanceFlights}&trackIds=${trackIds}&serialNumber=${serialNumber}&bootcount=${bootcount}`;

    const request = new Request(this.buildUrl(path), {});
    return this.addAuthToRequest(request);
  }

  /**
   * This is a duplicate of getTrackHistoryRequest to support the new tracks endpoint for the history table.
   */
  private getHistoryTableTracksRequest(params: URLSearchParams): Request {
    // NOTE: avoid accidentally mucking up the existing querystring
    const search = new URLSearchParams(params.toString());

    // Avoid passing empty parameters to the API
    search.forEach((v, k) => v === '' && search.delete(k));

    // NOTE: translate a few values that, for historical reasons, have different names. Also, confusingly,
    //   - duration == true means zeroMinFlights == false
    //   - distance == true means zeroDistanceFlights == false
    if (search.get('duration') === 'true') {
      search.append('zeroMinFlights', 'false');
      search.delete('duration');
    }
    if (search.get('distance') === 'true') {
      search.append('zeroDistanceFlights', 'false');
      search.delete('distance');
    }

    // Times in ms
    const startTime = search.get('start');
    const endTime = search.get('end');
    if (startTime) {
      search.set('startTime', (Number(startTime) * 1000).toString());
      search.delete('start');
    }
    if (endTime) {
      search.set('endTime', (Number(endTime) * 1000).toString());
      search.delete('end');
    }

    // 0-index page numbers
    const page = search.get('page');
    if (page) {
      search.set('page', (Number(page) - 1).toString());
    }

    const path = `${this.getPath('TRACKS')}/history-table?${search.toString()}`;
    const request = new Request(this.buildUrl(path), {});
    return this.addAuthToRequest(request);
  }

  private getDownloadRequest({ type, trackIds }: DownloadRequest): Request {
    const path = `${this.getPath('TRACKS')}?trackIds=${trackIds}&exportType=${type}`;
    const request = new Request(this.buildUrl(path), {});
    return this.addAuthToRequest(request);
  }

  public async getDownload(props: DownloadRequest) {
    try {
      await this.call<string>(this.getDownloadRequest(props), {
        process: async response => {
          const string = await response.text();
          let fileExtension = props.type;
          let fileName = 'exportedTracks';
          if (props.type === 'csvsummary') {
            fileExtension = 'csv';
            fileName = fileName + 'Summary';
          }
          fileDownload(string, `${fileName}.${fileExtension}`);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  // The API does not exist in NODE but exists in Go. What do we do?
  private deleteTracksRequest(trackIds: [string]): Request {
    const path = this.buildUrl(`${this.getPath('TRACKS')}`);
    const requestBody = {
      trackIds: trackIds
    };
    const request = new Request(path, {
      method: 'DELETE',
      headers: [['Content-Type', 'application/json']],
      body: JSON.stringify(requestBody)
    });
    return this.addAuthToRequest(request);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public async deleteTracks(trackIds: [string]): Promise<any> {
    try {
      await this.call(this.deleteTracksRequest(trackIds));
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  public async getLatestTracks(): Promise<PrivateTrackData[]> {
    try {
      const trackData = await this.call<TracksAPIResponse<PrivateTrackData[]>>(
        this.getLatestTrackRequest()
      );

      return trackData.items;
    } catch (error) {
      if (this.convertErrorToEvent(error)) {
        return [];
      } else {
        throw error;
      }
    }
  }

  public async getTrackHistory(props: TrackHistoryRequest): Promise<TrackHistoryData> {
    try {
      const trackData = await this.call<TracksAPIResponse<PrivateTrackData[]>>(
        this.getTrackHistoryRequest(props)
      );

      return trackData;
    } catch (error) {
      if (this.convertErrorToEvent(error)) {
        const emptyTrackData: TrackHistoryData = {
          items: [],
          _meta: { count: 0, total: 0 }
        };
        return emptyTrackData;
      } else {
        throw error;
      }
    }
  }

  /**
   * This is a duplicate of getTrackHistory to support the new tracks endpoint for the history table.
   */

  public async getHistoryTableTracks(params: URLSearchParams): Promise<HistoryTableData> {
    try {
      const trackData = await this.call<HistoryTableTracksAPIResponse<PrivateTrackData[]>>(
        this.getHistoryTableTracksRequest(params)
      );

      return trackData;
    } catch (error) {
      if (this.convertErrorToEvent(error)) {
        const emptyTrackData: HistoryTableData = {
          errors: [],
          items: [],
          meta: { count: 0, total: 0 }
        };
        return emptyTrackData;
      } else {
        throw error;
      }
    }
  }

  private getKMLRequest(): Request {
    const request = new Request(this.buildUrl(this.getPath('KML')), {});

    return this.addAuthToRequest(request);
  }

  public async getKML(): Promise<KMLData[]> {
    const kmlData = await this.call<KMLAPIResponse>(this.getKMLRequest());

    return kmlData.items.map((item): KMLData => ({ ...item, type: 'kml' }));
  }

  private getUsersRequest(): Request {
    const request = new Request(this.buildUrl(this.getPath('USER')), {});

    return this.addAuthToRequest(request);
  }

  public async getUsers(): Promise<UserData> {
    return await this.call<UsersAPIResponse>(this.getUsersRequest());
  }

  private isEmpty(obj: object): boolean {
    if (!obj) {
      return true;
    }
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

  public async saveUserPreference<K extends keyof UserPreference, V extends UserPreference[K]>(
    preferenceKey: K,
    preferenceValue: V
  ): Promise<void> {
    if (preferenceKey == undefined || preferenceKey == null || this.isEmpty(preferenceValue)) {
      throw Error('User preference key or value is empty');
    }

    let url;
    let requestBody;

    switch (preferenceKey) {
      case UserPreferenceKey.GROUPING_OPTION:
      case UserPreferenceKey.CLUSTERING_OPTION:
      case UserPreferenceKey.SHOW_TRACKS:
      case UserPreferenceKey.AIRCRAFT_SORT:
      case UserPreferenceKey.MAP_LAYER:
        url = this.buildUrl(this.getPath('USER_PREFERENCES'));
        requestBody = JSON.stringify(preferenceValue);
        break;
      case UserPreferenceKey.KML_LAYER:
        let body = preferenceValue as UserPreference[UserPreferenceKey.KML_LAYER];
        url = this.buildUrl(this.getPath('KML_STATE') + body.selectedKml);
        requestBody = JSON.stringify({ isActive: body.isActive });
        break;
      default:
        throw Error('Unknown user preference type: ' + preferenceKey);
    }

    if (url && requestBody) {
      const request = new Request(url, {
        method: 'PUT',
        headers: [['Content-Type', 'application/json']],
        body: requestBody
      });
      await this.call(this.addAuthToRequest(request));
      return;
    }
  }

  public getFavouritesService(): FavouritesService {
    if (!this.serviceInstances['FavouritesService']) {
      this.serviceInstances['FavouritesService'] = new FavouritesService(window.env.api.endpoint);
    }

    return this.serviceInstances['FavouritesService'] as FavouritesService;
  }

  public getSpiderSettingsService(): SpiderSettingsService {
    if (!this.serviceInstances['SpiderSettingsService']) {
      this.serviceInstances['SpiderSettingsService'] = new SpiderSettingsService(
        window.env.api.endpoint
      );
    }

    return this.serviceInstances['SpiderSettingsService'] as SpiderSettingsService;
  }

  public getAircraftService(): AircraftService {
    if (!this.serviceInstances['AircraftService']) {
      this.serviceInstances['AircraftService'] = new AircraftService(window.env.api.endpoint);
    }

    return this.serviceInstances['AircraftService'] as AircraftService;
  }

  public getFlightDataService(): FlightDataService {
    if (!this.serviceInstances['FlightDataService']) {
      this.serviceInstances['FlightDataService'] = new FlightDataService(window.env.api.endpoint);
    }

    return this.serviceInstances['FlightDataService'] as FlightDataService;
  }

  public getGeofencesService(): GeofencesService {
    if (!this.serviceInstances['GeofencesService']) {
      this.serviceInstances['GeofencesService'] = new GeofencesService(window.env.api.endpoint);
    }

    return this.serviceInstances['GeofencesService'] as GeofencesService;
  }

  public getSisenseService(): SisenseService {
    if (!this.serviceInstances['SisenseService']) {
      this.serviceInstances['SisenseService'] = new SisenseService(window.env.api.endpoint);
    }

    return this.serviceInstances['SisenseService'] as SisenseService;
  }

  public getFlightEventConfigurationsService(): FlightEventConfigurationsService {
    if (!this.serviceInstances['FlightEventConfigurationsService']) {
      this.serviceInstances[
        'FlightEventConfigurationsService'
      ] = new FlightEventConfigurationsService(window.env.api.endpoint);
    }

    return this.serviceInstances[
      'FlightEventConfigurationsService'
    ] as FlightEventConfigurationsService;
  }
  public getEventRulesService(): EventRulesService {
    if (!this.serviceInstances['EventRulesService']) {
      this.serviceInstances['EventRulesService'] = new EventRulesService(window.env.api.endpoint);
    }

    return this.serviceInstances['EventRulesService'] as EventRulesService;
  }
  public getEventNotificationsService(): EventNotificationsService {
    if (!this.serviceInstances['EventNotificationsService']) {
      this.serviceInstances['EventNotificationsService'] = new EventNotificationsService(
        window.env.api.endpoint
      );
    }

    return this.serviceInstances['EventNotificationsService'] as EventNotificationsService;
  }

  public getEventService(): EventService {
    if (!this.serviceInstances['EventService']) {
      this.serviceInstances['EventService'] = new EventService(window.env.api.endpoint);
    }
    return this.serviceInstances['EventService'] as EventService;
  }

  public getFeatureConfigService(): FeatureConfigService {
    if (!this.serviceInstances['FeatureConfigService']) {
      this.serviceInstances['FeatureConfigService'] = new FeatureConfigService(
        window.env.api.endpoint
      );
    }
    return this.serviceInstances['FeatureConfigService'] as FeatureConfigService;
  }
}
