import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Polyline } from '@react-google-maps/api';

export const AircraftTrackLine = ({ coordinates, isTrackSelected }) => {
  return (
    <Fragment>
      <Polyline
        options={{
          strokeColor: isTrackSelected ? 'blue' : '#FF0000',
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: '#FF0000',
          fillOpacity: 0.35
        }}
        path={coordinates}
      />
    </Fragment>
  );
};

AircraftTrackLine.propTypes = {
  coordinates: PropTypes.array,
  isTrackSelected: PropTypes.bool
};

export default AircraftTrackLine;
