import { PublicAircraftData, PrivateAircraftData } from './types/AircraftData';
import { PublicTrackData, PrivateTrackData } from './types/TrackData';
import { KMLData } from './types/KMLData';
import { UserData, UserPreference } from './types/UserData';
import { HTTPError } from './types/HTTPError';
import { DelegatedEventTarget } from './DelegatedEventTarget';
import { UnknownOrganisationError } from './types/UnknownOrganisationError';
import { FavouritesService } from './private/services/FavouritesService';
import { SpiderSettingsService } from './private/services/SpiderSettingsService';
import { GeofencesService } from './private/services/GeofencesService';
import { AircraftService } from './private/services/AircraftService';
import { ServiceAbstract } from './ServiceAbstract';
import { FlightDataService } from './private/services/FlightDataService';
import { SisenseService } from './private/services/SisenseService';
import { TrackHistoryRequest } from './types/TrackHistoryRequest';
import { HistoryTableData, TrackHistoryData } from './types/TrackHistoryData';
import { FlightEventConfigurationsService } from './private/services/FlightEventConfigurationsService';
import { EventRulesService } from './private/services/EventRulesService';
import { EventService } from './private/services/EventService';
import { FeatureConfigService } from './private/services/FeatureConfigService';

export type RequestType =
  | 'AIRCRAFT'
  | 'TRACKS'
  | 'KML'
  | 'KML_STATE'
  | 'USER'
  | 'USER_PREFERENCES'
  | 'ORGANISATION'
  | 'ORG_AIRCRAFT';
export type CustomEvents = 'malformed_json' | 'unknown_organisation';

export abstract class SDK extends DelegatedEventTarget {
  /**
   * Determines if this SDK instance is for public or private API calls
   */
  protected isPublic: boolean = false;

  private _baseUrl: string;

  // probably need the new implemtation ogf this
  protected serviceInstances: { [key: string]: ServiceAbstract } = {};

  public constructor(baseUrl: string) {
    super();
    this._baseUrl = baseUrl;
  }

  public get public() {
    return this.isPublic;
  }

  public get baseUrl() {
    return this._baseUrl;
  }

  protected getPath(pathType: RequestType) {
    return this.pathSegments[pathType];
  }

  protected buildUrl(path: string) {
    const baseUrlRe = this.baseUrl.replace(/\/$/, '');
    const pathRe = path.replace(/^\//, '');
    return `${baseUrlRe}/${pathRe}`;
  }

  private buildCallHelperFns(fns: Partial<CallHelperFns>): CallHelperFns {
    return {
      process: this.callProcess,
      processError: this.callProcessError,
      fetchError: this.callError,
      ...fns
    };
  }

  protected async call<T>(request: Request, cb: Partial<CallHelperFns> = {}): Promise<T> {
    const helperFns = this.buildCallHelperFns(cb);
    try {
      var fetchResult = await fetch(request);
    } catch (fetchError) {
      // Devtools never shows this error as being defined - but it is...
      throw helperFns.fetchError(fetchError);
    }
    if (fetchResult.ok) {
      try {
        return await helperFns.process(fetchResult);
      } catch (e) {
        throw helperFns.processError(fetchResult, e);
      }
    } else {
      throw helperFns.processError(fetchResult);
    }
  }

  private async callProcess(fetchResponse: Response): Promise<any> {
    if (fetchResponse.headers.get('content-type')?.includes('application/json')) {
      return await fetchResponse.json();
    }
    return {};
  }

  protected callProcessError(fetchResponse: Response, processError?: Error): Error {
    if (processError) {
      return processError;
    }
    const e = new HTTPError(fetchResponse);
    return e;
  }

  private callError(fetchError: Error) {
    return fetchError;
  }

  /**
   *
   * @param Error an error to handle
   * @returns boolean indicating if the error has be sufficiently handled
   */
  protected convertErrorToEvent(error: Error): boolean {
    console.log(error);
    if (error.message.includes('invalid json')) {
      this.dispatchCustomError('malformed_json', { error });
    } else if (error instanceof UnknownOrganisationError) {
      this.dispatchCustomError('unknown_organisation', { error });
    } else {
      return false;
    }
    return true;
  }

  private dispatchCustomError(event: CustomEvents, detail: any) {
    this.dispatchEvent(new CustomEvent(event, { detail }));
  }

  // Abstarct Properties
  protected abstract pathSegments: {
    [P in RequestType]: string;
  };

  public abstract getAircraft(
    organisationId: string | null
  ): Promise<PublicAircraftData[] | PrivateAircraftData[]>;
  public abstract getLatestTracks(
    organisationId: string | null
  ): Promise<PublicTrackData[] | PrivateTrackData[]>;
  public abstract getTrackHistory(params: TrackHistoryRequest | null): Promise<TrackHistoryData>;
  public abstract getHistoryTableTracks(params: URLSearchParams): Promise<HistoryTableData>;
  public abstract getKML(): Promise<KMLData[]>;
  public abstract getUsers(): Promise<UserData>;

  public abstract saveUserPreference<K extends keyof UserPreference, V extends UserPreference[K]>(
    preferenceKey: K,
    preferenceValue: V
  ): Promise<void>;

  public abstract getFavouritesService(): FavouritesService;

  public abstract getSpiderSettingsService(): SpiderSettingsService;

  public abstract getGeofencesService(): GeofencesService;

  public abstract getAircraftService(): AircraftService;

  public abstract getFlightDataService(): FlightDataService;

  public abstract getSisenseService(): SisenseService;

  public abstract getFlightEventConfigurationsService(): FlightEventConfigurationsService;

  public abstract getEventRulesService(): EventRulesService;

  public abstract getEventService(): EventService;

  public abstract getFeatureConfigService(): FeatureConfigService;
}

interface CallHelperFns {
  process: (r: Response) => Promise<any>;
  processError: (r: Response, e?: Error) => Error;
  fetchError: (e: Error) => Error;
}
