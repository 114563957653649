import { createSelector } from 'reselect';
import { DisplayPoint, TrackPoint } from '../../common/api/spidertracks-sdk/types/TrackData';
import { EventClass } from '../../constants';
import { FlightStatus } from '../../constants';
import { StartFinishIconNumber } from '../../components/Flying/Map/utils/drawing/marker';
import { timestampAscending } from '../../helpers';
import { mapEventsToTrackPoints } from '../../pages/flying/mapEventsToTrackPoints';
import { FullState } from '../../store';
import { EventState } from '../types';
import { getSelectedTrack, getSelectedTracks } from './aircraftData';
import { getAltitudeUnit, getLatLongFormat, getSpeedUnit } from './userData';

export const getEventData = (state: FullState): EventState => state.events;

export const getEvents = createSelector(
  getEventData,
  getSelectedTracks,
  (eventData, selectedTracks) => {
    return selectedTracks.reduce<Record<string, any[]>>((acc, selectedTrack) => {
      if (!eventData?.aircraft?.hasOwnProperty(selectedTrack.aircraft.id)) {
        acc[selectedTrack.trackId] = [];
        return acc;
      }

      const start = new Date(selectedTrack.departedTime);
      const end = new Date(selectedTrack.endTime);
      acc[selectedTrack.trackId] = Object.values(eventData.aircraft[selectedTrack.aircraft.id])
        .filter((event: any) => {
          const eventTime = new Date(event.eventTime);
          // TODO: is this inclusive?
          return eventTime >= start && eventTime <= end;
        })
        .sort(timestampAscending);
      return acc;
    }, {});
  }
);

export const getSelectedTrackEvents = createSelector(
  getEventData,
  getSelectedTrack,
  (eventData, selectedTrack) => {
    if (!selectedTrack) {
      return;
    }
    if (!eventData.aircraft.hasOwnProperty(selectedTrack.aircraft.id)) {
      return [];
    }

    const start = new Date(selectedTrack.departedTime);
    const end = new Date(selectedTrack.endTime);
    return Object.values(eventData.aircraft[selectedTrack.aircraft.id])
      .filter((event: any) => {
        const eventTime = new Date(event.eventTime);
        // TODO: is this inclusive?
        return eventTime >= start && eventTime <= end;
      })
      .sort(timestampAscending);
  }
);

export const getSelectedTrackEventTypes = createSelector(
  getEventData,
  getSelectedTrack,
  (eventData, selectedTrack) => {
    if (!selectedTrack) {
      return undefined;
    }
    return eventData.types[selectedTrack.trackId];
  }
);

export const getEventTypesByTrack = createSelector(getEventData, eventData => eventData.types);

export const getFlightStatus = createSelector(getEventData, ({ flightStatus }) => flightStatus);

export const getSelectedTrackFlightStatus = createSelector(
  getFlightStatus,
  getSelectedTrack,
  (flightStatus, selectedTrack) => {
    if (!selectedTrack) {
      return;
    }
    return flightStatus[selectedTrack.trackId];
  }
);

export const getDisplayPoints = createSelector(
  getSelectedTracks,
  getFlightStatus,
  getEventTypesByTrack,
  getEvents,
  getAltitudeUnit,
  getLatLongFormat,
  getSpeedUnit,
  (selectedTracks, flightStatus, eventTypes, events, altitudeUnit, latLongFormat, speedUnit) => {
    if (
      !selectedTracks ||
      !flightStatus ||
      !events ||
      !eventTypes ||
      !altitudeUnit ||
      !latLongFormat ||
      !speedUnit
    ) {
      return;
    }

    // Due to a race condition in the data fetching, check all the data exists for all the selected tracks
    // before running the reducer
    if (
      selectedTracks.some(
        ({ trackId }) => !flightStatus[trackId] || !events[trackId] || !eventTypes[trackId]
      )
    ) {
      return;
    }

    const result = selectedTracks.reduce<Record<string, DisplayPoint[]>>((acc, selectedTrack) => {
      const { trackId } = selectedTrack;
      const displayEventClasses = [EventClass.GEOFENCE];
      if (flightStatus[trackId] !== FlightStatus.IN_REVIEW) {
        displayEventClasses.push(EventClass.FSI);
      }
      let filteredEvents = events[trackId].filter(e => displayEventClasses.includes(e.eventClass));
      let points = [
        ...mapEventsToTrackPoints({
          events: filteredEvents,
          eventTypes: eventTypes[trackId],
          latLongFormat,
          speedUnit,
          altitudeUnit
        }),
        ...selectedTrack.points
      ].sort(timestampAscending);

      const isPointEvent = (point: TrackPoint) => point.buttonMode > 10;

      if (points.length === 1 && !isPointEvent(points[0])) {
        points[0] = {
          ...points[0],
          buttonMode: StartFinishIconNumber,
          description: 'Latest Point'
        };
      } else {
        // If the first or last point is not an event, override it, otherwise appending a new point
        // If the button mode is <=10, then we say it's not an event.
        const firstPoint = points[0];
        if (isPointEvent(firstPoint)) {
          points.unshift({
            ...firstPoint,
            buttonMode: StartFinishIconNumber,
            description: 'Start of Track',
            id: firstPoint.id + 'start'
          });
        } else {
          points[0] = {
            ...firstPoint,
            buttonMode: StartFinishIconNumber,
            description: 'Start of Track'
          };
        }
        const lastPoint = points[points.length - 1];
        if (isPointEvent(lastPoint)) {
          points.push({
            ...lastPoint,
            buttonMode: StartFinishIconNumber,
            description: 'Latest Point',
            id: lastPoint.id + 'end'
          });
        } else {
          points[points.length - 1] = {
            ...lastPoint,
            buttonMode: StartFinishIconNumber,
            description: 'Latest Point'
          };
        }
      }

      acc[trackId] = points as DisplayPoint[];
      return acc;
    }, {});
    return result;
  }
);

export const getSelectedTrackDisplayPoints = createSelector(
  getSelectedTrack,
  getDisplayPoints,
  (selectedTrack, displayPoints) => {
    if (!selectedTrack || !displayPoints) {
      return;
    }
    return displayPoints[selectedTrack.trackId];
  }
);

export const getSelectedDisplayPoint = createSelector(
  getEventData,
  eventData => eventData.selectedDisplayPoint
);
