import React, { useState, useEffect } from 'react';
import { NotificationsTable } from './NotificationsTable';
import { PageHeader } from 'antd';
import { getInstance } from '../../../common/api/spidertracks-sdk';

const style = { overflow: 'scroll', height: '100%' };

export const NotificationsContainer = () => {
  const SpidertracksSDK = getInstance();
  const [notificationsData, setNotificationsData] = useState();
  const [eventFilterList, setEventFilterList] = useState([]);

  useEffect(() => {
    const init = async () => {
      try {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const notificationsData = await SpidertracksSDK.getEventNotificationsService().get();
        const eventFilterList = notificationsData.reduce((acc, config) => {
          return [
            ...acc,
            {
              value: config.event,
              label: config.event,
              key: config.event
            }
          ];
        }, []);
        setNotificationsData(notificationsData);
        setEventFilterList(eventFilterList);
      } catch (err) {
        console.log(err);
      }
    };
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="px-5 py-4" style={style}>
      <PageHeader
        className="site-page-header"
        style={{ paddingLeft: '0px' }}
        title="Notifications"
        subTitle="View and manage event notifications"
      />
      <NotificationsTable notificationsData={notificationsData} eventFilterList={eventFilterList} />
    </div>
  );
};

export default NotificationsContainer;
