import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useLocation, withRouter } from 'react-router-dom';
import { Table } from 'antd';
import { AircraftRow, FilterMenu, EmptyList } from './components';
import { getInstance } from '../../../common/api/spidertracks-sdk';
import { UserPreferenceKey } from '../../../common/api/spidertracks-sdk/types/UserData';
import {
  selectAircraftFromList,
  clearSelectedFlight
} from '../../../redux/reducers/aircraftReducer/thunk';
import { savedFilter } from '../../../redux/reducers/mapReducer/actions/map/index';
import * as constants from './constants';
import './styles/scss/styles.scss';
import { getAircraftList, getSelectedAircraftId } from '../../../redux/selectors/aircraftData';
import { getSelectedFilter } from '../../../redux/selectors/mapData';
import { sortAircraftBy } from '../../../helpers/sortAircraftBy';
import { gaEventHelper } from '@spidertracks/common';
import { MAP_CONTAINER } from '../Map/GoogleMap/constants';
import { aircraftListFilter } from '../../../helpers/aircraftListFilter';
import { getIsLoading } from '../../../redux/slice/loading';

const gaEventSender = gaEventHelper('AircraftListFilter');

export const AircraftList = ({
  selectedFilter,
  aircraftList,
  clearSelectedFlight,
  savedFilter,
  selectAircraftFromList,
  match,
  selectedAircraftId,
  isLoading
}) => {
  // if on history flying page, dont filter out deleted aircraft
  const onHistoryPage = useLocation().pathname.includes('/history');
  if (!onHistoryPage) {
    aircraftList = aircraftList.filter(
      aircraft => aircraft.status !== 'DELETED' && aircraft.status !== 'UNAVAILABLE'
    );
  }

  const sendGAEvent = selected => {
    switch (selected) {
      case constants.AZ:
        gaEventSender('Alphabetical', 'Filter Type Alphabetical');
        break;
      case constants.LATEST:
        gaEventSender('Latest', 'Filter Type Latest');
        break;
      case constants.ACTIVE:
        gaEventSender('Active', 'Filter Type Active');
        break;
      default:
        return;
    }
  };

  const getFilterDropdownTitle = () => {
    const findFilter = constants.FILTERS.find(obj => obj.value === selectedFilter);
    if (findFilter) {
      return findFilter.text;
    }
    return '...';
  };

  const filter = e => {
    e.preventDefault();
    const selectedFilter = e.currentTarget.getAttribute('data-filter');

    getInstance().saveUserPreference(UserPreferenceKey.AIRCRAFT_SORT, {
      sidebarTab: selectedFilter
    });

    savedFilter(selectedFilter);
    sendGAEvent(selectedFilter);
  };

  const onRowClickHandler = ({ key }) => ({
    onClick: e => {
      e.preventDefault();
      const aircraftId = key;

      if (aircraftId === selectedAircraftId) {
        clearSelectedFlight();
        return;
      }

      selectAircraftFromList(aircraftId);
    }
  });

  const filteredAircraftList = aircraftListFilter(aircraftList, selectedFilter);

  const sortedAircraftList =
    selectedFilter === constants.AZ
      ? filteredAircraftList.sort(sortAircraftBy('registration'))
      : selectedFilter === constants.LATEST
      ? filteredAircraftList.sort(sortAircraftBy('lastPointTimestamp')).reverse()
      : filteredAircraftList;

  const filterTitle = getFilterDropdownTitle();
  const isHistoryPage = match.path === '/history/:trackId';

  const columns = [
    {
      title: ' ',
      dataIndex: constants.COLUMN_ONE.dataIndex,
      render: function AircraftRowContainer(aircraft) {
        const checked = aircraft.id === selectedAircraftId;
        return <AircraftRow checked={checked} aircraft={aircraft} />;
      },
      ...(isHistoryPage
        ? {}
        : {
            filters: constants.FILTERS,
            filterMultiple: false,
            filterDropdown: function FilterDrowpdownContainer(config) {
              return (
                <FilterMenu
                  selectedFilter={selectedFilter}
                  filters={config.filters}
                  filter={filter}
                  config={config}
                />
              );
            },
            filterIcon: function FilterIconContainer() {
              return <div>{`By: ${filterTitle}`}</div>;
            }
          })
    }
  ];

  return (
    <Table
      id="aircraft-list"
      className="filter-list-table"
      loading={isLoading}
      onRow={onRowClickHandler}
      columns={columns}
      dataSource={sortedAircraftList.map(aircraft => ({
        [constants.COLUMN_ONE.dataIndex]: aircraft,
        key: aircraft.id
      }))}
      pagination={false}
      getPopupContainer={() => document.getElementById(MAP_CONTAINER)}
      locale={{ emptyText: <EmptyList /> }}
    />
  );
};

AircraftList.propTypes = {
  clearSelectedFlight: PropTypes.func,
  savedFilter: PropTypes.func,
  selectAircraftFromList: PropTypes.func,
  match: PropTypes.object,
  selectedAircraftId: PropTypes.string,
  selectedFilter: PropTypes.string,
  aircraftList: PropTypes.array,
  isLoading: PropTypes.bool
};

const mapStateToProps = state => {
  const selectedFilter = getSelectedFilter(state);
  const aircraftList = getAircraftList(state);
  const selectedAircraftId = getSelectedAircraftId(state);
  const isLoading = getIsLoading(state);

  return {
    aircraftList,
    selectedFilter,
    selectedAircraftId,
    isLoading
  };
};

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(
    {
      selectAircraftFromList,
      savedFilter,
      clearSelectedFlight
    },
    dispatch
  )
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AircraftList));
