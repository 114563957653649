import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';

import './styles/scss/styles.scss';

const FlightEventTitle = ({ aircraftRegistration, aircraftDepartedTime }) => {
  const history = useHistory();

  return (
    <div className="event-header">
      <i onClick={() => history.goBack()} className="material-icons event-go-back-icon">
        arrow_back_ios
      </i>
      <div>
        <div className="event-title">{aircraftRegistration}</div>
        <div className="event-departed-time">{aircraftDepartedTime}</div>
      </div>
    </div>
  );
};

FlightEventTitle.propTypes = {
  aircraftRegistration: PropTypes.string,
  aircraftDepartedTime: PropTypes.string
};

export default FlightEventTitle;
