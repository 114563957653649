import { Tooltip } from 'antd';
import React from 'react';

const ReplayHeaderTooltip = () => (
  <span>
    {
      "Refresh page to get the latest status. For more info on what these status' mean and other info on 3D Flight Replay, "
    }
    <a href="https://support.spidertracks.com/knowledge/beta-3d-replay" target="blank">
      {'please refer to this KB article'}
    </a>
  </span>
);

const ReplayColumnHeader = () => (
  <Tooltip placement="rightTop" title={<ReplayHeaderTooltip />}>
    {'3D REPLAY'}
  </Tooltip>
);

export default ReplayColumnHeader;
