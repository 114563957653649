import { SVG_MARKER_ICON_SIZE } from '../constants';
import { aircraftMarkerColors } from './markerColorCodes';

export const fixedWingSingleMarker = (color, rotationAngle) => `
<svg height="${SVG_MARKER_ICON_SIZE.height}" width="${
  SVG_MARKER_ICON_SIZE.width
}" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 200 200" style="enable-background:new 0 0 200 200;" xml:space="preserve">
<style type="text/css">
.st0{fill: ${aircraftMarkerColors[color.toLowerCase()] ||
  aircraftMarkerColors.green};stroke:#000;stroke-width:2;stroke-miterlimit:10;}
</style>
<g>
<path transform="rotate(${rotationAngle} 100 100)" class="st0" d="M182.1,61.6c-2.6-0.8-30.3-1.6-44.5-1.6c0,0-17.1,0-26.9,0c0,0,2.1-14.8,1.6-18.8c-0.4-4-8-5.7-10-6.5v-1.6h20
 c1.3-0.4,3.3-2.1,3.3-3.1c0-1.4-2.5-3.5-3.3-4l-20,1c-0.9-1.4-3-2.1-3.7-2.1c-0.8,0-3,1.4-3.3,2.1l-20.5-1c-1.3,0-3.3,3.1-3.3,3.5
 c0,1,2.5,3.5,3.3,3.5h20.5v2.1c-1.9,0.8-9.9,2.6-9.9,6.5C85,45.1,87,59.8,87,59.8c-1.3,0-26.9,0-26.9,0c-26.2,0-32.7,0.9-42.3,1.6
 c-1,0.2-1.3,1.6-1.3,4.5v14.9c0,1,0.7,2.7,1.6,4c1,0.8,28.2,6.1,42.3,7.1c0,0,0,0,0.4,0l27.5,1l4.2,49.7l-18.3,4.5
 c-0.9,0-1.3,1.4-1.3,2.1v13.2c0,1.4,1.3,2.1,2.1,2.1h20.5c1.3,3.5,3,10.6,4.9,10.6c2.5,0,3.7-7.1,4.9-10.6h20.9
 c0.9,0,1.3-1,1.3-1.4v-14c0-1-0.9-2.1-1.3-2.1l-18.3-4l4.2-49.7l27.4-1.4l0,0c14.2-1.4,37-4.8,42.3-7.1c1.3-1.4,1.6-3.1,1.6-4V65.9
 C183.4,63.7,182.8,62.4,182.1,61.6z"/>
</g>
</svg>
  `;

export const fixedWingTwinMarker = (color, rotationAngle) => `
  <svg height="${SVG_MARKER_ICON_SIZE.height}" width="${
  SVG_MARKER_ICON_SIZE.width
}" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 200 200" style="enable-background:new 0 0 200 200;" xml:space="preserve">
<style type="text/css">
.st0{fill:${aircraftMarkerColors[color.toLowerCase()] ||
  aircraftMarkerColors.green};stroke:#000;stroke-width:2;stroke-miterlimit:10;}
</style>
<path transform="rotate(${rotationAngle} 100 100)" class="st0" d="M150.7,64.9c-0.4,0-0.7-0.4-0.7-0.7c0-2.8,0.4-11.4,0-14c-0.4-3.1-3.1-2.4-4.5-3.1l0.5-1.7l-0.5-1.7h13
c1.1-0.4,2.5-2.5,2.5-3.2c0-1.1-1.4-2.6-2.5-2.9l-13.2,0.8c-0.4,0-1.4-1-1.8-1.3c-0.7-0.7-1.4-0.9-2.2-0.9c-0.4,0-1.1,0.2-1.8,0.9
c-0.3,0.4-1.4,1.3-1.8,1.3l-13.9-0.8c-1.1,0-2.5,2.6-2.5,2.9c0,0.4,1.4,3.3,2.5,3.3h12.7l-0.5,1.7l0.5,1.7c-1.8,0.4-4.8,0-5.2,3.1
c-0.4,2.4,0,11.4,0,14c0,0.4-0.4,0.7-0.7,0.7h-6.1H124c-2.9-1.7-11.2-7-13-8c-0.5-0.2-1-0.7-1-1c-1.6-19.5-6.1-33.5-10-33.5
c-3.9,0-8.2,13.9-10,33.4c0,0.4-0.6,0.8-1,1.1c-1,0.7-10.3,5.9-13.9,7.9h-0.4h-6.1c-0.4,0-0.7-0.4-0.7-0.7c0-2.8,0.4-11.4,0-14
c-0.4-3.1-2.6-2.4-4.4-3.1l0.5-1.7l-0.5-1.7l12.3,0.1c1.4-0.7,2.9-2.6,2.9-3.3c0-1.1-1.4-2.6-2.5-2.9l-13.2,0.7
c-0.4,0-1.3-1-1.8-1.3c-0.8-0.4-2-0.9-2.2-0.9c-0.2,0-1.4,0.4-2.2,0.9c-0.4,0.4-1.4,1.3-1.8,1.3l-13-0.8c-1.1,0-2.5,2.6-2.5,2.9
c0,0.3,1.4,2.9,2.5,2.9l12.4,0.3L53.8,45l0.5,1.7c-1.8,0.4-4.3,0-4.6,3.1c-0.4,2.4,0,11.4,0,14c0,0.4-0.4,0.7-0.7,0.7H17.2
c-2.2,0-3.8,2-4.3,3.8l-0.2,1.1c-0.2,1.6-0.2,3-0.2,4.7v5.5c0,2.8,0.4,5.3,1.4,7.7l0,0c0.4,0.7,1.1,1.4,1.8,1.4l71.2,10.8
c0.4,0,0.7,0.4,0.7,0.7l4.7,45c0,0.4-0.4,0.7-0.7,0.7l-18.8,4.3c-0.7,0-1.4,1.4-1.4,2.1v13c0,0.7,0.7,2.1,1.4,2.1h21.7
c0.4,0,0.7,0.4,0.7,0.4c1.1,3.5,2.8,9.8,4.9,9.8s3.7-6.3,5.1-9.8c0-0.4,0.4-0.4,0.7-0.4h21.7c0.7,0,1.4-1.4,1.4-2.1v-13
c0-0.7-0.7-2.1-1.4-2.1l-18.8-4.3c-0.4,0-0.7-0.4-0.7-0.7l4.7-44.6c0-0.4,0.4-0.7,0.7-0.7l69.6-10.6c2.2-0.4,3.6-1.7,3.9-3.5l0,0
c0.4-1.7,0.7-3.8,0.7-5.6v-4.9c0-2.4-0.3-4.6-0.6-6.9l-0.1-0.3c-0.4-2.1-2.2-3.5-4.3-3.5L150.7,64.9z"/>
</svg>`;

export const rotaryWingMarker = (color, rotationAngle) => `
  <svg height="${SVG_MARKER_ICON_SIZE.height}" width="${
  SVG_MARKER_ICON_SIZE.width
}" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 200 200" style="enable-background:new 0 0 200 200;" xml:space="preserve">
<style type="text/css">
.st0{fill:${aircraftMarkerColors[color.toLowerCase()] ||
  aircraftMarkerColors.green};stroke:#000;stroke-width:2;stroke-miterlimit:10;}
</style>
<g>
<g id="Layer_1-2">
 <path transform="rotate(${rotationAngle} 100 100)" class="st0" d="M80.8,163.7c-0.3,0-0.6,0.1-1,0.2c-0.8,0.3-2.1,0.7-3,2.1c-0.3,0.3-0.7,1.3-0.7,2.2c0,0.9,0.3,1.9,0.7,2.2
   c1.1,1.6,2.7,2.2,3.4,2.2h38.2c0.4,0,0.9-0.1,1.2-0.3c0.6-0.4,1.5-0.9,2.3-1.7c0.1-0.2,0.2-0.3,0.3-0.5c0.3-0.5,0.6-1.2,0.6-2
   c0-1-0.3-1.6-0.7-2.2c-0.3-0.5-1.3-1.4-2.4-1.9c-0.4-0.2-0.8-0.3-1.3-0.3h-12c-1.4,0-2.5-1.1-2.4-2.4l2.1-33.8
   c0.1-0.6,0.4-1.4,1-1.7c3.8-2.5,8.7-10.5,11-15.9c0.6-1.5,2.7-1.9,3.9-0.7l29.4,27.9c0.7,0.6,4.3-0.3,6.5-2.2
   c2-1.9,2.3-5.5,1.7-6.2l-37.6-32.8c-0.6-0.5-0.9-1.3-0.8-2.1c0.4-2.3,0.9-6.5,1.2-11.3c0-0.1,0-0.1,0-0.2c0-5.4-0.3-7.4-0.6-12.7
   c-0.1-0.6,0.2-1.4,0.8-1.7l35.6-32.5c0.6-0.5,0.9-1.4,0.7-2.2c-0.3-1.2-0.9-2.8-2-4.2c-1.5-1.5-3.6-1.4-4.8-1.5
   c-0.6,0-1.3,0.2-1.8,0.6l-28.8,25.9c-1.3,1.1-3.4,0.6-3.9-0.8C113.4,43.2,105.3,36,99.8,36c-5.8,0-14,7.3-18.3,17.6
   c-0.6,1.5-2.7,2-3.9,0.8L48.9,28.1c-0.5-0.5-1.2-0.6-1.8-0.6c-1.3,0.1-3,0.4-4.4,1.4c-0.2,0.1-0.2,0.2-0.3,0.3
   c-0.7,0.7-1.1,1.7-1.3,2.5c-0.4,1.4,0.1,2.9,1.2,4.1l35.1,32.1c0.6,0.5,0.9,1.3,0.7,2c-0.6,3.9-0.8,8-0.5,12.7
   c0,4.2,0.3,7.9,0.6,11.4c0.1,0.7-0.2,1.4-0.8,1.9l-37,32.4c-0.7,0.6-0.3,3.9,1.7,6.2c2,2.2,5.8,2.9,6.5,2.2l29.5-27.5
   c1.3-1.2,3.4-0.7,3.9,0.8c2.3,5.3,7.1,13.2,10.8,15.7c0.6,0.4,1,1,1,1.7l2.1,33.8c0.1,1.4-1.1,2.4-2.4,2.4L80.8,163.7z"/>
</g>
</g>
</svg>
  `;

export const defaultMarker = (color, rotationAngle) => `
  <svg height="${SVG_MARKER_ICON_SIZE.height}" width="${
  SVG_MARKER_ICON_SIZE.width
}" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 200 200" style="enable-background:new 0 0 200 200;" xml:space="preserve">
<style type="text/css">
.st0{fill:${aircraftMarkerColors[color.toLowerCase()] ||
  aircraftMarkerColors.green};stroke:#000;stroke-width:3;stroke-miterlimit:10;}
</style>
<path transform="rotate(${rotationAngle} 100 100)" class="st0" d="M100,162.5c-22.5,0-37.5-17.9-37.5-44.7c0-29.8,37.5-80.3,37.5-80.3s37.5,50.4,37.5,80.3
C137.5,144.6,122.5,162.5,100,162.5z"/>
</svg>
  `;

export const watchOnOff = () => `
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 200 200" style="enable-background:new 0 0 200 200;" xml:space="preserve">
<style type="text/css">
.st0{fill:#00A8D5;stroke:#0088B9;stroke-width:6;stroke-miterlimit:10;}
.st1{fill:#FFFFFF;}
</style>
<circle class="st0" cx="100" cy="100" r="60"/>
<g>
<g id="surface1">
 <path class="st1" d="M113.3,128L72,86.7c-0.7-0.7-0.5-2.1,0.4-3.1l11.2-11.2c1-1,2.3-1.2,3.1-0.4l41.3,41.3
   c0.7,0.7,0.5,2.1-0.4,3.1l-11.2,11.2C115.4,128.5,114.1,128.7,113.3,128z"/>
</g>
</g>
<g>
<g id="surface1_1_">
 <path class="st1" d="M128,86.7L86.7,128c-0.7,0.7-2.1,0.5-3.1-0.4l-11.2-11.2c-1-1-1.2-2.3-0.4-3.1L113.3,72
   c0.7-0.7,2.1-0.5,3.1,0.4l11.2,11.2C128.5,84.6,128.7,85.9,128,86.7z"/>
</g>
</g>
</svg>
`;

export const mark1 = () => `
<svg height="${SVG_MARKER_ICON_SIZE.height}" width="${SVG_MARKER_ICON_SIZE.width}" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 200 200" style="enable-background:new 0 0 200 200;" xml:space="preserve">
<style type="text/css">
	.st0{fill:#B2D21B;stroke:#8BA72D;stroke-width:6;stroke-miterlimit:10;}
	.st1{fill:#FFFFFF;}
</style>
<circle class="st0" cx="100" cy="100" r="60"/>
<path class="st1" d="M107.3,68.4l-23.7,9.2v10.2l12.6-4.4v48.2h12.6V68.4H107.3z"/>
</svg>
`;

export const mark2 = () => `
   <svg height="${SVG_MARKER_ICON_SIZE.height}" width="${SVG_MARKER_ICON_SIZE.width}" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 200 200" style="enable-background:new 0 0 200 200;" xml:space="preserve">
<style type="text/css">
	.st0{fill:#B2D21B;stroke:#8BA72D;stroke-width:6;stroke-miterlimit:10;}
	.st1{fill:#FFFFFF;}
</style>
<circle class="st0" cx="100" cy="100" r="60"/>
<path class="st1" d="M99.6,68.4c-9.3,0-14,5.4-15.8,8.6c-1.8,3.1-2.7,6.6-2.7,10.4h12.4c0-6.3,3.7-9,6.5-9c2.6,0,6.3,2.1,6.3,9
	c0,2-0.7,4.2-2,6.4c-1.3,2.2-23.3,29.3-23.3,29.3v8.5h37.9v-10H97c0,0,17.1-22.5,19.1-25.4c2.4-3.4,2.9-7,2.9-10.1
	c0-5.6-1.8-10-5.3-13C110.1,70,106,68.4,99.6,68.4z"/>
</svg>`;

export const mark3 = () => `
<svg height="${SVG_MARKER_ICON_SIZE.height}" width="${SVG_MARKER_ICON_SIZE.width}" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 200 200" style="enable-background:new 0 0 200 200;" xml:space="preserve">
<style type="text/css">
.st0{fill:#B2D21B;stroke:#8BA72D;stroke-width:6;stroke-miterlimit:10;}
.st1{fill:#FFFFFF;}
</style>
<circle class="st0" cx="100" cy="100" r="60"/>
<path class="st1" d="M99.4,68.4c-9.2,0-18.3,7-18.3,19h11c0-3.9,2.9-9,8-9c1.9,0,8.2,0.7,8.2,8c0,8-5.7,8.6-8.8,8.6h-5.1h-0.6v9.3
h5.1c1.3,0,8.8-0.6,8.8,8.7c0,1.3,0.6,8.6-7.6,8.6c-6.6,0-8.2-7-7.6-9H81.1c-0.6,6.7,3.8,19,19,19c6.3,0,19-3,19-17.9
c0-10-5.7-13.3-8.8-14.6c1.3-0.7,8.8-4.7,8.8-13.3C119,81,117.1,68.4,99.4,68.4z"/>
</svg>
`;

export const mark4 = () => `
<svg height="${SVG_MARKER_ICON_SIZE.height}" width="${SVG_MARKER_ICON_SIZE.width}"  version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 200 200" style="enable-background:new 0 0 200 200;" xml:space="preserve">
<style type="text/css">
.st0{fill:#B2D21B;stroke:#8BA72D;stroke-width:6;stroke-miterlimit:10;}
.st1{fill:#FFFFFF;}
</style>
<circle class="st0" cx="100" cy="100" r="60"/>
<g id="surface1">
<path class="st1" d="M101.7,68.4l-26.4,41.5v8.1h26.4v13.6H114V118h6.8v-10.2H114V68.4H101.7z M101.1,86.1v21.7H87.5L101.1,86.1z"
 />
</g>
</svg>
`;

export const speedup = () => `
  <svg height="${SVG_MARKER_ICON_SIZE.height}" width="${SVG_MARKER_ICON_SIZE.width}" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
  viewBox="0 0 200 200" style="enable-background:new 0 0 200 200;" xml:space="preserve">
<style type="text/css">
 .st0{fill:#FDBC24;stroke:#E4A129;stroke-width:6;stroke-miterlimit:10;}
 .st1{fill:#FFFFFF;}
</style>
<circle class="st0" cx="100" cy="100" r="60"/>
<g>
 <g id="surface1">
   <path class="st1" d="M129.2,110.4H70.8c-1,0-1.9-1.1-1.9-2.5V92.1c0-1.4,0.8-2.5,1.9-2.5h58.4c1,0,1.9,1.1,1.9,2.5v15.8
     C131.1,109.2,130.2,110.4,129.2,110.4z"/>
 </g>
</g>
<g>
 <g id="surface1_1_">
   <path class="st1" d="M110.4,70.8v58.4c0,1-1.1,1.9-2.5,1.9H92.1c-1.4,0-2.5-0.8-2.5-1.9V70.8c0-1,1.1-1.9,2.5-1.9h15.8
     C109.2,68.9,110.4,69.8,110.4,70.8z"/>
 </g>
</g>
</svg>
`;

export const speeddown = () => `
  <svg height="${SVG_MARKER_ICON_SIZE.height}" width="${SVG_MARKER_ICON_SIZE.width}" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
  viewBox="0 0 200 200" style="enable-background:new 0 0 200 200;" xml:space="preserve">
<style type="text/css">
 .st0{fill:#FDBC24;stroke:#E4A129;stroke-width:6;stroke-miterlimit:10;}
 .st1{fill:#FFFFFF;}
</style>
<circle class="st0" cx="100" cy="100" r="60"/>
<g>
 <g id="surface1">
   <path class="st1" d="M129.2,110.4H70.8c-1,0-1.9-1.1-1.9-2.5V92.1c0-1.4,0.8-2.5,1.9-2.5h58.4c1,0,1.9,1.1,1.9,2.5v15.8
     C131.1,109.2,130.2,110.4,129.2,110.4z"/>
 </g>
</g>
</svg>
`;

export const normalRocRod = () => `
  <svg height="${SVG_MARKER_ICON_SIZE.height}" width="${SVG_MARKER_ICON_SIZE.width}" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
  viewBox="0 0 200 200" style="enable-background:new 0 0 200 200;" xml:space="preserve">
<style type="text/css">
 .st0{fill:#FDBC24;stroke:#E4A129;stroke-width:6;stroke-miterlimit:10;}
 .st1{fill:#FFFFFF;}
</style>
<circle class="st0" cx="100" cy="100" r="60"/>
<path class="st1" d="M84.5,79.7l8,29.5l-19.4,0.1l-5.2-7.3l-4.9,0.2l5,18.6l63.7-0.2l0,0c0.4,0,0.9-0.1,1.3-0.2c3-0.8,4.9-4,4-7l0,0
 c-0.7-2.7-3.2-4.4-5.9-4.2h-19.7l-19-29.7L84.5,79.7z"/>
</svg>
`;

export const rocExceeded = () => `<svg height="${SVG_MARKER_ICON_SIZE.height}" width="${SVG_MARKER_ICON_SIZE.width}" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 200 200" style="enable-background:new 0 0 200 200;" xml:space="preserve">
<style type="text/css">
.st0{fill:#FDBC24;stroke:#E4A129;stroke-width:6;stroke-miterlimit:10;}
.st1{fill:#FFFFFF;}
</style>
<circle class="st0" cx="100" cy="100" r="60"/>
<path class="st1" d="M71.8,80.2l21.6,21.6l-16.7,9.7l-8.2-3.7l-4.2,2.7L77.9,124l55-32l0,0c0.4-0.3,0.8-0.5,1.1-0.8
c2.2-2.2,2.2-5.9,0-8.1l0,0c-2-2-5-2.2-7.3-0.7l-17.1,9.9L78.3,76L71.8,80.2z"/>
</svg>`;

export const rodExceeded = () => `<svg height="${SVG_MARKER_ICON_SIZE.height}" width="${SVG_MARKER_ICON_SIZE.width}" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 200 200" style="enable-background:new 0 0 200 200;" xml:space="preserve">
<style type="text/css">
.st0{fill:#FDBC24;stroke:#E4A129;stroke-width:6;stroke-miterlimit:10;}
.st1{fill:#FFFFFF;}
</style>
<circle class="st0" cx="100" cy="100" r="60"/>
<path class="st1" d="M99.5,71.3l-5.3,30.1l-17.6-8.1L75,84.5l-4.5-1.9l-3.3,18.9l57.7,26.7l0,0c0.4,0.2,0.8,0.3,1.3,0.4
c3.1,0.6,6.1-1.5,6.6-4.6l0,0c0.5-2.7-1-5.4-3.6-6.3l-17.7-8.3l-4.7-35.1L99.5,71.3z"/>
</svg>`;

export const sosOn = () => `
  <svg height="${SVG_MARKER_ICON_SIZE.height}" width="${SVG_MARKER_ICON_SIZE.width}" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
  viewBox="0 0 200 200" style="enable-background:new 0 0 200 200;" xml:space="preserve">
<style type="text/css">
 .st0{fill:#DD0C0D;stroke:#BF0402;stroke-width:6;stroke-miterlimit:10;}
 .st1{fill:#FFFFFF;}
</style>
<circle class="st0" cx="100" cy="100" r="60"/>
<g>
 <g>
   <g id="surface1">
     <path class="st1" d="M90.5,114.1V60.6c0-0.9,1-1.7,2.3-1.7h14.4c1.2,0,2.3,0.8,2.3,1.7v53.5c0,0.9-1,1.7-2.3,1.7H92.8
       C91.5,115.8,90.5,115,90.5,114.1z"/>
   </g>
 </g>
 <g>
   <g id="surface1_1_">
     <path class="st1" d="M90.5,138.8v-14.4c0-1.2,1-2.3,2.3-2.3h14.4c1.2,0,2.3,1,2.3,2.3v14.4c0,1.2-1,2.3-2.3,2.3H92.8
       C91.5,141.1,90.5,140,90.5,138.8z"/>
   </g>
 </g>
</g>
</svg>
`;

export const sosOff = () => `
  <svg height="${SVG_MARKER_ICON_SIZE.height}" width="${SVG_MARKER_ICON_SIZE.width}" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
  viewBox="0 0 200 200" style="enable-background:new 0 0 200 200;" xml:space="preserve">
<style type="text/css">
 .st0{fill:#DD0C0D;stroke:#BF0402;stroke-width:6;stroke-miterlimit:10;}
 .st1{fill:#FFFFFF;}
</style>
<circle class="st0" cx="100" cy="100" r="60"/>
<g>
 <g id="surface1">
   <path class="st1" d="M113.3,128L72,86.7c-0.7-0.7-0.5-2.1,0.4-3.1l11.2-11.2c1-1,2.3-1.2,3.1-0.4l41.3,41.3
     c0.7,0.7,0.5,2.1-0.4,3.1l-11.2,11.2C115.4,128.5,114.1,128.7,113.3,128z"/>
 </g>
</g>
<g>
 <g id="surface1_1_">
   <path class="st1" d="M128,86.7L86.7,128c-0.7,0.7-2.1,0.5-3.1-0.4l-11.2-11.2c-1-1-1.2-2.3-0.4-3.1L113.3,72
     c0.7-0.7,2.1-0.5,3.1,0.4l11.2,11.2C128.5,84.6,128.7,85.9,128,86.7z"/>
 </g>
</g>
  </svg>
`;

export const radius = () => `
  <svg height="${SVG_MARKER_ICON_SIZE.height}" width="${SVG_MARKER_ICON_SIZE.width}" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
  viewBox="0 0 200 200" style="enable-background:new 0 0 200 200;" xml:space="preserve">
<style type="text/css">
 .st0{fill:#FDBC24;stroke:#E4A129;stroke-width:6;stroke-miterlimit:10;}
 .st1{fill:#FFFFFF;stroke:#E4A129;stroke-miterlimit:10;}
 .st2{fill:#FDBC24;stroke:#E4A129;stroke-miterlimit:10;}
</style>
<circle class="st0" cx="100" cy="100" r="60"/>
<circle class="st1" cx="100" cy="100" r="36.3"/>
<circle class="st2" cx="100" cy="100" r="20.5"/>
</svg>
`;

export const altitude = rotationAngle => `
  <svg height="${SVG_MARKER_ICON_SIZE.height}" width="${SVG_MARKER_ICON_SIZE.width}" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 200 200" style="enable-background:new 0 0 200 200;" xml:space="preserve">
  <circle style="fill:#FDBC24;stroke:#E4A129;stroke-width:6;stroke-miterlimit:10;" cx="100" cy="100" r="95"/>
  <path transform="rotate(${rotationAngle} 100 100)" style="fill:#FFFFFF;" d="M52.21,139h95.57c1.72,0,2.78-1.87,1.9-3.35L101.9,56.01c-0.86-1.43-2.93-1.43-3.79,0l-47.79,79.64
    C49.43,137.13,50.5,139,52.21,139z"/>
  </svg>
`;

// default 0.4

export const activeMarker = () => `
  <svg height="${SVG_MARKER_ICON_SIZE.height}" width="${SVG_MARKER_ICON_SIZE.width}" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
  viewBox="0 0 200 200" style="enable-background:new 0 0 200 200;" xml:space="preserve">
<style type="text/css">
 .st0{opacity:0.7;fill:#CBCBCB;enable-background:new;}
 .st1{opacity:0.75;fill:#050707;enable-background:new;}
</style>
<g>
 <circle class="st0" cx="100" cy="100" r="93.5"/>
 <rect x="98.5" y="185" class="st1" width="3" height="15"/>
 <rect y="98.5" class="st1" width="15" height="3"/>
 <rect x="185" y="98.5" class="st1" width="15" height="3"/>
 <rect x="98.5" class="st1" width="3" height="15"/>
</g>
</svg>
`;

export const crossFireMarker = () => `
<svg height="${SVG_MARKER_ICON_SIZE.height}" width="${SVG_MARKER_ICON_SIZE.width}" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 31.444 31.444" style="enable-background:new 0 0 31.444 31.444;" xml:space="preserve">
<path style="fill:#1E201D;" d="M1.119,16.841c-0.619,0-1.111-0.508-1.111-1.127c0-0.619,0.492-1.111,1.111-1.111h13.475V1.127
	C14.595,0.508,15.103,0,15.722,0c0.619,0,1.111,0.508,1.111,1.127v13.476h13.475c0.619,0,1.127,0.492,1.127,1.111
	c0,0.619-0.508,1.127-1.127,1.127H16.833v13.476c0,0.619-0.492,1.127-1.111,1.127c-0.619,0-1.127-0.508-1.127-1.127V16.841H1.119z"
	/>
</svg>
`;

export const greyRoundIcon = () => `
<svg height="${SVG_MARKER_ICON_SIZE.height}" width="${SVG_MARKER_ICON_SIZE.width}" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="100" cy="100" r="60" fill="#9D9D9D" stroke="#222222" stroke-width="16"/>
</svg>
`;

export const startFinishTrackIcon = () => `
<svg width="${SVG_MARKER_ICON_SIZE.width}" height="${SVG_MARKER_ICON_SIZE.height}" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="50" cy="50" r="21" fill="white" stroke="#BFBFBF" stroke-width="5"/>
</svg>
`;
