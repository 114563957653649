import React from 'react';
import PropTypes from 'prop-types';
import { Form, Row, Col, Select } from 'antd';
import { VFDRSupportNoticeInfo } from '../../../helpers/globalNotifications';

const formItemStyle = { marginBottom: '1px' };

const centerStyle = {
  textAlign: 'center',
  padding: '6px 10px'
};

const { Option } = Select;

const SpiderSettingsReportingIntervalDropdown = ({
  name,
  label,
  getFieldDecorator,
  setFieldsValue,
  validation,
  setSaveButtonDisabled
}) => {
  const dropdownOptions = [{ value: 'VFDR' }, { value: 1 }, { value: 2 }, { value: 10 }];
  const options = dropdownOptions.map(option => {
    return (
      <Option key={option.value} value={option.value}>
        {option.value}
      </Option>
    );
  });

  const setInput = value => {
    setSaveButtonDisabled(false);
    setFieldsValue({ [name]: value });
    if (value === 'VFDR') {
      VFDRSupportNoticeInfo();
    }
  };

  return (
    <Form.Item name={name} label={label} style={formItemStyle}>
      <Row>
        <Col span={16}>
          {getFieldDecorator(name, {
            rules: [
              {
                required: validation.required,
                message: validation.errorMessage
              }
            ]
          })(
            <Select onSelect={setInput} size="large">
              {options}
            </Select>
          )}
        </Col>
        <Col className="d-flex" span={8} style={centerStyle}>
          minute(s)
        </Col>
      </Row>
    </Form.Item>
  );
};

SpiderSettingsReportingIntervalDropdown.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  validation: PropTypes.object.isRequired,
  getFieldDecorator: PropTypes.func.isRequired,
  setFieldsValue: PropTypes.func.isRequired,
  setSaveButtonDisabled: PropTypes.func
};

export default SpiderSettingsReportingIntervalDropdown;
