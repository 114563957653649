import React, { useCallback, useEffect, useState } from 'react';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { MultiSelectOverlay } from '@spidertracks/components';

interface FilterDropdownProps {
  confirm: Function;
  clearSelection?: Function;
  selectedKeys: CheckboxValueType[];
  filters: {
    value: string | number;
    label: string | number;
    key?: string | number;
  }[];
  searchInput: string;
  onFilter: Function;
  setSelectedKeys: Function;
  clearFilters: Function;
  remoteTrigger?: Function;
  displayName: string;
  style: object;
  showSearch?: boolean;
  updateOnConfirm?: boolean;
  visible?: boolean;
}

export const FilterDropdown = ({
  clearFilters,
  clearSelection,
  confirm,
  displayName,
  filters = [],
  onFilter,
  selectedKeys,
  setSelectedKeys,
  showSearch,
  style,
  updateOnConfirm = false,
  visible
}: FilterDropdownProps) => {
  const [modified, setModified] = useState(false);

  const onConfirm = () => {
    if (modified) {
      setModified(false);
      onFilter(selectedKeys);
    }

    // NOTE: only required to make AntD close the filter.
    confirm();
  };

  useEffect(() => {
    if (updateOnConfirm && !visible && modified) {
      onConfirm();
    }
  }, [updateOnConfirm, modified, onConfirm, selectedKeys, visible]);

  const onReset = () => {
    clearFilters();
    onFilter([]);
    if (clearSelection) {
      clearSelection();
    }
    setModified(false);
  };

  const onSelect = useCallback(
    (selected: CheckboxValueType[]) => {
      if (!updateOnConfirm) {
        return onFilter(selected);
      }

      setModified(true);
      setSelectedKeys(selected);
    },
    [onFilter, setSelectedKeys, updateOnConfirm]
  );

  return (
    <MultiSelectOverlay
      displayName={displayName}
      onConfirm={onConfirm}
      onReset={onReset}
      onSelect={onSelect}
      options={filters}
      selected={selectedKeys}
      showSearch={showSearch}
      style={style}
      visible={visible}
    />
  );
};
