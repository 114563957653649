import { UserData, NavigationState } from '../types';
import { FullState } from '../../store';
import { createSelector } from 'reselect';
import { Organisation } from '../../common/api/spidertracks-sdk/types/Organisation';

export const getNavigationData = (state: FullState): NavigationState => state.navigationReducer;

export const getUserData = createSelector(
  [getNavigationData],
  (navigationData: NavigationState): UserData => navigationData.userData
);

export const getUserId = createSelector(
  [getNavigationData],
  (navigationData: NavigationState): string => navigationData.userData.id
);

export const getUserOrgData = createSelector(
  [getUserData],
  (userData: UserData): Organisation[] => userData.orgs
);

export const isOrgRoleMember = (org: Organisation) => org.role === 'MEMBER';
export const isOrgRoleOwnerOrAdmin = (org: Organisation) =>
  org.role === 'OWNER' || org.role === 'ADMIN';

export const orgByOrgName = (a: Organisation, b: Organisation) =>
  a.org.orgName > b.org.orgName ? 1 : -1;

export const getUserOwnerAdminOrgs = createSelector(
  [getUserOrgData],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (orgsData: any = []): Organisation => orgsData.filter(isOrgRoleOwnerOrAdmin).sort(orgByOrgName)
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getUserMemberOrgs = createSelector([getUserOrgData], (orgsData: any = []) =>
  orgsData.filter((org: Organisation) => org.role === 'MEMBER')
);

export const getExclusions = createSelector(
  [getNavigationData],
  navigationData => navigationData.exclusions
);

export const getSpiderTxtCount = createSelector(
  [getNavigationData],
  navigationData => navigationData.spidertxtCount
);

export const getSosNotifications = createSelector(
  [getNavigationData],
  navigationData => navigationData.sosNotifications
);

export const getDateTimeFormat = createSelector(
  [getUserData],
  userData => `${userData.dateFormat}`.replace(/_/g, '/') + ' HH:mm:ss z'
);

export const getDateFormat = createSelector([getUserData], userData =>
  `${userData.dateFormat}`.replace(/_/g, '/')
);

export const getHideMapRadiusShading = createSelector(
  [getUserData],
  userData => userData.hideMapRadiusShading
);

export const getFavourites = createSelector(
  [getNavigationData],
  navigationData => navigationData.favourites
);

export const getLatLongFormat = createSelector([getUserData], userData => userData.latLongFormat);

export const getTimezone = createSelector([getUserData], userData => userData.timezone);

export const getDistanceUnit = createSelector([getUserData], userData => userData.distanceUnit);

export const getSpeedUnit = createSelector([getUserData], userData => userData.speedUnit);

export const getAltitudeUnit = createSelector([getUserData], userData => userData.altitudeUnit);

export default {
  getNavigationData,
  getUserData,
  getExclusions,
  getSpiderTxtCount,
  getSosNotifications,
  getDateTimeFormat,
  getDateFormat,
  getHideMapRadiusShading,
  getFavourites,
  getLatLongFormat,
  getTimezone,
  getDistanceUnit,
  getSpeedUnit,
  getUserOrgData,
  getUserOwnerAdminOrgs,
  getUserMemberOrgs,
  getAltitudeUnit
};
