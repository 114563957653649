import { formatCoordinates } from 'helpers/formatCoordinates';
import { convertMetresTo, convertKnotsTo } from '../../components/Flying/Map/utils/helper';
import { getEventDisplayValues } from '../../components/Flying/Map/utils/eventDisplayHelper';
import { EventClass, GeofenceEventType } from '../../constants';
import { formatSpeedFromMetersPerSeconds } from '../../helpers';
import { decodeGeoHash } from '../../util/geoHash';

export const getEventDisplayName = (event, eventTypeNameMap) => {
  if (event.eventClass === EventClass.GEOFENCE) {
    switch (event.eventType) {
      case GeofenceEventType.BreachIn:
        return 'Geofence Entry';
      case GeofenceEventType.BreachOut:
        return 'Geofence Exit';
      default:
        return 'Geofence';
    }
  }
  return eventTypeNameMap[event.eventType];
};

export const getEventProperties = ({ event, latLongFormat, altitudeUnit, speedUnit }) => {
  if (event.eventClass === EventClass.FSI) {
    const { latitude, longitude } = decodeGeoHash(event.geohash);
    const { altitude, groundSpeed, heading } = event.eventDetails;
    const eventDisplayValues = getEventDisplayValues(event) || {};

    return {
      latitude,
      longitude,
      coordinates: formatCoordinates({ latitude, longitude }, latLongFormat),
      altitude: convertMetresTo(altitude, altitudeUnit, 0)[0],
      altitudeUnit,
      speed: Math.ceil(formatSpeedFromMetersPerSeconds(groundSpeed, speedUnit)),
      speedUnit,
      heading: Math.round(heading),
      ...eventDisplayValues,
      type: 'fsi'
    };
  }

  if (event.eventClass === EventClass.GEOFENCE) {
    const { latitude, longitude } = decodeGeoHash(event.geohash);
    const { heading, altitude, speed } = event.eventDetails.point.properties;

    return {
      latitude,
      longitude,
      coordinates: formatCoordinates({ latitude, longitude }, latLongFormat),
      heading,
      altitude: convertMetresTo(altitude, altitudeUnit, 0)[0],
      altitudeUnit,
      speed: convertKnotsTo(speed, speedUnit),
      speedUnit,
      type: 'geofence'
    };
  }

  return {};
};

export function mapEventsToTrackPoints({
  events,
  eventTypes,
  latLongFormat,
  speedUnit,
  altitudeUnit
}) {
  const eventTypeNameMap = eventTypes.reduce((typeNameMapping, event) => {
    typeNameMapping[event.eventType] = event.eventTypeDisplayName;
    return typeNameMapping;
  }, {});

  return events.map(event => ({
    buttonMode: 200,
    timestamp: new Date(event.eventTime).getTime(),
    id: event.eventId,
    description: getEventDisplayName(event, eventTypeNameMap),
    ...getEventProperties({ event, latLongFormat, speedUnit, altitudeUnit })
  }));
}
