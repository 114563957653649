import { MultiSelectForm } from '@spidertracks/components';
import { Form, Row } from 'antd';
import { CheckboxOptionType } from 'antd/lib/checkbox';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { FormComponentProps } from 'antd/lib/form';
import React, { FC, useEffect } from 'react';

interface AircraftSelectorProps extends FormComponentProps {
  initialValue: CheckboxValueType[];
  isEditForm: boolean;
  options: CheckboxOptionType[];
}

const getClosedLabel = (options: CheckboxOptionType[], selected: CheckboxValueType[]) => {
  if (options.length === selected.length) {
    return 'All Aircraft';
  }

  if (selected.length) {
    return `${selected.length} Aircraft`;
  }

  return 'Select Aircraft';
};

export const AircraftSelector: FC<AircraftSelectorProps> = ({ form, initialValue, options }) => {
  const onReset = () => form.setFieldsValue({ aircraft: initialValue });

  useEffect(() => {
    form.resetFields();
  }, [initialValue.length]);

  return (
    <Form.Item label={'Aircraft'} style={{ textAlign: 'left' }} className="mt-3">
      <Row>
        {form.getFieldDecorator('aircraft', {
          initialValue,
          rules: [
            {
              required: true,
              message: 'Please select aircraft'
            }
          ]
        })(
          <MultiSelectForm
            closedLabel={getClosedLabel(options, form.getFieldValue('aircraft'))}
            fieldName="aircraft"
            form={form}
            options={options}
            onReset={onReset}
            showAll
            showSearch
            shifted={true}
          />
        )}
      </Row>
    </Form.Item>
  );
};

export default AircraftSelector;
