import React, { FC, Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getInstance } from '../../../common/api/spidertracks-sdk';
import { PrivateTrackData } from '../../../common/api/spidertracks-sdk/types/TrackData';
import { TrackHistoryRequest } from '../../../common/api/spidertracks-sdk/types/TrackHistoryRequest';
import { throwIfRequestError } from '../../../helpers';
import { trackFetchError, trackNotFound } from '../../../helpers/globalNotifications';
import { setSelectedFlight } from '../../../redux/reducers/aircraftReducer/thunk';
import { savedFilter } from '../../../redux/reducers/mapReducer/actions/map';
import { getSelectedTrack } from '../../../redux/selectors/aircraftData';
import {
  getSelectedTrackDisplayPoints,
  getSelectedTrackEventTypes,
  getSelectedTrackFlightStatus
} from '../../../redux/selectors/eventData';
import { getSelectedFilter } from '../../../redux/selectors/mapData';
import { fetchSelectedTrackAircraft } from '../../../redux/slice/aircraft';
import {
  clearSelectedDisplayPoint,
  fetchEvents,
  setEvents,
  setEventTypes,
  setFlightStatus,
  setSelectedDisplayPoint
} from '../../../redux/slice/events';
import {
  clearSelectedTracks,
  setLatestTracks,
  setSelectedTracks,
  setSelectedTrackId
} from '../../../redux/slice/tracks';
import { AZ } from '../../Flying/AircraftList/constants';
import { LoadingBackground } from '../../LoadingBackground';

interface TrackIdParams {
  trackId: string;
}
interface SNBootcountParams {
  serialNumber: string;
  bootcount: string;
}
type PreselectTrackParams = TrackIdParams | SNBootcountParams;

// Wrap a child component in this to prevent its display until a specific track has been loaded and
// is available in the store. Designed to work on any route with `:trackId` or with `:serialNumber` & `:bootcount` in it.
// Also loads relevant events. Waits for everything to arrive in store, then renders the child component(s).
export const PreselectTrack: FC = ({ children }) => {
  const dispatch = useDispatch();
  const params = useParams<PreselectTrackParams>();
  const input = 'trackId' in params ? { trackIds: params.trackId } : params;
  const SpidertracksSDK = getInstance();

  const eventTypes = useSelector(getSelectedTrackEventTypes);
  const flightStatus = useSelector(getSelectedTrackFlightStatus);
  const displayPoints = useSelector(getSelectedTrackDisplayPoints);
  const selectedTrack = useSelector(getSelectedTrack);
  const selectedAircraftFilter = useSelector(getSelectedFilter);

  const [ready, setReady] = useState(false);

  useEffect(() => {
    const fetchTrack = async () => {
      dispatch(savedFilter(AZ));
      try {
        const trackHistoryResponse = await SpidertracksSDK.getTrackHistory(
          input as TrackHistoryRequest
        );
        const track = trackHistoryResponse.items[0] as PrivateTrackData;
        dispatch(setLatestTracks(trackHistoryResponse.items));
        dispatch(setSelectedTracks([track.trackId]));
        dispatch(setSelectedTrackId(track.trackId));
        dispatch(setSelectedFlight(track));
        if (!track.aircraft || track.aircraft.id === '') {
          dispatch(setEvents({ items: [] }));
        } else {
          dispatch(fetchEvents(track.aircraft.id, track.departedTime, track.endTime));
        }
      } catch (e) {
        console.error(e);
        if (e.message.includes('404')) {
          return trackNotFound();
        }
        trackFetchError();
      }
    };

    fetchTrack();

    return () => {
      dispatch(clearSelectedTracks());
      dispatch(clearSelectedDisplayPoint());
      dispatch(savedFilter(selectedAircraftFilter));
    };
  }, []);

  useEffect(() => {
    // TODO: this might be a good candidate for caching, will infrequently change
    const fetchOrganisationEventTypes = async () => {
      if (selectedTrack && !eventTypes) {
        if (!selectedTrack.aircraft.org) {
          dispatch(setEventTypes({ trackId: selectedTrack.trackId, eventTypes: [] }));
          return;
        }

        try {
          const eventTypesResponse = await SpidertracksSDK.getEventService().getEventTypes(
            selectedTrack.aircraft.org.id
          );
          throwIfRequestError(eventTypesResponse);
          dispatch(
            setEventTypes({ trackId: selectedTrack.trackId, eventTypes: eventTypesResponse.items })
          );
        } catch (e) {
          console.error(e);
          trackFetchError();
        }
      }
    };

    fetchOrganisationEventTypes();
  }, [eventTypes, selectedTrack]);

  useEffect(() => {
    // TODO: another good candidate for a cached value in Redux
    const fetchFlightStatus = async () => {
      if (selectedTrack && !flightStatus) {
        try {
          const flightStatusResponse = await SpidertracksSDK.getFlightDataService().getFlightStatus(
            [selectedTrack.id]
          );
          dispatch(
            setFlightStatus({
              trackId: selectedTrack.trackId,
              flightStatus: flightStatusResponse.items[0].status
            })
          );
        } catch (e) {
          console.error(e);
          trackFetchError();
        }
      }
    };

    fetchFlightStatus();
  }, [flightStatus, selectedTrack]);

  useEffect(() => {
    if (displayPoints && displayPoints.length && !ready) {
      dispatch(fetchSelectedTrackAircraft());
      dispatch(setSelectedDisplayPoint(displayPoints[displayPoints.length - 1]));
      setReady(true);
    }
  }, [displayPoints, ready]);

  return <Fragment>{ready ? children : <LoadingBackground />}</Fragment>;
};

export default PreselectTrack;
