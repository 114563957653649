/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, Row, Button, Spin } from 'antd';
import { getInstance } from '../../../common/api/spidertracks-sdk';
import { getSpeedUnit } from '../../../redux/selectors/userData';
import {
  formatSpeedFromMetersPerSeconds,
  formatSpeedToMetersPerSeconds,
  roundToTenth
} from '../../../helpers/formatSpeed';
import SpiderSettingsInput from './SpiderSettingsInput';
import SpiderSettingsRodRocDropdown from './SpiderSettingsRodRocDropdown';
import SpiderSettingReportingIntervalDropdown from './SpiderSettingsReportingIntervalDropdown';
import { getSpiderSettingsError } from '../../../helpers/globalNotifications';

export const validateField = (unit, field) => {
  const speedField = {
    knots: {
      validationRegexPattern: '^([5-9]|[1-8][0-9]|9[0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-4]|0)$',
      errorMessage: 'Unsupported value. Must be a whole number between 5-254 knots.'
    },
    kmh: {
      validationRegexPattern: '^([1-8][0-9]|9[0-9]|[1-3][0-9]{2}|4[0-6][0-9]|0|470)$',
      errorMessage: 'Unsupported value. Must be a whole number between 10-470 km/h.'
    },
    mih: {
      validationRegexPattern: '^([6-9]|[1-8][0-9]|9[0-9]|1[0-9]{2}|2[0-8][0-9]|0|290)$',
      errorMessage: 'Unsupported value. Must be a whole number between 6-290 mi/h.'
    },
    ms: {
      validationRegexPattern: '^([3-9]|[1-8][0-9]|9[0-9]|1[0-2][0-9]|0|130)$',
      errorMessage: 'Unsupported value. Must be a whole number between 3-130 m/s.'
    }
  };

  const autowatchField = {
    knots: {
      validationRegexPattern: '^([1-8][0-9]|9[0-9]|[1-8][0-9]{2}|9[0-8][0-9]|0|99[0-9])$',
      errorMessage: 'Unsupported value. Must be a whole number between 10-999 knots.'
    },
    kmh: {
      validationRegexPattern:
        '^(19|[2-9][0-9]|[1-8][0-9]{2}|9[0-8][0-9]|99[0-9]|1[0-7][0-9]{2}|18[0-4][0-9]|0|1850)$',
      errorMessage: 'Unsupported value. Must be a whole number between 19-1850 km/h.'
    },
    mih: {
      validationRegexPattern:
        '^(1[2-9]|[2-9][0-9]|[1-8][0-9]{2}|9[0-8][0-9]|99[0-9]|10[0-9]{2}|11[0-4][0-9]|0|1150)$',
      errorMessage: 'Unsupported value. Must be a whole number between 12-1150 mi/h.'
    },
    ms: {
      validationRegexPattern: '^([6-9]|[1-8][0-9]|9[0-9]|[1-4][0-9]{2}|50[0-9]|51[0-4]|0)$',
      errorMessage: 'Unsupported value. Must be a whole number between 6-514 m/s.'
    }
  };
  switch (field) {
    case 'autowatch':
      switch (unit) {
        case 'knots':
          return autowatchField.knots;
        case 'km/h':
          return autowatchField.kmh;
        case 'mi/h':
          return autowatchField.mih;
        case 'm/s':
          return autowatchField.ms;
        default:
          return autowatchField.ms;
      }
    case 'speed':
      switch (unit) {
        case 'knots':
          return speedField.knots;
        case 'km/h':
          return speedField.kmh;
        case 'mi/h':
          return speedField.mih;
        case 'm/s':
          return speedField.ms;
        default:
          return speedField.ms;
      }
    default:
      throw new Error('Unknown Field');
  }
};

export const SpiderSettingsForm = ({
  onClose,
  serialNumber,
  userSpeedUnit,
  form,
  aircraftRegistration,
  saveButtonDisabled,
  setSaveButtonDisabled
}) => {
  const [isSaving, setIsSaving] = useState(false);

  const [isSpiderSettingsLoading, setIsSpiderSettingsLoading] = useState(true);
  const SpidertracksSDK = getInstance();

  const { getFieldValue, getFieldDecorator, setFieldsValue, getFieldsError, validateFields } = form;

  const fieldsError = getFieldsError();
  let hasErrors = false;
  if (Object.entries(fieldsError).length !== 0) {
    hasErrors = Object.values(fieldsError).some(value => value);
  }

  const disabled = saveButtonDisabled || isSaving || hasErrors;

  const speedValidation = validateField(userSpeedUnit, 'speed');
  const autowatchValidation = validateField(userSpeedUnit, 'autowatch');

  useEffect(() => {
    let formattedSettings = {};
    const fetchSpiderSettings = async serialNumber => {
      try {
        const { settings } = await SpidertracksSDK.getSpiderSettingsService().getSpiderSettings(
          serialNumber
        );
        const {
          version,
          timeTriggerPrimaryS,
          timeTriggerSecondaryS,
          headingTriggerDegrees,
          verticalSpeedClimbEntryTriggerMpsE1,
          verticalSpeedDescentEntryTriggerMpsE1,
          horizontalSpeed_1EntryTriggerMpsE1,
          autoWatchTriggerMpsE1
        } = settings;

        setIsSpiderSettingsLoading(false);

        const reportingInterval =
          timeTriggerPrimaryS / 60 === 1 && timeTriggerSecondaryS === 15
            ? 'VFDR'
            : timeTriggerPrimaryS / 60;

        // Format speed from meters/second to the user speed unit format
        formattedSettings = {
          version: version,
          reportingInterval,
          headingTriggerDegrees,
          // the descent and climb rate values are not rounded as they will end up in a
          // Select component where the values are mapped. Check the "SpiderSettingsRodRocDropdown" component
          descentRateThreshold: formatSpeedFromMetersPerSeconds(
            verticalSpeedDescentEntryTriggerMpsE1,
            'ft/min'
          ),
          climbRateThreshold: formatSpeedFromMetersPerSeconds(
            verticalSpeedClimbEntryTriggerMpsE1,
            'ft/min'
          ),
          // round the formatted speed as the input component only allows whole numbers.
          speedUpDownThreshold: Math.round(
            formatSpeedFromMetersPerSeconds(horizontalSpeed_1EntryTriggerMpsE1, userSpeedUnit)
          ),
          automatedWatchThreshold: Math.round(
            formatSpeedFromMetersPerSeconds(autoWatchTriggerMpsE1, userSpeedUnit)
          )
        };
        setFieldsValue({ ...formattedSettings });
      } catch (e) {
        getSpiderSettingsError();
        setIsSpiderSettingsLoading(false);
        console.error(e);
      }
    };

    fetchSpiderSettings(serialNumber);
  }, []);

  const saveSpiderSettings = async e => {
    e.preventDefault();

    validateFields(async (err, values) => {
      if (!err) {
        const formattedSettings = {
          settings: {
            timeTriggerPrimaryS:
              values.reportingInterval === 'VFDR' ? 60 : values.reportingInterval * 60,
            timeTriggerSecondaryS: values.reportingInterval === 'VFDR' ? 15 : 0,
            headingTriggerDegrees: parseFloat(values.headingTriggerDegrees),
            // Format speed to meters/second from the user speed unit format, then rounds it to the tenth.
            // The use case here is updating the speed settings in a spider, where the API only accepts numbers with one place decimal,
            // which get converted later on to Integer to be updated on the spider
            verticalSpeedDescentEntryTriggerMpsE1: roundToTenth(
              formatSpeedToMetersPerSeconds(values.descentRateThreshold, 'ft/min')
            ),
            verticalSpeedClimbEntryTriggerMpsE1: roundToTenth(
              formatSpeedToMetersPerSeconds(values.climbRateThreshold, 'ft/min')
            ),
            horizontalSpeed_1EntryTriggerMpsE1: roundToTenth(
              formatSpeedToMetersPerSeconds(values.speedUpDownThreshold, userSpeedUnit)
            ),
            autoWatchTriggerMpsE1: roundToTenth(
              formatSpeedToMetersPerSeconds(values.automatedWatchThreshold, userSpeedUnit)
            )
          }
        };
        setIsSaving(true);
        await SpidertracksSDK.getSpiderSettingsService().updateSpiderSettings(
          serialNumber,
          formattedSettings
        );
        setIsSaving(false);
        onClose();
      }
    });
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      {isSpiderSettingsLoading ? (
        <Spin />
      ) : (
        <Form
          onSubmit={saveSpiderSettings}
          layout="vertical"
          hideRequiredMark={true}
          style={{ width: '308px', margin: '30px 0 0 32px' }}
        >
          <p style={{ fontSize: '1.1em', marginBottom: '30px' }}>
            {aircraftRegistration ? (
              <span style={{ fontWeight: 'bold' }}>{aircraftRegistration}</span>
            ) : (
              `No Aircraft assigned`
            )}
          </p>
          <SpiderSettingReportingIntervalDropdown
            name="reportingInterval"
            label="Reporting Interval"
            validation={{
              required: true,
              errorMessage: 'Rate of Descent threshold is required'
            }}
            setFieldsValue={setFieldsValue}
            getFieldValue={getFieldValue}
            getFieldDecorator={getFieldDecorator}
            setSaveButtonDisabled={setSaveButtonDisabled}
          />
          <SpiderSettingsInput
            name="headingTriggerDegrees"
            label="Heading Change"
            unit="degrees"
            setFieldsValue={setFieldsValue}
            getFieldValue={getFieldValue}
            getFieldDecorator={getFieldDecorator}
            validation={{
              validationRegexPattern: '^([3-8][0-9]|0|90)$',
              errorMessage: 'Unsupported value. Must be a whole number between 30-90 degrees.'
            }}
            setSaveButtonDisabled={setSaveButtonDisabled}
          />
          <SpiderSettingsRodRocDropdown
            name="descentRateThreshold"
            label="Rate of descent threshold"
            unit="ft/min"
            validation={{
              required: true,
              errorMessage: 'Rate of Descent threshold is required'
            }}
            setFieldsValue={setFieldsValue}
            getFieldValue={getFieldValue}
            getFieldDecorator={getFieldDecorator}
            setSaveButtonDisabled={setSaveButtonDisabled}
          />
          <SpiderSettingsRodRocDropdown
            name="climbRateThreshold"
            label="Rate of climb threshold"
            unit="ft/min"
            setFieldsValue={setFieldsValue}
            getFieldValue={getFieldValue}
            getFieldDecorator={getFieldDecorator}
            validation={{
              required: true,
              errorMessage: 'Rate of Climb threshold is required'
            }}
            setSaveButtonDisabled={setSaveButtonDisabled}
          />
          <SpiderSettingsInput
            name="speedUpDownThreshold"
            label="Speed threshold"
            unit={userSpeedUnit}
            setFieldsValue={setFieldsValue}
            getFieldValue={getFieldValue}
            getFieldDecorator={getFieldDecorator}
            validation={speedValidation}
            setSaveButtonDisabled={setSaveButtonDisabled}
          />
          <SpiderSettingsInput
            name="automatedWatchThreshold"
            label="Automated Watch threshold"
            unit={userSpeedUnit}
            setFieldsValue={setFieldsValue}
            getFieldValue={getFieldValue}
            getFieldDecorator={getFieldDecorator}
            validation={autowatchValidation}
            setSaveButtonDisabled={setSaveButtonDisabled}
          />
          <Form.Item style={{ textAlign: 'center' }} className="mt-3">
            <Row>
              <Button
                className="mx-2 px-3"
                type="secondary"
                size="large"
                onClick={onClose}
                style={{ width: '85px' }}
              >
                Cancel
              </Button>
              <Button
                className="mx-2 px-4"
                disabled={disabled}
                type="primary"
                htmlType="submit"
                size="large"
                style={{ width: '85px' }}
              >
                {isSaving ? (
                  <span className="px-2">
                    <Spin size="small" />
                  </span>
                ) : (
                  'Save'
                )}
              </Button>
            </Row>
          </Form.Item>
        </Form>
      )}
    </div>
  );
};

SpiderSettingsForm.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  serialNumber: PropTypes.string.isRequired,
  userSpeedUnit: PropTypes.string.isRequired,
  form: PropTypes.object,
  refreshDeviceTable: PropTypes.func,
  aircraftRegistration: PropTypes.string,
  saveButtonDisabled: PropTypes.bool.isRequired,
  setSaveButtonDisabled: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  userSpeedUnit: getSpeedUnit(state)
});

export const WrappedSpiderSettingsForm = Form.create()(SpiderSettingsForm);

export default connect(mapStateToProps)(WrappedSpiderSettingsForm);
