import React, { FC, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../../../../assets/scss/global.scss';
import {
  dateFormatter,
  formatStandardDateStrings,
  fromTimestamp
} from '../../../../helpers/formatTime';
import { getSelectedTrack } from '../../../../redux/selectors/aircraftData';
import {
  getSelectedDisplayPoint,
  getSelectedTrackDisplayPoints
} from '../../../../redux/selectors/eventData';
import { getDateTimeFormat, getTimezone } from '../../../../redux/selectors/userData';
import { setSelectedDisplayPoint } from '../../../../redux/slice/events';
import { POINT_INFO_PANEL } from '../GoogleMap/constants';
import EtaButtonContainer from './etapanel/EtaButtonContainer';
import PanelBody from './PanelBody';
import PanelSlider from './PanelSlider';
import RowInfo from './RowInfo';

export const EventsPointInfoPanel: FC = () => {
  const dispatch = useDispatch();

  const dateFormat = useSelector(getDateTimeFormat);
  const points = useSelector(getSelectedTrackDisplayPoints);
  const selectedTrack = useSelector(getSelectedTrack);
  const selectedDisplayPoint = useSelector(getSelectedDisplayPoint);
  const timezone = useSelector(getTimezone);

  if (!selectedDisplayPoint || !selectedTrack || !points) {
    return null;
  }

  const setPoint = (i: number) => dispatch(setSelectedDisplayPoint(points[i]));
  const selectedPointIndex = points.findIndex(p => p.id === selectedDisplayPoint.id);
  const isFirstPointInTrack = selectedPointIndex === 0;
  const isLastPointInTrack = selectedPointIndex === points.length - 1;

  const { aircraft } = selectedTrack;
  const localMoment = fromTimestamp(selectedDisplayPoint.timestamp);
  const formatFn = dateFormatter(dateFormat);
  const [localTime, utcTime] = formatStandardDateStrings(formatFn, localMoment, timezone);
  const singlePointTrack = isFirstPointInTrack && isLastPointInTrack;

  return (
    <Fragment>
      <RowInfo name={POINT_INFO_PANEL.header} value={aircraft.registration} />
      <RowInfo name={POINT_INFO_PANEL.time} value={localTime} />
      <RowInfo value={utcTime} />
      <PanelBody selectedDisplayPoint={selectedDisplayPoint} />
      {selectedTrack.isActive && <EtaButtonContainer />}
      {!singlePointTrack && (
        <PanelSlider
          points={points}
          selectedDisplayPoint={selectedDisplayPoint}
          setPoint={setPoint}
        />
      )}
    </Fragment>
  );
};

export default EventsPointInfoPanel;
