import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Table, Spin } from 'antd';
import EditSettingsDrawer from './EditSettingsDrawer';
import { getRowClassName } from '../../common';

export const aircraftSorter = (a, b) => {
  // make registration and serialNumber strings a consistant case
  const registrationA = a.registration.toUpperCase();
  const registrationB = b.registration.toUpperCase();
  const serialNumberA = a.serialNumber.toUpperCase();
  const serialNumberB = b.serialNumber.toUpperCase();

  let order = registrationA.localeCompare(registrationB);

  const NO_AIRCRAFT_ASSIGNED = 'NO AIRCRAFT ASSIGNED';
  let aNotAssigned = registrationA.includes(NO_AIRCRAFT_ASSIGNED);
  let bNotAssigned = registrationB.includes(NO_AIRCRAFT_ASSIGNED);

  if (!aNotAssigned && bNotAssigned) {
    // A is assigned and B is not assigned, A should come before B ie. return -1
    return -1;
  }
  if (aNotAssigned && bNotAssigned) {
    // A is not assigned and B is not assigned, check against the serial number to determine the order
    return serialNumberA.localeCompare(serialNumberB);
  }
  if (aNotAssigned && !bNotAssigned) {
    return 1;
  }
  // if none of the above conditions are met then A and B must both be assigned and a localeCompare can be done
  return order;
};

export const SpiderDeviceTable = ({ isLoading, spidersAircraftsData, refreshDeviceTable }) => {
  const [editSettingsDrawerVisibility, setEditSettingsDrawerVisibility] = useState(false);
  const [selectedRecordSettings, setSelectedRecordSettings] = useState('');

  const openEditSettingsDrawer = useCallback(spiderSettings => e => {
    e.stopPropagation();
    setEditSettingsDrawerVisibility(true);
    setSelectedRecordSettings(spiderSettings);
  });

  const onEditSettingsDrawerClose = () => {
    setEditSettingsDrawerVisibility(false);
  };

  const columns = [
    {
      title: 'AIRCRAFT',
      dataIndex: 'registration',
      key: 'aircraft',
      sorter: aircraftSorter
    },
    {
      title: 'TYPE',
      dataIndex: 'type'
    },
    {
      title: 'MAKE',
      dataIndex: 'make'
    },
    {
      title: 'MODEL',
      dataIndex: 'model'
    },
    {
      title: 'ICON',
      dataIndex: 'iconColour'
    },
    {
      title: 'SPIDER',
      dataIndex: 'spiderType'
    },
    {
      title: 'SERIAL NUMBER',
      dataIndex: 'serialNumber',
      sorter: (a, b) => {
        const serialNumberA = a.serialNumber.toUpperCase();
        const serialNumberB = b.serialNumber.toUpperCase();

        return serialNumberA.localeCompare(serialNumberB);
      }
    }
  ];

  return (
    <React.Fragment>
      <Table
        bordered
        dataSource={spidersAircraftsData}
        columns={columns}
        loading={isLoading && <Spin />}
        rowClassName={getRowClassName}
        onRow={record => ({
          onClick: openEditSettingsDrawer(record)
        })}
      />
      {editSettingsDrawerVisibility && (
        <EditSettingsDrawer
          visibility={editSettingsDrawerVisibility}
          onClose={onEditSettingsDrawerClose}
          selectedRecordSettings={selectedRecordSettings}
          spidersAircraftsData={spidersAircraftsData}
          refreshDeviceTable={refreshDeviceTable}
        />
      )}
    </React.Fragment>
  );
};

SpiderDeviceTable.propTypes = {
  isLoading: PropTypes.bool,
  spidersAircraftsData: PropTypes.array,
  refreshDeviceTable: PropTypes.func
};

SpiderDeviceTable.displayName = 'SpiderDeviceTable';

export default SpiderDeviceTable;
