/* eslint-disable @typescript-eslint/camelcase */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Drawer, Tabs, Modal } from 'antd';
import SpiderSettingsForm from './SpiderSettingsForm';
import AircraftForm from './AircraftForm';
import SpiderForm from './SpiderForm';
import { getInstance } from '../../../common/api/spidertracks-sdk';
import { useParams } from 'react-router';
import {
  aircraftAddFormSuccess,
  aircraftEditFormSuccess,
  unableToSaveError,
  cannotChangeSpiderError,
  duplicateRegistrationError
} from '../../../helpers/globalNotifications';
import { MoveSpiderModalContent, ReplaceSpiderModalContent } from './SpiderModalContent';
import ExclamationCircleFilled from '@ant-design/icons/ExclamationCircleFilled';
import {
  registerThenAssignSpiderFlow,
  assignAircraftToSpiderFlow,
  registerSpiderFlow
} from './editAircraftSpiderFlows';

const { TabPane } = Tabs;

export const showConfirm = ({ content, okText, onOk, title = 'Change Spider' }) => {
  Modal.confirm({
    title,
    icon: <ExclamationCircleFilled />,
    content,
    okText,
    onOk
  });
};

export const EditSettingsDrawer = ({
  visibility,
  onClose,
  refreshDeviceTable,
  selectedRecordSettings,
  spidersAircraftsData
}) => {
  const SpidertracksSDK = getInstance();

  const initialSelectedAircraftSpider = {
    ...selectedRecordSettings,
    iconColour: selectedRecordSettings.iconColourName
  };

  if (selectedRecordSettings.type !== '') {
    if (selectedRecordSettings.type === 'Fixed wing') {
      initialSelectedAircraftSpider.type = 'FIXED_WING';
    } else {
      initialSelectedAircraftSpider.type = 'ROTARY_WING';
    }
  }

  const [currentAircraftSpider, setCurrentAircraftSpider] = useState(initialSelectedAircraftSpider);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);

  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(true);
  const [submitButtonValue, setSubmitButtonValue] = useState('Save');

  const [serialNumber, setSerialNumber] = useState(selectedRecordSettings.serialNumber);

  const initialIsReportingDisabledState =
    selectedRecordSettings.serialNumber === 'No Device assigned' ? true : false;

  const [isReportingDisabled, setIsReportingDisabled] = useState(initialIsReportingDisabledState);

  const editOrAddAircraft = async aircraft => {
    let aircraftToEdit;
    try {
      aircraftToEdit = { ...aircraft };
      if (currentAircraftSpider.aircraftId) {
        // aircraft already exists
        aircraftToEdit.aircraftId = currentAircraftSpider.aircraftId;
        await SpidertracksSDK.getAircraftService().editAircraft(aircraftToEdit);
        aircraftEditFormSuccess();
        setCurrentAircraftSpider({
          ...currentAircraftSpider,
          registration: aircraftToEdit.registration
        });
        setIsSubmitButtonDisabled(true);
      } else {
        // aircraft doesn't currently exist
        const { aircraftId } = await SpidertracksSDK.getAircraftService().addAircraft(aircraft);
        await SpidertracksSDK.getSpiderSettingsService().assignAircraftToSpider({
          serialNumber: currentAircraftSpider.serialNumber,
          aircraftId
        });
        aircraftAddFormSuccess();
        setCurrentAircraftSpider({
          ...currentAircraftSpider,
          aircraftId,
          registration: aircraftToEdit.registration
        });
      }
      setIsSubmitButtonDisabled(true);
      refreshDeviceTable();
    } catch (e) {
      if (e.response && e.response.status === 409) {
        duplicateRegistrationError(aircraftToEdit.registration);
      } else {
        unableToSaveError();
      }
      console.error(e);
    }
  };

  const { organisationId } = useParams();
  const [savedSerialNumber, setSavedSerialNumber] = useState(currentAircraftSpider.serialNumber);

  const editOrSaveSpider = async params => {
    const newSpiderCreated = !spidersAircraftsData.some(
      spiderAircraftRecord => spiderAircraftRecord.serialNumber === params.serialNumber
    );
    let { aircraftId, serialNumber, registration } = currentAircraftSpider;
    //Editing a record with an aircraft
    if (aircraftId) {
      // if a new spider was passed create it (filter existing spider data we have in the table to know if the spider is new or not)
      if (newSpiderCreated) {
        //if the aircraft has already a serial assigned to it
        if (serialNumber !== 'No Device assigned') {
          showConfirm({
            title: 'Replace Spider',
            content: ReplaceSpiderModalContent(serialNumber, registration),
            okText: 'Confirm',
            onOk: async () =>
              await registerThenAssignSpiderFlow(
                params.serialNumber,
                currentAircraftSpider,
                setCurrentAircraftSpider,
                setSavedSerialNumber,
                refreshDeviceTable,
                organisationId
              )
          });
        } else {
          // Aircraft has no serial number assigned
          await registerThenAssignSpiderFlow(
            params.serialNumber,
            currentAircraftSpider,
            setCurrentAircraftSpider,
            setSavedSerialNumber,
            refreshDeviceTable,
            organisationId
          );
        }
      }
      // if the user selects a serial number from the dropdown
      else {
        // if the selected spider does not have an assigned aircraft
        if (params.associatedAircraft === 'Unassigned') {
          // if the aircraft has already a serial assigned to it
          if (serialNumber !== 'No Device assigned') {
            showConfirm({
              title: 'Replace Spider',
              content: ReplaceSpiderModalContent(serialNumber, registration),
              okText: 'Confirm',
              onOk: async () =>
                await assignAircraftToSpiderFlow(
                  params.serialNumber,
                  currentAircraftSpider,
                  setCurrentAircraftSpider,
                  setSavedSerialNumber,
                  refreshDeviceTable
                )
            });
          } else {
            await assignAircraftToSpiderFlow(
              params.serialNumber,
              currentAircraftSpider,
              setCurrentAircraftSpider,
              setSavedSerialNumber,
              refreshDeviceTable
            );
          }
        } else {
          // if the selected spider already has an assigned aircraft
          showConfirm({
            content: MoveSpiderModalContent(
              params.serialNumber,
              params.associatedAircraft,
              registration
            ),
            okText: 'Confirm',
            onOk: async () =>
              await assignAircraftToSpiderFlow(
                params.serialNumber,
                currentAircraftSpider,
                setCurrentAircraftSpider,
                setSavedSerialNumber,
                refreshDeviceTable
              )
          });
        }
      }
    } else {
      // if the user added a new spider, register it without assigning it to an aircraft as that aircraft does not exist
      if (newSpiderCreated) {
        await registerSpiderFlow(
          params.serialNumber,
          setSavedSerialNumber,
          refreshDeviceTable,
          organisationId
        );
      }
      // the user has selected a spider from the dropdown
      else {
        cannotChangeSpiderError();
      }
    }
    setSerialNumber(params.serialNumber);
    if (isReportingDisabled) {
      setIsReportingDisabled(false);
    }
  };

  return (
    <Drawer
      title="Edit"
      placement="right"
      closable={true}
      visible={visibility}
      onClose={onClose}
      getContainer={false}
      style={{ position: 'absolute' }}
      width={400}
    >
      <Tabs defaultActiveKey="1" size="large">
        <TabPane tab="Aircraft" key="1">
          <AircraftForm
            onClose={onClose}
            currentAircraftSpider={currentAircraftSpider}
            save={editOrAddAircraft}
            submitButtonValue={submitButtonValue}
            setSubmitButtonValue={setSubmitButtonValue}
            isSubmitButtonDisabled={isSubmitButtonDisabled}
            setIsSubmitButtonDisabled={setIsSubmitButtonDisabled}
            handleFieldChange={() => setIsSubmitButtonDisabled(false)}
          />
        </TabPane>
        <TabPane tab="Spider" key="2">
          <SpiderForm
            editing={true}
            onClose={onClose}
            currentAircraftSpider={currentAircraftSpider}
            save={editOrSaveSpider}
            spidersAircraftsData={spidersAircraftsData}
            setSerialNumber={setSerialNumber}
            savedSerialNumber={savedSerialNumber}
            setCurrentAircraftSpider={setCurrentAircraftSpider}
            submitButtonValue="Save"
          />
        </TabPane>
        <TabPane disabled={isReportingDisabled} tab="Reporting" key="3">
          <SpiderSettingsForm
            aircraftRegistration={currentAircraftSpider.registration}
            onClose={onClose}
            editing={true}
            saveButtonDisabled={saveButtonDisabled}
            setSaveButtonDisabled={setSaveButtonDisabled}
            serialNumber={serialNumber}
          />
        </TabPane>
      </Tabs>
    </Drawer>
  );
};

EditSettingsDrawer.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  refreshDeviceTable: PropTypes.func,
  selectedRecordSettings: PropTypes.object,
  spidersAircraftsData: PropTypes.array
};

export default EditSettingsDrawer;
