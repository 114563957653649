import React, { Fragment, memo } from 'react';
import PropTypes from 'prop-types';
import { Marker, Circle } from '@react-google-maps/api';

import DefaultAircraftRegistrationLabel, {
  SOSAircraftRegistrationLabel,
  ActiveAircraftRegistrationLabel
} from './AircraftRegistrationLabel';

export const AircraftTrackPoint = ({
  position,
  trackStatus,
  pointStatus,
  radius,
  hideMapRadiusShading,
  ...markerProps
}) => {
  const RegistrationLabel =
    trackStatus === 'SOS'
      ? SOSAircraftRegistrationLabel
      : trackStatus === 'ACTIVE'
      ? ActiveAircraftRegistrationLabel
      : DefaultAircraftRegistrationLabel;

  return (
    <Fragment>
      <Marker position={position} {...markerProps}>
        {markerProps.aircraftRegistration && (
          <RegistrationLabel position={position} label={markerProps.aircraftRegistration} />
        )}
      </Marker>

      {pointStatus === 'RADIUS' && (
        <Circle
          center={position}
          radius={radius}
          options={{
            strokeColor: '#ff0000',
            strokeOpacity: 0.85,
            strokeWeight: 2,
            fillColor: '#ff0000',
            fillOpacity: hideMapRadiusShading ? 0 : 0.2
          }}
        />
      )}
    </Fragment>
  );
};

AircraftTrackPoint.propTypes = {
  position: PropTypes.object.isRequired,
  icon: PropTypes.object.isRequired,
  trackStatus: PropTypes.string,
  radius: PropTypes.number,
  hideMapRadiusShading: PropTypes.bool,
  aircraftId: PropTypes.string,
  pointStatus: PropTypes.string
};

export default memo(AircraftTrackPoint);
