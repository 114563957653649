import { vectorTile } from './vectorTile';

export const highMap = ({ getNormalizedCoorda, skyVectorView }) => {
  const vectorMapType = new window.google.maps.ImageMapType({
    getTileUrl: function tileUrl(coord, zoom) {
      const normalizedCoord = getNormalizedCoorda(coord, zoom);
      const viewUrl = vectorTile(normalizedCoord, skyVectorView, zoom);
      return viewUrl;
    },
    minZoom: 1,
    maxZoom: 9,
    tileSize: new window.google.maps.Size(256, 256),
    name: 'highMap'
  });

  window.gmap.mapTypes.set('high', vectorMapType);
  window.gmap.setMapTypeId('high');
};

export default highMap;
