import deepEqual from 'deep-equal';
import {
  startMapInitialLoad,
  finishMapInitialLoad
} from '../mapReducer/actions/map/mapInitialLoad';
import { FULL_TRACK } from '../../../components/Flying/Map/GoogleMap/constants';
import { createReducer } from '@reduxjs/toolkit';

export const initialState = {
  mapView: { googleView: 'roadmap', skyVectorView: '' },
  selectedFilter: undefined,
  weatherLayer: undefined,
  pointGroupingOption: FULL_TRACK,
  selectedMarkerPoint: undefined,
  kmlData: [],
  activeKmls: [],
  uiSettings: { clusterer: true },
  sosData: [],
  mapStatus: {
    loading: false,
    loaded: false
  },
  aircraftListVisible: true
};

export default createReducer(initialState, builder => {
  builder
    .addCase(startMapInitialLoad.type, state => {
      state.mapStatus.loaded = false;
      state.mapStatus.loading = true;
    })
    .addCase(finishMapInitialLoad.type, state => {
      state.mapStatus.loaded = true;
      state.mapStatus.loading = false;
    })
    .addCase('MAP_VIEW', (state, { view }) => {
      if (!deepEqual(state.mapView, view)) {
        state.mapView = view;
      }
    })
    .addCase('SAVED_FILTER', (state, { selectedFilter }) => {
      // Shouldn't need the deep equal here, as it is a primitave value
      state.selectedFilter = selectedFilter;
    })
    .addCase('WEATHER_LAYER', (state, { weather: selectedWeatherLayer }) => {
      // Only updating the Nested value - as the rest of the object is never used.
      state.weatherLayer = selectedWeatherLayer;
    })
    .addCase('SELECTED_MARKER_POINT', (state, { point }) => {
      if (!deepEqual(state.selectedMarkerPoint, point)) {
        state.selectedMarkerPoint = point;
      }
    })
    .addCase('CLEAR_SELECTED_MARKER_POINT', state => {
      state.selectedMarkerPoint = undefined;
    })
    .addCase('KML_DATA', (state, { kmlData }) => {
      if (!deepEqual(state.kmlData, kmlData)) {
        state.kmlData = kmlData;
      }
    })
    .addCase('ACTIVE_KMLS', (state, { activeKmls }) => {
      if (!deepEqual(state.activeKmls, activeKmls)) {
        state.activeKmls = activeKmls;
      }
    })
    .addCase('USER_SETTINGS', (state, { settings }) => {
      if (!deepEqual(state.uiSettings, settings)) {
        state.uiSettings = settings;
      }
    })
    .addCase('SOS_DATA', (state, { sos }) => {
      if (!deepEqual(state.sosData, sos)) {
        state.sosData = sos;
      }
    })
    .addCase('AIRCRAFT_LIST_VISIBILITY', (state, { payload }) => {
      state.aircraftListVisible = payload;
    })
    .addCase('GROUPING_OPTION', (state, { payload }) => {
      state.pointGroupingOption = payload;
    });
});
