import { activeMarker } from '../../GoogleMap/markers/Markers';
import {
  GMAP,
  GOOGLE,
  GMAP_CONTROLLED_ICON,
  ACTIVE_GMAP_CONTROLLED_ICON
} from '../../../../../common/constants';

import {
  fixedWingSingleMarker,
  fixedWingTwinMarker,
  rotaryWingMarker,
  defaultMarker,
  mark1,
  mark2,
  mark3,
  mark4,
  watchOnOff,
  speedup,
  speeddown,
  normalRocRod,
  sosOn,
  radius,
  rocExceeded,
  rodExceeded,
  greyRoundIcon,
  startFinishTrackIcon
} from '../../GoogleMap/markers/Markers';

const checkTrackStatus = (trackStatus, aircraftHeading) => {
  if (trackStatus === 'SPIDERWATCH') {
    return defaultMarker('blue', aircraftHeading);
  }
  if (trackStatus === 'SOS') {
    return defaultMarker('red', aircraftHeading);
  }
  return false;
};

export const polyline = polylineOptions => {
  const google = GOOGLE();
  window.mapData = {
    polylines: [],
    ...window.mapData
  };
  const line = new google.maps.Polyline({
    path: polylineOptions.path,
    geodesic: polylineOptions.geodesic || false,
    strokeColor: polylineOptions.strokeColor || 'red',
    strokeOpacity: polylineOptions.strokeOpacity || 1.0,
    strokeWeight: polylineOptions.strokeWeight || 2,
    fillColor: polylineOptions.fillColor || 'red',
    fillOpacity: polylineOptions.fillOpacity || 0.35
  });

  window.mapData.polylines.push(line);
  return line;
};

// For now assign a number to start/finish of track from fe.
export const StartFinishIconNumber = 201;

const FIRE_ATU_EVENTS_BUTTON_MODE = 988;

export const getIconSvg = (markerTemplate, buttonMode) => {
  switch (buttonMode) {
    case 61:
      markerTemplate = mark1();
      break;
    case 62:
      markerTemplate = mark2();
      break;
    case 63:
      markerTemplate = mark3();
      break;
    case 64:
      markerTemplate = mark4();
      break;
    case 50:
    case 51:
    case 52:
    case 53:
    case 57:
      markerTemplate = watchOnOff();
      break;
    case 58:
      markerTemplate = speedup();
      break;
    case 59:
      markerTemplate = speeddown();
      break;
    case 81:
    case 83:
      markerTemplate = normalRocRod();
      break;
    case 80:
      markerTemplate = rocExceeded();
      break;
    case 82:
      markerTemplate = rodExceeded();
      break;
    case 111:
      markerTemplate = sosOn();
      break;
    case 68:
      markerTemplate = radius();
      break;

    case FIRE_ATU_EVENTS_BUTTON_MODE:
      markerTemplate = greyRoundIcon();
      break;
    case 200:
      markerTemplate = greyRoundIcon();
      break;
    case StartFinishIconNumber:
      markerTemplate = startFinishTrackIcon();
      break;
    default:
      break;
  }

  return markerTemplate;
};

export const getMarkerType = (lastPoint, aircraft) => {
  let markerTemplate;

  const { aircraftIcon, aircraftIconColour } = aircraft;
  const { buttonMode, heading: aircraftHeading, trackStatus } = lastPoint;

  switch (aircraftIcon) {
    case 'FIXED_WING_SINGLE':
      markerTemplate =
        checkTrackStatus(trackStatus, aircraftHeading) ||
        fixedWingSingleMarker(aircraftIconColour, aircraftHeading);
      break;
    case 'FIXED_WING_TWIN':
      markerTemplate =
        checkTrackStatus(trackStatus, aircraftHeading) ||
        fixedWingTwinMarker(aircraftIconColour, aircraftHeading);
      break;
    case 'ROTARY_WING':
      markerTemplate =
        checkTrackStatus(trackStatus, aircraftHeading) ||
        rotaryWingMarker(aircraftIconColour, aircraftHeading);
      break;
    default:
      markerTemplate =
        checkTrackStatus(trackStatus, aircraftHeading) ||
        defaultMarker(aircraftIconColour || 'default', aircraftHeading);
  }

  markerTemplate = getIconSvg(markerTemplate, buttonMode);

  return markerTemplate;
};

const getEncodedIconUrl = iconSvg =>
  `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(iconSvg)}`;

export const getIconUrlFromButtonMode = buttonMode =>
  getEncodedIconUrl(getIconSvg(greyRoundIcon(), buttonMode));

export const getMarkerIcon = (lastPoint, aircraft) => {
  const markerTemplate = getMarkerType(lastPoint, aircraft);
  const urlEncoded = getEncodedIconUrl(markerTemplate);

  return {
    url: urlEncoded,
    scaledSize: new window.google.maps.Size(
      GMAP_CONTROLLED_ICON.height,
      GMAP_CONTROLLED_ICON.width
    ),
    anchor: new window.google.maps.Point(
      GMAP_CONTROLLED_ICON.positionX,
      GMAP_CONTROLLED_ICON.positionY
    ),
    labelOrigin: new window.google.maps.Point(
      GMAP_CONTROLLED_ICON.width / 2, // anchor x coord to the center of the icon
      GMAP_CONTROLLED_ICON.height + 8 // anchor y coord to past the bototm of the icon
    )
  };
};

export const createMarker = options => {
  const google = GOOGLE();
  const gmap = GMAP();
  const { latitude, longitude, icon, isDraggable } = options;
  window.mapData = {
    markers: [],
    ...window.mapData
  };
  const marker = new google.maps.Marker({
    position: new google.maps.LatLng(latitude, longitude),
    map: gmap,
    icon: icon || getMarkerIcon(options),
    draggable: isDraggable,
    clickable: true
  });
  window.mapData.markers.push(marker);

  return new Promise(resolve => resolve(marker));
};

export const activeMarkerIcon = () => {
  const google = GOOGLE();
  const urlEncoded = `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(activeMarker())}`;
  const marker = {
    url: urlEncoded,
    scaledSize: new google.maps.Size(
      ACTIVE_GMAP_CONTROLLED_ICON.height,
      ACTIVE_GMAP_CONTROLLED_ICON.width
    ),
    anchor: new google.maps.Point(
      ACTIVE_GMAP_CONTROLLED_ICON.positionX,
      ACTIVE_GMAP_CONTROLLED_ICON.positionY
    )
  };
  return marker;
};
