import React, { useState, Fragment } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Divider } from 'antd';
import { GetApp } from '@material-ui/icons';
import { boldFont, lightFont } from '../common/styles';
import { ViewFlights } from './ViewFlights';
import { DeleteTracks } from './DeleteTracks';
import { useFeatureFlag } from '../../hooks';
import { getUserOrgData } from '../../redux/selectors/userData';
import showDeleteTracksModal from './DeleteTracksModal';
import styled from 'styled-components';
import { DownloadTracksModal } from './DownloadTracksModal';
import { notification } from 'antd';

const TableHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  min-height: 26px;
  margin-bottom: 2px;
`;
const TableHeaderInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TableHeaderActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const DownloadTracksModalContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  color: #1890ff;
`;

export const HistoryTableHeader = ({
  selectedTrackCount,
  totalDuration,
  totalDistance,
  distanceUnit,
  downloadCSV,
  downloadCSVSummary,
  downloadKML,
  selectedRowKeys,
  history,
  deleteTracks,
  selectedTracksInfo,
  downloadLimit
}) => {
  const orgs = useSelector(getUserOrgData) || [];
  const userOrgIds = orgs.reduce((acc, o) => acc.concat(o.org.id), []);
  const hasAccessToViewFlightsFeature = useFeatureFlag('flight-report-multi-view', userOrgIds);
  const hasAccessToDeleteTracksFeature = useFeatureFlag('history-page-delete-tracks', userOrgIds);
  const [downloadModalVisible, setDownloadModalVisible] = useState(false);

  const onClick = () => {
    if (selectedRowKeys.length === 1) {
      history.push(`history/${selectedRowKeys[0]}/flight-report`);
    } else {
      const encodedTrackIds = selectedRowKeys.join(encodeURIComponent(','));
      history.push(`history/flight-report-multiview?trackIds=${encodedTrackIds}`);
    }
  };

  const showDeleteIcon =
    hasAccessToDeleteTracksFeature &&
    selectedTrackCount > 0 &&
    selectedTracksInfo.every(
      ({ orgUserRole }) => orgUserRole && (orgUserRole === 'OWNER' || orgUserRole === 'ADMIN')
    );

  const showDeleteConfirm = () => {
    showDeleteTracksModal(deleteTracks);
  };

  return (
    <Fragment>
      <DownloadTracksModal
        visible={downloadModalVisible}
        selectedTrackCount={selectedTrackCount}
        requestClose={() => setDownloadModalVisible(false)}
        downloadCSVDetailed={downloadCSV}
        downloadCSVSummary={downloadCSVSummary}
        downloadKML={downloadKML}
        downloadLimit={downloadLimit}
      />
      <TableHeaderContainer>
        <TableHeaderInfoContainer>
          {!!selectedTrackCount && <div style={boldFont}>{selectedTrackCount} Selected</div>}
          {!!selectedTrackCount && <Divider type="vertical" style={{ background: '#727272' }} />}
          {!!selectedTrackCount && (
            <div>
              <span style={boldFont}>
                Total Distance:
                <span style={lightFont}>{` ${totalDistance} ${distanceUnit}`}</span>
                <Divider type="vertical" style={{ background: '#727272' }} />
                Total Time: <span style={lightFont}>{` ${totalDuration}`}</span>
              </span>
            </div>
          )}
        </TableHeaderInfoContainer>
        <TableHeaderActionsContainer>
          {hasAccessToViewFlightsFeature && selectedTrackCount <= 5 && selectedTrackCount > 0 && (
            <ViewFlights handleClick={onClick} rowLength={selectedTrackCount} trigger={['click']} />
          )}
          {!!selectedTrackCount && (
            <DownloadTracksModalContainer
              className="download-row-items"
              onClick={() => {
                if (selectedTrackCount > downloadLimit) {
                  notification.error({
                    message: 'Error',
                    description: `You have selected a large number of flights. Please select up to ${downloadLimit} flights`,
                    duration: 10
                  });
                } else {
                  setDownloadModalVisible(true);
                }
              }}
            >
              <GetApp style={{ fontSize: '14px' }} />
              <span>Download</span>
            </DownloadTracksModalContainer>
          )}
          {showDeleteIcon && (
            <>
              <Divider type="vertical" style={{ background: '#727272' }} />
              <DeleteTracks
                handleClick={showDeleteConfirm}
                rowLength={selectedTrackCount}
                trigger={['click']}
              />
            </>
          )}
        </TableHeaderActionsContainer>
      </TableHeaderContainer>
    </Fragment>
  );
};

HistoryTableHeader.propTypes = {
  selectedTrackCount: PropTypes.number,
  downloadTracks: PropTypes.func,
  deleteTracks: PropTypes.func,
  clearSelectedRows: PropTypes.func,
  totalDuration: PropTypes.string,
  totalDistance: PropTypes.number,
  distanceUnit: PropTypes.string,
  downloadCSV: PropTypes.func,
  downloadCSVSummary: PropTypes.func,
  downloadKML: PropTypes.func,
  trackIds: PropTypes.string,
  selectedRowKeys: PropTypes.array,
  history: PropTypes.object,
  selectedTracksInfo: PropTypes.array,
  downloadLimit: PropTypes.number
};

HistoryTableHeader.displayName = 'HistoryTableHeader';

export default HistoryTableHeader;
