/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect, useMemo, ReactNode } from 'react';
import {
  formatStandardDateStrings,
  fromTimestamp,
  dateFormatter,
  getDuration
} from '../helpers/formatTime';
import { convertMetresTo } from '../components/Flying/Map/utils/helper';
import { useTrackHistory, useFlightStatus } from './';
import { getAircraftSosStatus, getAircraftRegistrationLabel } from '../util';
import { HistoryTableTrack } from '../types/Track';

interface HistoryTableDataSource {
  id: string;
  key: string;
  orgId: string;
  aircraft: ReactNode;
  aircraftId: string;
  aircraftType: string | null;
  startTime: string;
  distance: string;
  rawDistance: number;
  sosStatus: string;
  flightReplay?: ReactNode;
  actions?: string;
  formattedTrackDuration: string;
  unformattedTrackDuration: number;
  replayStatus: string;
  flightId: string;
}

export const useHistoryTableData = (
  filters: URLSearchParams,
  props: {
    dateFormat: string;
    timezone: string;
    distanceUnit: string;
  },
  immediate: boolean = true
) => {
  const [getTrackHistory, tracks, meta, loadingTrackHistory] = useTrackHistory(filters, immediate);
  const [tableData, setTableData] = useState<HistoryTableDataSource[]>(tracks);
  const [tracksWithStatus] = useFlightStatus(tracks);
  useMemo(() => {
    setTableData(
      tracks.map((track: HistoryTableTrack) => {
        const localMoment = fromTimestamp(track.departedTime);
        const formatFn = dateFormatter(props.dateFormat);
        const [localTime] = formatStandardDateStrings(formatFn, localMoment, props.timezone);
        const [convertedDistance, unit] = convertMetresTo(track.totalDistance, props.distanceUnit);
        const formattedTrackDuration = getDuration(track.departedTime, track.endTime).formatted;
        const unformattedTrackDuration = getDuration(
          track.departedTime,
          track.endTime
        ).unformatted.asMilliseconds();
        const aircraftId = !!track.aircraft ? track.aircraft.id : 'Registration unavailable';
        const aircraftType = !!track.aircraft ? track.aircraft.type : null;
        const distance = `${convertedDistance} ${unit}`;
        const registration = getAircraftRegistrationLabel(track);
        const sosStatus = getAircraftSosStatus(track);

        return {
          id: String(track.id),
          key: track.trackId,
          orgId: track.orgId,
          aircraft: registration,
          aircraftId,
          aircraftType,
          startTime: localTime,
          distance: distance,
          rawDistance: track.totalDistance,
          sosStatus,
          formattedTrackDuration,
          unformattedTrackDuration,
          replayStatus: 'NULL',
          flightId: String(track.flightId)
        };
      }, [])
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meta, tracks]);

  useEffect(() => {
    if (tableData.length && tracksWithStatus) {
      setTableData(
        tableData.map(row => ({ ...row, replayStatus: tracksWithStatus[row.id] || 'NULL' }))
      );
    }
  }, [tracksWithStatus]);
  return [getTrackHistory, tableData, meta, loadingTrackHistory];
};
