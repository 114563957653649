import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { PageHeader, Button } from 'antd';
import { useParams } from 'react-router';
import { getInstance } from 'common/api/spidertracks-sdk';
import SpiderDeviceTable from './SpiderDeviceTable';
import AircraftSpiderWizard from './AircraftSpiderWizard';
import { aircraftMarkerColors } from '../../Flying/Map/GoogleMap/markers/markerColorCodes';
import { getUserData } from '../../../redux/selectors/userData';
import useFeatureFlag from '../../../hooks/useFeatureFlag';

export const AircraftColour = ({ colour = 'default' }) => (
  <div
    style={{
      height: '30px',
      width: '30px',
      border: '1px solid',
      borderRadius: '5px',
      backgroundColor: `${aircraftMarkerColors[colour.toString().toLowerCase()]}`
    }}
  ></div>
);

AircraftColour.propTypes = {
  colour: PropTypes.string
};

/**
 * This function takes the spiders and aircrafts returned from the API and combine them in a single array to be passed to the table
 * @param {*} spiders
 * @param {*} aircrafts
 */
export const combineSpidersAndAircrafts = (spiders, aircrafts) => {
  // create an object formed of spiders with their associated aircrafts, or just with spider when no aircraft is assigned
  const spidersUpdatedWithAircrafts = spiders.map(spider => {
    let spiderAircraft = {
      key: spider.serialNumber,
      serialNumber: `${spider.serialNumber}`.toUpperCase(),
      spiderType: spider.spiderType,
      imeiNumber: spider.imeiNumber
    };

    if (!spider.vehicleId) {
      //form object for spiders NOT assigned to an aircraft
      spiderAircraft.aircraftId = undefined;
      spiderAircraft.registration = 'No Aircraft assigned';
      spiderAircraft.type = '';
      spiderAircraft.make = '';
      spiderAircraft.model = '';
      spiderAircraft.icon = '';
      spiderAircraft.iconColourName = '';
      spiderAircraft.iconColour = '';
    } else {
      //if the spider is assigned to an aircraft, find the aircraft object
      const associatedAircraft = aircrafts.find(aircraft => aircraft.id === spider.vehicleId);

      //Form the spider aircraft object
      spiderAircraft.aircraftId = associatedAircraft.id;
      spiderAircraft.registration = associatedAircraft.registration;
      if (!associatedAircraft.type || associatedAircraft.type === 'UNDEFINED') {
        spiderAircraft.type = '';
      } else {
        spiderAircraft.type =
          associatedAircraft.type === 'FIXED_WING' ? 'Fixed wing' : 'Rotary wing';
      }

      spiderAircraft.make = associatedAircraft.make ? associatedAircraft.make : '';
      spiderAircraft.model = associatedAircraft.model ? associatedAircraft.model : '';
      spiderAircraft.icon = associatedAircraft.aircraftIcon ? associatedAircraft.aircraftIcon : '';
      spiderAircraft.iconColourName = associatedAircraft.aircraftIconColor
        ? associatedAircraft.aircraftIconColor
        : '';
      spiderAircraft.iconColour = <AircraftColour colour={associatedAircraft.aircraftIconColor} />;
    }
    return spiderAircraft;
  });

  // Create an object formed of all aircrafts without assigned spiders
  const aircraftsWithoutSpiders = aircrafts
    .filter(aircraft => aircraft.spider === null)
    .map(aircraft => {
      let formattedAircraft = {
        key: aircraft.id,
        aircraftId: aircraft.id,
        registration: aircraft.registration,
        make: aircraft.make ? aircraft.make : '',
        model: aircraft.model ? aircraft.model : '',
        icon: aircraft.aircraftIcon ? aircraft.aircraftIcon : '',
        iconColourName: aircraft.aircraftIconColor ? aircraft.aircraftIconColor : '',
        iconColour: <AircraftColour colour={aircraft.aircraftIconColor} />,
        spiderType: '',
        serialNumber: 'No Device assigned'
      };

      if (!aircraft.type || aircraft.type === 'UNDEFINED') {
        formattedAircraft.type = '';
      } else {
        formattedAircraft.type = aircraft.type === 'FIXED_WING' ? 'Fixed wing' : 'Rotary wing';
      }

      return formattedAircraft;
    });

  //concatenate both objects (first object formed of spiders with and without aircrafts, second object formed of aircrafts without spiders assigned)
  return spidersUpdatedWithAircrafts.concat(aircraftsWithoutSpiders);
};

export const SpiderSettings = () => {
  const { organisationId } = useParams();
  const SpidertracksSDK = getInstance();
  const [drawerVisibility, setDrawerVisibility] = useState(false);
  const [spidersAircraftsData, setSpidersAircraftsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [serialNumbers, setSerialNumbers] = useState([]);

  const showDrawer = () => {
    setDrawerVisibility(true);
  };

  const onDrawerClose = () => {
    setDrawerVisibility(false);
  };

  const isLegacySpidersFeatureEnabled = useFeatureFlag('legacy-devices-aircraft-spiders-table', [
    organisationId
  ]);

  const fetchSpiderData = async () => {
    setIsLoading(true);
    try {
      await Promise.all([
        SpidertracksSDK.getSpiderSettingsService().getSpiders(organisationId),
        SpidertracksSDK.getOrgAircraft(organisationId)
      ])
        .then(result => {
          const [spiders, aircrafts] = result;
          let spidersAircrafts = [];

          if (isLegacySpidersFeatureEnabled) {
            spidersAircrafts = combineSpidersAndAircrafts(spiders, aircrafts);
          } else {
            const filteredSpiders = spiders.filter(spider => spider.spiderType === 'SX');
            spidersAircrafts = combineSpidersAndAircrafts(filteredSpiders, aircrafts);
          }

          setSpidersAircraftsData(spidersAircrafts);
          setIsLoading(false);
          setSerialNumbers(spiders.map(spider => spider.serialNumber));
        })
        .catch(error => {
          setIsLoading(false);
          console.error(error);
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchSpiderData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisationId]);

  return (
    <React.Fragment>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <PageHeader
          className="site-page-header"
          style={{ paddingLeft: '0px' }}
          title="Aircraft & Spiders"
          subTitle="Click the row to edit"
        />
        <Button
          size="large"
          onClick={showDrawer}
          type="primary"
          ghost
          style={{ padding: '0 50px' }}
        >
          Add
        </Button>
      </div>
      <SpiderDeviceTable
        refreshDeviceTable={fetchSpiderData}
        spidersAircraftsData={spidersAircraftsData}
        serialNumbers={serialNumbers}
        isLoading={isLoading}
      />
      {drawerVisibility && (
        <AircraftSpiderWizard
          visibility={drawerVisibility}
          onClose={onDrawerClose}
          refreshDeviceTable={fetchSpiderData}
          spidersAircraftsData={spidersAircraftsData}
        />
      )}
    </React.Fragment>
  );
};

SpiderSettings.displayName = 'SpiderSettings';

const mapStateToProps = state => ({
  userData: getUserData(state)
});

SpiderSettings.propTypes = {
  userData: PropTypes.object
};

export default connect(mapStateToProps)(SpiderSettings);
