import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { SOS, SPIDERWATCH, ACTIVE, RED, GREEN, BLUE } from '../../../../constants';

const StatusStyled = styled.div`
  width: 15px;
  height: 15px;
  background: ${props => props.color};
  position: absolute;
  z-index: 9;
  top: 60%;
  left: 8px;
  border-style: solid;
  border-width: 2px;
  border-stroke: #000000;
  border-radius: 50%;
`;

export const Status = ({ status }) => {
  let color;

  switch (status) {
    case SOS:
      color = RED;
      break;
    case SPIDERWATCH:
      color = BLUE;
      break;
    case ACTIVE:
      color = GREEN;
      break;
    default:
      color = 'grey';
  }

  return <StatusStyled className="aircraft-status" color={color} />;
};

export const AircraftContainer = styled.div`
  position: relative;
  padding-left: 30px;

  .ant-checkbox-inner {
    border: 1px solid #bfbfbf;
  }
`;

export const AircraftWrapper = styled.div`
  display: block;
  padding-top: 5px;
  vertical-align: bottom;
`;

export const AircraftNameWrapper = styled.div`
  display: block;
`;

export const TimeWrapper = styled.div`
  display: inline-block;
  font-size: 12px;
  float: left;
`;

export const DurationWrapper = styled.div`
  display: inline-block;
  text-align: right;
  font-size: 12px;
  float: right;
  padding-right: 5px;
`;

Status.propTypes = {
  status: PropTypes.string
};
