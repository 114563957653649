import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { getInstance } from '../../common/api/spidertracks-sdk';
import { notification } from 'antd';

const regex = new RegExp(/^\/history\/\w+\/\d+\/flight-report/);

export const checkPermissions = async (serialNumber, bootcount) => {
  try {
    await getInstance().getTrackHistory({
      serialNumber,
      bootcount
    });
    return true;
  } catch (e) {
    console.error(e);
    notification.error({
      message: 'Error',
      description:
        'Sorry, you do not have visibility enabled for this aircraft, please contact your administrator.',
      duration: 10,
      style: { backgroundColor: '#FFFFF' }
    });
    return false;
  }
};

export const callback = checkPermissions => push => async ({ origin, data }) => {
  if (origin.includes('sisense.com') && typeof data === 'string') {
    try {
      const { action, path } = JSON.parse(data);
      if (action === 'open_link' && regex.test(path)) {
        const pathArray = path.split('/');
        if (pathArray.includes('flight-report') && pathArray.includes('history')) {
          const serialNumber = pathArray[2];
          const bootcount = pathArray[3];
          const result = await checkPermissions(serialNumber, bootcount);
          if (result) {
            push(path);
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
  }
};

export const InsightsDashboard = () => {
  const history = useHistory();
  const sisenseMessageCallback = callback(checkPermissions)(history.push);
  useEffect(() => {
    window.addEventListener('message', sisenseMessageCallback);
    return () => {
      window.removeEventListener('message', sisenseMessageCallback);
    };
  }, [sisenseMessageCallback]);

  return (
    <iframe
      id="sisense_iframe"
      title="Sisense"
      style={{
        height: `calc(100vh - 149.88px)`,
        width: '100%',
        minWidth: '100%',
        border: 'none',
        marginBottom: '1.5rem'
      }}
    ></iframe>
  );
};

InsightsDashboard.displayName = 'InsightsDashboard';
