import { headers } from '@spidertracks/common';

export const baronWeatherFetchSignature = metaurl => {
  if (metaurl) {
    return fetch(metaurl)
      .then(response => {
        if (response) {
          return response.text();
        }
      })
      .then(time => {
        let instances = time;
        instances = instances.replace('?([', '');
        instances = instances.replace(']);', '');
        instances = JSON.parse(instances);

        return instances.time;
      });
  }
};

export function locationRequest(aircraftId) {
  return fetch(`${window.env.STL_GO_ORIGIN}/api/vehicles/${aircraftId}/position`, {
    method: 'POST',
    withCredentials: true,
    headers: headers()
  })
    .then(data => {
      if (data) {
        return Promise.resolve(data);
      }
      return Promise.reject();
    })
    .catch(error => {
      console.error(error);
      return null;
    });
}
