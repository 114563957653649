import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Checkbox } from 'antd';
import {
  AircraftContainer,
  AircraftWrapper,
  DurationWrapper,
  AircraftNameWrapper,
  TimeWrapper,
  Status
} from './styles/js/styled';
import {
  dateFormatter,
  formatStandardDateStrings,
  fromTimestamp
} from '../../../../../helpers/formatTime';
import { getDateFormat, getTimezone } from '../../../../../redux/selectors/userData';

const AircraftRow = ({ aircraft, checked = false }) => {
  const dateFormat = useSelector(getDateFormat);
  const timezone = useSelector(getTimezone);
  const localMoment = fromTimestamp(aircraft.departedTime);

  const formatFn = dateFormatter(dateFormat);
  const [localTime] = formatStandardDateStrings(formatFn, localMoment, timezone);
  return (
    <AircraftContainer>
      <Status status={aircraft.trackStatus} />
      <AircraftNameWrapper>{aircraft.registration}</AircraftNameWrapper>
      <AircraftWrapper>
        <TimeWrapper>{localTime}</TimeWrapper>
        <Checkbox style={{ top: 0, position: 'absolute', right: '5px' }} checked={checked} />
        <DurationWrapper>{aircraft.flightDuration}</DurationWrapper>
      </AircraftWrapper>
    </AircraftContainer>
  );
};

AircraftRow.defaultProps = {
  data: ''
};

AircraftRow.propTypes = {
  aircraft: PropTypes.object,
  checked: PropTypes.bool
};

export default AircraftRow;
