import { useState, useCallback, useEffect } from 'react';
import { getInstance } from '../common/api/spidertracks-sdk';
import { flightReplayStatusError } from '../helpers';
import { Track } from '../types/Track';
import { FlightStatus } from '../constants';

interface Status {
  [id: string]: FlightStatus;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useFlightStatus = (tracks: Track[]): [Status | null, boolean, null | Error] => {
  const SpidertracksSDK = getInstance();

  const [flightStatus, setFlightStatus] = useState<Status | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const getTrackHistoryWithStatus = useCallback(() => {
    const init = async () => {
      try {
        setLoading(true);
        const status = await SpidertracksSDK.getFlightDataService().getFlightStatus(
          tracks.map((t: Track) => t.id)
        );

        const trackStatus = status.items.reduce((statusObj, t) => {
          return {
            ...statusObj,
            [t.id]: t.status
          };
        }, {});

        setFlightStatus(trackStatus);
        setLoading(false);
      } catch (error) {
        flightReplayStatusError();
        setLoading(false);
        setError(() => error);
        console.log(error);
      }
    };
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tracks]);

  useEffect(() => {
    if (tracks.length) {
      getTrackHistoryWithStatus();
    }
  }, [tracks, getTrackHistoryWithStatus]);

  return [flightStatus, loading, error];
};
