import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Drawer, Form } from 'antd';
import { ConfirmationButtons } from '../../common/form';
import { EventTypeDropdown } from './EventTypeDropdown';
import { AircraftDropdown } from './AircraftDropdown';
import { ParameterThresholdFields } from './ParameterThresholdFields';
import { useNonDeveloperFlag } from '../../../hooks/useFeatureFlag';
import { AircraftSelector } from './AircraftSelector';
import { buildAircraftOptions } from './utils';

export const formValidation = async (err, fieldValues, { setIsSaving, closeDrawer }, save) => {
  try {
    if (!err) {
      setIsSaving(true);
      await save({
        eventClass: 'fsi',
        eventType: fieldValues.eventType.key,
        displayThresholds: fieldValues.displayThresholds,
        ...(fieldValues.aircraft === 'All Aircraft' && { allAircraft: true })
      });
      closeDrawer();
    }
  } catch (e) {
    console.log(e);
  } finally {
    setIsSaving(false);
  }
};

// NOTE: this is a branch by abstraction behind a feature flag. It will become the "new normal" once
// the flag is removed.
export const newFormValidation = async (
  err,
  fieldValues,
  { setIsSaving, closeDrawer },
  save,
  aircraftOptions
) => {
  try {
    if (!err) {
      // NOTE: `err` is purely a validation error, we don't want those in the console
      setIsSaving(true);
      const body = {
        displayThresholds: fieldValues.displayThresholds,
        eventClass: 'fsi',
        eventType: fieldValues.eventType.key
      };
      if (fieldValues.aircraft.length === aircraftOptions.length) {
        body.allAircraft = true;
      } else {
        body.aircraftIds = fieldValues.aircraft;
      }
      await save(body);
      closeDrawer();
    }
  } catch (e) {
    console.log(JSON.stringify(e, null, 2));
  } finally {
    setIsSaving(false);
  }
};

export const EventsConfigDrawer = ({
  aircraft,
  isEditForm = false,
  visibility,
  onClose,
  form,
  eventTypes,
  eventParameters,
  eventRules,
  selectedEventRule,
  save,
  organisationIds = []
}) => {
  const { validateFields, getFieldDecorator, setFieldsValue } = form;
  const [isSaving, setIsSaving] = useState(false);
  const [selectedEventType, setSelectedEventType] = useState(
    isEditForm
      ? { key: selectedEventRule.eventType, label: selectedEventRule.eventTypeDisplayName }
      : undefined
  );
  const [aircraftOptions, setAircraftOptions] = useState([]);

  const isAircraftDropdownFeatureEnabled = useNonDeveloperFlag('insights', organisationIds);

  useEffect(() => {
    if (selectedEventType) {
      form.resetFields();
      setAircraftOptions(
        buildAircraftOptions(
          aircraft,
          eventRules,
          selectedEventType.key,
          isEditForm && selectedEventRule.id
        )
      );
    }
  }, [aircraft, eventRules, isEditForm, selectedEventType, selectedEventRule]);

  if (!visibility) {
    return null;
  }

  const closeDrawer = () => {
    onClose();
    setSelectedEventType(undefined);
  };

  const saveEventRule = async e => {
    e.preventDefault();
    validateFields((err, fieldValues) => {
      isAircraftDropdownFeatureEnabled
        ? newFormValidation(err, fieldValues, { setIsSaving, closeDrawer }, save, aircraftOptions)
        : formValidation(err, fieldValues, { setIsSaving, closeDrawer }, save);
    });
  };

  const initialAircraft = isEditForm
    ? selectedEventRule.aircraftIds || aircraftOptions.map(o => o.value)
    : [];
  const parameters = isEditForm ? [{ parameters: selectedEventRule.parameters }] : eventParameters;

  return (
    <Drawer
      title={isEditForm ? 'Edit' : 'Add'}
      placement="right"
      closable={true}
      visible={visibility}
      onClose={closeDrawer}
      getContainer={false}
      style={{ position: 'absolute' }}
      width={400}
      bodyStyle={{ width: '356px', margin: '20px 0 0 32px' }}
    >
      <Form onSubmit={saveEventRule} layout="vertical" hideRequiredMark={true}>
        <EventTypeDropdown
          selectedEventType={selectedEventType}
          isEditForm={isEditForm}
          setSelectedEventType={item => setSelectedEventType(item)}
          getFieldDecorator={getFieldDecorator}
          eventTypes={eventTypes}
        />
        {selectedEventType &&
          (isAircraftDropdownFeatureEnabled ? (
            <AircraftSelector
              form={form}
              initialValue={initialAircraft}
              isEditForm={isEditForm}
              options={aircraftOptions}
            />
          ) : (
            <AircraftDropdown
              selectedAircraft={'All Aircraft'}
              getFieldDecorator={getFieldDecorator}
            />
          ))}
        {selectedEventType &&
          parameters
            .filter(parameter => isEditForm || parameter.eventType === selectedEventType.key)
            .map((p, i) => {
              return (
                <ParameterThresholdFields
                  key={`${p.eventClass}#${p.eventType}#${i}`}
                  selectedParameter={p}
                  getFieldDecorator={getFieldDecorator}
                  setFieldsValue={setFieldsValue}
                />
              );
            })}
        <ConfirmationButtons onClose={closeDrawer} isSaving={isSaving} />
      </Form>
    </Drawer>
  );
};

EventsConfigDrawer.propTypes = {
  aircraft: PropTypes.array,
  isEditForm: PropTypes.bool,
  visibility: PropTypes.bool,
  onClose: PropTypes.func,
  form: PropTypes.any,
  eventTypes: PropTypes.array,
  eventParameters: PropTypes.array,
  eventRules: PropTypes.array,
  selectedEventRule: PropTypes.object,
  save: PropTypes.func.isRequired,
  organisationIds: PropTypes.array
};

export default Form.create()(EventsConfigDrawer);
