import React from 'react';
import { Spin } from 'antd';
export const LoadingBackground = () => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%',
      opacity: 0.3
    }}
  >
    <Spin size="large" />
  </div>
);
