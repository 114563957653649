import React from 'react';
import AircraftTrackPoint from './AircraftTrackPoint';
import PropTypes from 'prop-types';
import { getLatLng } from '../../../../../../redux/selectors/aircraftData';
import { getMarkerIcon } from '../../../utils/drawing/marker';

const LatestTrackPoint = ({ point, onMarkerClick, aircraftProperties, hideMapRadiusShading }) => (
  <AircraftTrackPoint
    onClick={onMarkerClick(point)}
    position={getLatLng(point)}
    icon={getMarkerIcon(point, aircraftProperties)}
    radius={point.radius}
    aircraftRegistration={aircraftProperties.aircraftRegistration}
    trackStatus={aircraftProperties.trackStatus}
    pointStatus={point.trackStatus}
    hideMapRadiusShading={hideMapRadiusShading}
  />
);

LatestTrackPoint.propTypes = {
  point: PropTypes.object,
  onMarkerClick: PropTypes.func,
  getLatLng: PropTypes.func,
  getMarkerIcon: PropTypes.func,
  aircraftProperties: PropTypes.object,
  hideMapRadiusShading: PropTypes.bool
};

export default LatestTrackPoint;
