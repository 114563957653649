import React from 'react';
import { Tag } from 'antd';
import { KeyIndex } from '../../../types/KeyIndex';
import { DELETED } from '../../Flying/AircraftList/constants';
import './filterResults.css';

export const getTaggedLabel = (value: string, tag: string) => (
  <span key={value}>
    {`${value} `}
    <Tag closable={false} color="red">
      {tag}
    </Tag>
  </span>
);

interface FilterResultsProps {
  searchText: string;
  filters: KeyIndex[];
  filterKey: string;
}

export const FilterResults = ({
  searchText,
  filters = [{ value: '', text: '' }],
  filterKey
}: FilterResultsProps) => {
  let sortedList = filters.sort((a, b) => a.value.localeCompare(b.value));
  const results = sortedList.map((filter: KeyIndex) => {
    const value = filter[filterKey];
    const label = filter.value;
    const hasDeletedProp = !!filter.deleted;

    if (!!searchText) {
      const matchesSearch = label.toLowerCase().includes(searchText.toLowerCase());
      if (!matchesSearch) {
        return { label, value, disabled: true };
      }
    }

    const labelWithDeletedTag = {
      label: getTaggedLabel(label, DELETED),
      value
    };

    return hasDeletedProp ? labelWithDeletedTag : { label, value };
  });
  return results;
};

export default FilterResults;
