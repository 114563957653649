import React from 'react';
import PropTypes from 'prop-types';
import { Form, Row, Select } from 'antd';

export const AircraftDropdown = ({ getFieldDecorator, selectedAircraft }) => {
  return (
    <Form.Item label={'Aircraft'} name={'aircraft'} style={{ textAlign: 'left' }} className="mt-3">
      <Row>
        {getFieldDecorator('aircraft', {
          initialValue: selectedAircraft,
          rules: [
            {
              type: 'string',
              required: true
            }
          ]
        })(
          <Select disabled={true} style={{ width: '300px' }}>
            <Select.Option disabled={true} key={1} value={selectedAircraft}>
              {selectedAircraft}
            </Select.Option>
          </Select>
        )}
      </Row>
    </Form.Item>
  );
};

AircraftDropdown.propTypes = {
  eventTypes: PropTypes.array,
  selectedAircraft: PropTypes.any,
  getFieldDecorator: PropTypes.func,
  setSelectedEventType: PropTypes.func
};
