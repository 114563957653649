import React, { Fragment, memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  getLatLng,
  getIndexOfPointInTrack,
  getAircraftWithLatestTrackById,
  getSelectedAircraftById
} from '../../../../../../redux/selectors/aircraftData';
import { getHideMapRadiusShading } from '../../../../../../redux/selectors/userData';
import {
  getSelectedMarkerPoint,
  getPointGroupingOption
} from '../../../../../../redux/selectors/mapData';
import { getSelectedDisplayPoint } from '../../../../../../redux/selectors/eventData';
import LatestTrackPoint from './LatestTrackPoint';
import FullTrack from './FullTrack';
import { FULL_TRACK, NORMAL_BUTTON_MODES } from '../../constants';

import { createSelector } from 'reselect';
import { splitLast } from '../../../../../../helpers/splitLast';
import SelectedTrackPoint from './SelectedTrackPoint';

export const AircraftTrackPoints = ({
  lastPoint,
  onMarkerClick,
  hideMapRadiusShading,
  showFullTrack,
  selectedMarkerLatLng,
  selectedMarkerPoint,
  nonNormalPoints,
  aircraftProperties
}) => {
  return (
    <Fragment>
      {showFullTrack && (
        <FullTrack
          points={nonNormalPoints}
          onMarkerClick={onMarkerClick}
          aircraftProperties={aircraftProperties}
        />
      )}
      {!!selectedMarkerLatLng && (
        <SelectedTrackPoint point={selectedMarkerPoint} aircraftProperties={aircraftProperties} />
      )}
      <LatestTrackPoint
        point={lastPoint}
        onMarkerClick={onMarkerClick}
        aircraftProperties={aircraftProperties}
        hideMapRadiusShading={hideMapRadiusShading}
      />
    </Fragment>
  );
};

AircraftTrackPoints.propTypes = {
  normalPoints: PropTypes.array,
  nonNormalPoints: PropTypes.array,
  aircraftTrack: PropTypes.object,
  lastPoint: PropTypes.object,
  selectedMarkerPoint: PropTypes.object,
  onMarkerClick: PropTypes.func,
  selectedMarkerLatLng: PropTypes.object,
  aircraftProperties: PropTypes.object,
  hideMapRadiusShading: PropTypes.bool.isRequired,
  showFullTrack: PropTypes.bool.isRequired
};

AircraftTrackPoints.defaultProps = {
  isFirstPointInTrack: false,
  isLastPointInTrack: false
};

const mapStateToProps = function connectFactoryFunction() {
  const selectSplitLast = createSelector(
    points => points,
    points => splitLast(points)
  );

  const selectNormalAndNonNormal = createSelector([selectSplitLast], ([points]) =>
    points.reduce(
      (accumulator, point) => {
        if (NORMAL_BUTTON_MODES.includes(point.buttonMode)) {
          accumulator[0].push(point);
        } else {
          accumulator[1].push(point);
        }
        return accumulator;
      },
      [[], []]
    )
  );

  return (state, ownProps) => {
    const showEvents = ownProps.displayEventClasses.length;
    const selectedMarkerPoint = showEvents
      ? getSelectedDisplayPoint(state)
      : getSelectedMarkerPoint(state);
    let selectedMarkerBelongsToTrack = false;

    if (selectedMarkerPoint) {
      selectedMarkerBelongsToTrack = showEvents
        ? !!ownProps.points.find(p => p.id === selectedMarkerPoint.id)
        : getIndexOfPointInTrack(ownProps.aircraftTrack, selectedMarkerPoint) > -1;
    }

    const aircraft = showEvents
      ? getSelectedAircraftById(state, ownProps.aircraftTrack.aircraft.id)
      : getAircraftWithLatestTrackById(state, ownProps.aircraftTrack.aircraft.id);
    const lastPoint = selectSplitLast(ownProps.points)[1];
    const [normalPoints, nonNormalPoints] = selectNormalAndNonNormal(ownProps.points);
    // AircraftTrackPoints is either rendered with context or without, with context displayFullTrack = true
    const showFullTrack =
      getPointGroupingOption(state) === FULL_TRACK || ownProps.strictPointGrouping.displayFullTrack;

    return {
      normalPoints,
      nonNormalPoints,
      selectedMarkerPoint,
      lastPoint,
      showFullTrack,
      selectedMarkerLatLng: selectedMarkerBelongsToTrack
        ? getLatLng(selectedMarkerPoint)
        : undefined,
      aircraftProperties: {
        trackStatus: aircraft[0].trackStatus,
        aircraftRegistration: aircraft[0].registration,
        aircraftIcon: aircraft[0].aircraftIcon,
        aircraftIconColour: aircraft[0].aircraftIconColour
      },
      hideMapRadiusShading: getHideMapRadiusShading(state)
    };
  };
};

export default connect(mapStateToProps)(memo(AircraftTrackPoints));
