import moment from 'moment';
import { SOS, SPIDERWATCH, ACTIVE, INACTIVE } from '../constants';
import momentDurationFormatSetup from 'moment-duration-format';

momentDurationFormatSetup(moment);

export const getLastPoint = points => [...points].sort((a, b) => b.timestamp - a.timestamp)[0];

export const setIsActive = latestPoint => {
  if (!latestPoint) {
    return false;
  }
  return moment().diff(moment(latestPoint.timestamp), 'minute') <= 15;
};

export const getTrackStatus = (trackState, isActive) => {
  if (trackState === SOS) {
    return SOS;
  }
  if (trackState === SPIDERWATCH && isActive) {
    return SPIDERWATCH;
  }
  if (isActive) {
    return ACTIVE;
  }
  return INACTIVE;
};
