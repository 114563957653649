import PropTypes from 'prop-types';
import React, { Fragment, memo, useCallback } from 'react';
import { connect } from 'react-redux';
import { selectedMarkerPoint as selectedMarkerPointAction } from '../../../../../redux/reducers/mapReducer/actions/map';
import {
  getLatLng,
  getTrackById,
  getSelectedTrackById,
  getTrackPoints,
  isTrackSelected
} from '../../../../../redux/selectors/aircraftData';
import { getPointGroupingOption } from '../../../../../redux/selectors/mapData';
import {
  setSelectedTrackId,
  setSelectedTracks as setSelectedTracksAction
} from '../../../../../redux/slice/tracks';
import { setSelectedDisplayPoint } from '../../../../../redux/slice/events';
import AircraftTrackLine from './AircraftTrackLine';
import AircraftTrackPoints from './AircraftTrackPoints/AircraftTrackPoints';
import { getDisplayPoints } from '../../../../../redux/selectors/eventData';

export function AircraftTrackContainer({
  aircraftTrack,
  displayEventClasses,
  isTrackSelected,
  points,
  setSelectedMarkerPoint,
  setSelectedTrackId,
  showTrackLine,
  strictPointGrouping,
  trackId,
  setSelectedTracks
}) {
  const onMarkerClick = useCallback(
    point => () => {
      !displayEventClasses.length && setSelectedTracks(aircraftTrack); // Only do this if not on the events page
      setSelectedTrackId(trackId);
      setSelectedMarkerPoint(point);
    },
    [trackId]
  );

  return (
    <Fragment>
      {(showTrackLine || isTrackSelected) && (
        <AircraftTrackLine coordinates={points.map(getLatLng)} isTrackSelected={isTrackSelected} />
      )}
      <AircraftTrackPoints
        aircraftTrack={aircraftTrack}
        displayEventClasses={displayEventClasses}
        onMarkerClick={onMarkerClick}
        points={points}
        strictPointGrouping={strictPointGrouping}
      />
    </Fragment>
  );
}

AircraftTrackContainer.propTypes = {
  points: PropTypes.array,
  aircraftTrack: PropTypes.object,
  setSelectedTrackId: PropTypes.func,
  setSelectedMarkerPoint: PropTypes.func,
  trackId: PropTypes.string,
  isTrackSelected: PropTypes.bool,
  showTrackLine: PropTypes.bool,
  displayEventClasses: PropTypes.array,
  strictPointGrouping: PropTypes.object,
  setSelectedTracks: PropTypes.func
};

const mapStateToProps = (state, { trackId, displayEventClasses, strictPointGrouping }) => {
  const aircraftTrack = displayEventClasses.length
    ? getSelectedTrackById(state, trackId)
    : getTrackById(state, trackId);

  const points = displayEventClasses.length
    ? getDisplayPoints(state)[trackId]
    : getTrackPoints(aircraftTrack);

  return {
    aircraftTrack,
    points,
    isTrackSelected: isTrackSelected(state, aircraftTrack),
    showTrackLine:
      getPointGroupingOption(state) !== 'LAST_POINT' || strictPointGrouping?.displayFullTrack
  };
};

const mapDispatchToProps = (dispatch, { displayEventClasses }) => ({
  setSelectedTrackId: trackId => dispatch(setSelectedTrackId(trackId)),
  setSelectedTracks: point => dispatch(setSelectedTracksAction([point.trackId])),
  setSelectedMarkerPoint: point =>
    displayEventClasses.length
      ? dispatch(setSelectedDisplayPoint(point))
      : dispatch(selectedMarkerPointAction(point))
});

export default memo(connect(mapStateToProps, mapDispatchToProps)(AircraftTrackContainer));
