import React from 'react';
import PropTypes from 'prop-types';
import Loading from '../components/loading/Loading';
import './NgContainer.scss';

let locations;

class NgContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ready: false
    };
  }

  componentDidMount() {
    const that = this;
    setTimeout(() => {
      const hash = sessionStorage.getItem('hash');
      window.hash = hash && hash.length ? hash.replace(/Basic /g, '') : '';

      locations = {
        history: '/ng/index.html#/flights',
        spidertxt: '/ng/index.html#/spidertxt',
        schedule: '/ng/index.html#/schedule',
        journeylog: '/ng/index.html#/journeylog',
        personalsettings: '/ng/index.html#/general',
        settings: `${window.env.STL_GO_ORIGIN}/settings?go_token=${encodeURIComponent(window.hash)}`
      };

      that.setState({
        ready: true
      });
    }, 500);
  }

  render() {
    if (!this.state.ready) {
      return (
        <React.Fragment>
          <Loading />
          <div>&nbsp;</div>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <Loading />
        <iframe
          className="iframe-placeholder"
          src={locations[this.props.match.params.name]}
          title={this.props.match.params.name}
          width="100%"
          height="100%"
          style={{ border: 0 }}
        />
      </React.Fragment>
    );
  }
}

NgContainer.propTypes = {
  match: PropTypes.object
};

export default NgContainer;
