export const getNormalizedCoorda = (coord, zoom) => {
  const { y } = coord;
  let { x } = coord;
  const tileRange = 1 << zoom; // eslint-disable-line no-bitwise
  if (y < 0 || y >= tileRange) {
    return null;
  }
  if (x < 0 || x >= tileRange) {
    x = ((x % tileRange) + tileRange) % tileRange;
  }
  return {
    x,
    y
  };
};
