/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable no-undef */
import { connect } from 'react-redux';
import { getUserData } from '../redux/selectors/userData';

export const Pendo = ({ userData }) => {
  if (!window.pendo) {
    console.log('pendo not found');
  }
  window.pendo.initialize({
    disableGuides:
      window.env.pendo && window.env.pendo.disableGuides ? window.env.pendo.disableGuides : false,
    visitor: {
      id: userData.id,
      email: userData.email,
      full_name: userData.displayName
    },
    account: {
      id: userData.id,
      name: userData.displayName,
      creationDate: userData.createdDate
    }
  });
  return null;
};

Pendo.displayName = 'Pendo';

const mapStateToProps = state => {
  return {
    userData: getUserData(state)
  };
};

export default connect(mapStateToProps)(Pendo);
