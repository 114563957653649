import React, { Fragment, useMemo } from 'react';
import AircraftTrackPoint from './AircraftTrackPoint';
import PropTypes from 'prop-types';
import { getMarkerIcon } from '../../../utils/drawing/marker';
import { activeMarkerIcon as activeMarkerIconBuilder } from '../../../utils/drawing/marker';
import { getLatLng } from '../../../../../../redux/selectors/aircraftData';

const SelectedTrackPoint = ({ point, aircraftProperties }) => {
  const activeMarkerIcon = useMemo(() => activeMarkerIconBuilder(), [activeMarkerIconBuilder]);
  const selectedTrackPointLatLng = getLatLng(point);
  return (
    <Fragment>
      <AircraftTrackPoint icon={activeMarkerIcon} position={selectedTrackPointLatLng} />
      <AircraftTrackPoint
        position={selectedTrackPointLatLng}
        icon={getMarkerIcon(point, aircraftProperties)}
      />
    </Fragment>
  );
};

SelectedTrackPoint.propTypes = {
  point: PropTypes.object.isRequired,
  getMarkerIcon: PropTypes.func,
  aircraftProperties: PropTypes.object
};

export default SelectedTrackPoint;
