export const GMAP = () => window.gmap;
export const GOOGLE = () => window.google;

export const GMAP_CONTROLLED_ICON = {
  width: 35,
  height: 35,
  positionX: 17,
  positionY: 17
};

export const ACTIVE_GMAP_CONTROLLED_ICON = {
  width: 55,
  height: 55,
  positionX: 27,
  positionY: 28
};

export const ORGANISATION = '/organisation';
export const HISTORY = '/history';
export const INSIGHTS = '/insights';
export const INSIGHTS_SSO_LOGIN = '/sisense/sso-login';

export const THIRTY_SECONDS = 30000;

export const OLD_SETTINGS_PAGES = [
  'basicinformation',
  'publicpage',
  'kml',
  'spidertxt',
  'members',
  'visibility',
  'notifications',
  'aircraftsettings',
  'aff/api',
  'sossettings',
  'eventlogging',
  'billingandpayments',
  'invoices'
];

/**
 * SpiderTracks Brand Colors 2019
 */
export const BeaconGreenHex = '#BED600';
export const TrackBlackHex = '#000000';
