import React from 'react';
import { Modal } from 'antd';
import PropTypes from 'prop-types';
import FlightPlayer from '../Flight3dPlayer/FlightPlayer';

const FlightPlayerModal = ({ viewingTrack, onCancel }) => {
  const visible = !!viewingTrack && !!viewingTrack.trackId && !!viewingTrack.stId;

  return visible ? (
    <Modal
      title={
        <div>
          <span>{`${viewingTrack.aircraft} - ${viewingTrack.startTime}`}</span>
        </div>
      }
      visible={visible}
      footer={null}
      destroyOnClose={true}
      onCancel={onCancel}
      width="75vw"
      bodyStyle={{
        padding: 0,
        height: '75vh'
      }}
    >
      <FlightPlayer
        trackId={viewingTrack.trackId}
        stId={viewingTrack.stId}
        aircraftType={viewingTrack.aircraftType}
      />
    </Modal>
  ) : null;
};

FlightPlayerModal.propTypes = {
  viewingTrack: PropTypes.object,
  onCancel: PropTypes.func.isRequired
};
export default FlightPlayerModal;
