import { DisplayPoint } from '../../../../common/api/spidertracks-sdk/types/TrackData';
import {
  formatStandardDateStrings,
  fromTimestamp,
  DateFormatterFn
} from '../../../../helpers/formatTime';
import { getIconUrlFromButtonMode, getIconSvg } from '../utils/drawing/marker';

export const formatDisplayPoint = (
  points: DisplayPoint[],
  formatTimefn: DateFormatterFn,
  timezone: string
) => {
  return points
    .filter(p => p.description && getIconSvg(null, p.buttonMode))
    .map(p => ({
      name: p.description,
      iconUrl: getIconUrlFromButtonMode(p.buttonMode),
      time: formatStandardDateStrings(formatTimefn, fromTimestamp(p.timestamp), timezone)[0]
    }));
};
