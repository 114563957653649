import { UserData } from '../../../types';
import { Favourite } from '../../../../common/api/spidertracks-sdk/types/Favourite';

export const EXCLUSIONS = 'EXCLUSIONS';
export const UNREAD_SPIDERTXT = 'UNREAD_SPIDERTXT';
export const SOS_NOTIFICATIONS = 'SOS_NOTIFICATIONS';
export const USER_DATA = 'USER_DATA';
export const FAVOURITES = 'FAVOURITES';

interface SetExclusionsAction {
  type: typeof EXCLUSIONS;
  payload: string[];
}

interface SetUnreadSpidertxtAction {
  type: typeof UNREAD_SPIDERTXT;
  payload: number;
}

interface SetSosNotificationsAction {
  type: typeof SOS_NOTIFICATIONS;
  payload: any[];
}

interface SetUserDataAction {
  type: typeof USER_DATA;
  payload: any;
}

interface SetFavouritesDataAction {
  type: typeof FAVOURITES;
  payload: any;
}

export type ActionTypes =
  | SetExclusionsAction
  | SetUnreadSpidertxtAction
  | SetSosNotificationsAction
  | SetUserDataAction
  | SetFavouritesDataAction;

export const setExclusions = (exclusions: string[]): ActionTypes => ({
  type: EXCLUSIONS,
  payload: exclusions
});

export const setUnreadSpidertxt = (spidertxtCount: number): ActionTypes => ({
  type: UNREAD_SPIDERTXT,
  payload: spidertxtCount
});

export const setSosNotifications = (sosNotifications: any[]): ActionTypes => ({
  type: SOS_NOTIFICATIONS,
  payload: sosNotifications
});

export const setUserData = (userData: UserData): ActionTypes => ({
  type: USER_DATA,
  payload: userData
});

export const setFavourites = (favourites: Favourite): ActionTypes => ({
  type: FAVOURITES,
  payload: favourites
});

export default {
  setExclusions,
  setUnreadSpidertxt,
  setSosNotifications,
  setUserData,
  setFavourites
};
