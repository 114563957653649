import React from 'react';
import { DeleteOutline } from '@material-ui/icons';
import { grey } from '@ant-design/colors';
import { boldFont } from '../common/styles';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const DeleteTracksContainer = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  color: ${grey.primary};
`;

const DeleteTracksLabel = styled.span`
  margin-left: 5px;
  font-family: arial;
  font-weight: 500;
`;

export const DeleteTracks = ({ handleClick }) => {
  return (
    <DeleteTracksContainer onClick={handleClick}>
      <DeleteOutline style={{ fontSize: '14px' }} />
      <DeleteTracksLabel>Delete</DeleteTracksLabel>
    </DeleteTracksContainer>
  );
};

DeleteTracks.propTypes = {
  handleClick: PropTypes.func
};

export default DeleteTracks;
