import { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { populateUserData } from '../../redux/reducers/navigationReducer/actions/thunk';
import { bindActionCreators } from 'redux';
import { getUserData } from '../../redux/selectors/userData';
import { StandardDispatch, FullState } from '../../store';

const mapStateToProps = (state: FullState) => {
  return { userData: getUserData(state) };
};

const mapDispatchToProps = (dispatch: StandardDispatch) => {
  return {
    ...bindActionCreators(
      {
        populateUserData
      },
      dispatch
    )
  };
};

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = ReturnType<typeof mapDispatchToProps>;
type IComponentProps = IStateProps & IDispatchProps & RouteComponentProps;

class PublicDataLoader extends Component<IComponentProps, never> {
  public constructor(props: IComponentProps) {
    super(props);
    this.props.populateUserData();
  }

  public render() {
    return null;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PublicDataLoader);
