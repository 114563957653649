/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  fetchUnreadSpidertxt,
  fetchSosNotifications,
  fetchContactDetails
} from '../../../../common/api/api';
import {
  mapView,
  savedFilter,
  uiSettings,
  setPointGroupingOption
} from '../../mapReducer/actions/map/index';
import mapviewdata from '../../../../components/Flying/Map/MapControls/mapview/mapviewdata.json';
import {
  setExclusions,
  setUnreadSpidertxt,
  setSosNotifications,
  setUserData,
  setFavourites
} from './index';
import { getInstance } from '../../../../common/api/spidertracks-sdk';
import { StandardThunk, StandardDispatch } from '../../../../store';
import { isAuthenticated } from '@spidertracks/common';

export const populateUserData = (): StandardThunk<void> => async dispatch => {
  const SpidertracksSDK = getInstance();
  const data = await SpidertracksSDK.getUsers();

  dispatch(setUserData(data));

  const exclusions = ['journeylog', 'schedule'];
  const { orgs } = data;
  if (orgs.length > 0) {
    data.orgs.forEach(org => {
      if (org.org.betaFeatures) {
        org.org.betaFeatures.forEach(feature => {
          if (feature.enabled) {
            exclusions.splice(
              exclusions.findIndex(elem => elem === feature.name),
              1
            );
          }
        });
      }
    });
  }

  dispatch(setPointGroupingOption(data.states.pointGroupingOption));
  dispatch(savedFilter(data.states.sidebarTab));
  dispatch(uiSettings({ clusterer: data.states.groupTrackPoints }));

  if (isAuthenticated()) dispatch(setExclusions(exclusions));

  const savedMapView = mapviewdata.find((item: any) => item.key === data.states.mapLayer);
  if (savedMapView) {
    if (savedMapView.provider === 'google') {
      dispatch(mapView({ googleView: savedMapView.viewOption, skyVectorView: '' }));
    } else {
      dispatch(mapView({ googleView: '', skyVectorView: savedMapView.viewOption }));
    }
  }
};

export const populateUnreadSpidertxt = () => (dispatch: StandardDispatch) =>
  fetchUnreadSpidertxt().then((data: any) => {
    dispatch(setUnreadSpidertxt(data.result));
  });

export const populateSosNotifications = () => (dispatch: StandardDispatch) =>
  fetchSosNotifications().then((data: any) => {
    dispatch(setSosNotifications(data));
  });

export const populateFavourites = (): StandardThunk<void> => async dispatch => {
  const SpidertracksSDK = getInstance();
  const favourites = await SpidertracksSDK.getFavouritesService().get();

  dispatch(setFavourites(favourites));
};

export const getContactDetails = (trackIdentifier: string) =>
  fetchContactDetails(trackIdentifier).then((contactDetails: any) => contactDetails);

export default {
  populateUserData,
  populateUnreadSpidertxt,
  populateSosNotifications,
  populateFavourites,
  getContactDetails
};
