import React, { FC, Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getInstance } from '../../../common/api/spidertracks-sdk';
import { PrivateTrackData } from '../../../common/api/spidertracks-sdk/types/TrackData';
import { throwIfRequestError } from '../../../helpers';
import { trackFetchError, trackNotFound } from '../../../helpers/globalNotifications';
import { useTrackRouteParams } from '../../../hooks/useTrackRouteParams';
import { setSelectedFlight } from '../../../redux/reducers/aircraftReducer/thunk';
import { savedFilter } from '../../../redux/reducers/mapReducer/actions/map';
import { getSelectedTracks } from '../../../redux/selectors/aircraftData';
import {
  getEventTypesByTrack,
  getFlightStatus,
  getDisplayPoints
} from '../../../redux/selectors/eventData';
import { getSelectedFilter } from '../../../redux/selectors/mapData';
import { fetchSelectedTrackAircraft } from '../../../redux/slice/aircraft';
import {
  clearSelectedDisplayPoint,
  fetchEvents,
  setEvents,
  setEventTypes,
  setFlightStatus
} from '../../../redux/slice/events';
import {
  clearSelectedTracks,
  updateAllTracks,
  setSelectedTracks,
  setSelectedTrackId
} from '../../../redux/slice/tracks';
import { AZ } from '../../Flying/AircraftList/constants';
import { LoadingBackground } from '../../LoadingBackground';

// Wrap a child component in this to prevent its display until a specific track has been loaded and
// is available in the store. Designed to work on the following route parameters:
//  - :trackId (probably most requests)
//  - :serialNumber & :bootcount (click through from FSI dashboard)
//  - :trackIds in querystring (multiview)
// Also loads relevant events. Waits for everything to arrive in store, then renders the child component(s).
export const PreselectMultipleTracks: FC = ({ children }) => {
  const dispatch = useDispatch();
  const { trackHistoryRequest, trackIds } = useTrackRouteParams();

  const SpidertracksSDK = getInstance();

  const eventTypes = useSelector(getEventTypesByTrack);
  const flightStatus = useSelector(getFlightStatus);

  const displayPoints = useSelector(getDisplayPoints);
  const selectedTracks = useSelector(getSelectedTracks);
  const selectedAircraftFilter = useSelector(getSelectedFilter);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    const fetchTracks = async () => {
      dispatch(savedFilter(AZ));

      try {
        const trackHistoryResponse = await SpidertracksSDK.getTrackHistory(trackHistoryRequest);
        const track = trackHistoryResponse.items[0] as PrivateTrackData;
        dispatch(updateAllTracks(trackHistoryResponse.items));
        dispatch(setSelectedTracks(trackIds));
        dispatch(setSelectedFlight(track));
        dispatch(setSelectedTrackId(undefined));

        if (!track.aircraft || track.aircraft.id === '') {
          dispatch(setEvents({ items: [] }));
        } else {
          dispatch(fetchEvents(track.aircraft.id, track.departedTime, track.endTime));
        }
      } catch (e) {
        console.error(e);
        if (e.message.includes('404')) {
          return trackNotFound();
        }
        trackFetchError();
      }
    };

    fetchTracks();

    return () => {
      dispatch(clearSelectedTracks());
      dispatch(clearSelectedDisplayPoint());
      dispatch(savedFilter(selectedAircraftFilter));
    };
  }, []);

  useEffect(() => {
    // TODO: this might be a good candidate for caching, will infrequently change
    const fetchOrganisationEventTypes = async (selectedTrack: PrivateTrackData) => {
      // Check cache - should be changed to be by org id not track
      if (eventTypes[selectedTrack.trackId]) {
        return;
      }

      if (selectedTrack) {
        if (!selectedTrack.aircraft.org) {
          dispatch(setEventTypes({ trackId: selectedTrack.trackId, eventTypes: [] }));
          return;
        }

        try {
          const eventTypesResponse = await SpidertracksSDK.getEventService().getEventTypes(
            selectedTrack.aircraft.org.id
          );
          throwIfRequestError(eventTypesResponse);
          dispatch(
            setEventTypes({ trackId: selectedTrack.trackId, eventTypes: eventTypesResponse.items })
          );
        } catch (e) {
          console.error(e);
          trackFetchError();
        }
      }
    };

    selectedTracks.forEach(selectedTrack => fetchOrganisationEventTypes(selectedTrack));
  }, [eventTypes, selectedTracks, SpidertracksSDK, dispatch]);

  useEffect(() => {
    const fetchFlightStatus = async () => {
      // Check cache!
      if (trackIds.every(trackId => flightStatus[trackId])) return;

      if (selectedTracks.length > 0) {
        try {
          const flightStatusResponse = await SpidertracksSDK.getFlightDataService().getFlightStatus(
            selectedTracks.map(t => t.id)
          );
          flightStatusResponse.items.forEach(flightStatus =>
            dispatch(
              setFlightStatus({
                trackId: selectedTracks.find(track => String(track.id) === flightStatus.id)
                  ?.trackId,
                flightStatus: flightStatus.status
              })
            )
          );
        } catch (e) {
          console.error(e);
          trackFetchError();
        }
      }
    };
    fetchFlightStatus();
  }, [flightStatus, selectedTracks, dispatch, SpidertracksSDK, trackIds]);

  useEffect(() => {
    if (displayPoints && Object.values(displayPoints).length && !ready) {
      dispatch(fetchSelectedTrackAircraft());
      setReady(true);
    }
  }, [displayPoints, ready]);

  return <Fragment>{ready ? children : <LoadingBackground />}</Fragment>;
};

export default PreselectMultipleTracks;
