import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import { NavLink } from 'react-router-dom';

const { Option } = Select;

export const Create = ({ key }) => (
  <Option key={key}>
    <NavLink exact to={`/organisation/create`}>
      Create New Organisation
    </NavLink>
  </Option>
);

Create.propTypes = {
  key: PropTypes.string,
  label: PropTypes.string
};

export default Create;
