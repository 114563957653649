import React, { useState } from 'react';
import { Modal, Tooltip, notification } from 'antd';
import './download-modal.scss';
import { MapOutlined, TableChartOutlined, TimelineOutlined } from '@material-ui/icons';

export interface ComponentProps {
  visible: boolean;
  selectedTrackCount: number;
  requestClose: () => void;
  downloadCSVSummary: () => void;
  downloadCSVDetailed: () => void;
  downloadKML: () => void;
  downloadLimit: number;
}

export const DownloadTracksModal: React.FC<ComponentProps> = ({
  visible,
  selectedTrackCount,
  requestClose,
  downloadCSVSummary = () => {},
  downloadCSVDetailed,
  downloadKML,
  downloadLimit
}: ComponentProps) => {
  const [csvSummary, setCSVSummary] = useState(false);
  const [csvDetail, setCSVDetail] = useState(false);
  const [kml, setKML] = useState(false);

  const detailDisabled = selectedTrackCount > downloadLimit;
  const disabledButtonText = `You can not download this file type as you have selected a large number of flights. Please select up to ${downloadLimit} flights.`;

  return (
    <Modal
      className="download-modal"
      title={
        <div className="download-modal__title-container">
          <p className="download-modal__title-title ">Download Flights</p>
          <p className="download-modal__title-subtitle">
            Select the file types you wish to download
          </p>
        </div>
      }
      okText="Download"
      style={{ border: 0 }}
      maskStyle={{ border: 0 }}
      bodyStyle={{ border: 0 }}
      visible={visible}
      destroyOnClose={true}
      onCancel={requestClose}
      okButtonProps={{ disabled: !csvSummary && !csvDetail && !kml }}
      onOk={() => {
        csvSummary && downloadCSVSummary();
        csvDetail && downloadCSVDetailed();
        kml && downloadKML();
        setCSVSummary(false);
        setCSVDetail(false);
        setKML(false);
        notification.success({
          key: 'download-modal',
          message: `Downloading tracks...`
        });
        requestClose();
      }}
    >
      <div className="download-modal__grid">
        <div className="download-modal__option">
          <div className="download-modal__icon-container">
            <TableChartOutlined className="download-modal__icon" />
          </div>
          <span onClick={() => setCSVSummary(!csvSummary)}>
            <input
              data-testid="csv-summary"
              className="download-modal__checkbox"
              type="checkbox"
              checked={csvSummary}
              onChange={() => setCSVSummary(!csvSummary)}
            />
            CSV summary file
          </span>
          <span className="download-modal__option-description">
            Download track summary in CSV format
          </span>
        </div>
        <div className="download-modal__option">
          <div className={`download-modal__icon-container${detailDisabled ? '--disabled' : ''}`}>
            <TimelineOutlined className="download-modal__icon" />
          </div>
          <Tooltip
            placement="bottom"
            title={disabledButtonText}
            {...(detailDisabled ? {} : { visible: false })}
          >
            <span
              onClick={() => {
                if (!detailDisabled) {
                  setCSVDetail(!csvDetail);
                }
              }}
              className={`download-modal__checkbox-label${detailDisabled ? '--disabled' : ''}`}
            >
              <input
                data-testid="csv-detail"
                className="download-modal__checkbox"
                type="checkbox"
                checked={csvDetail}
                disabled={detailDisabled ? true : false}
                onChange={() => {
                  if (!detailDisabled) {
                    setCSVDetail(!csvDetail);
                  }
                }}
              />
              CSV detail file
            </span>
          </Tooltip>
          <span className="download-modal__option-description">
            Download track details in CSV format
          </span>
        </div>

        <div className="download-modal__option">
          <div className={`download-modal__icon-container${detailDisabled ? '--disabled' : ''}`}>
            <MapOutlined className="download-modal__icon" />
          </div>
          <Tooltip
            placement="bottom"
            title={disabledButtonText}
            {...(detailDisabled ? {} : { visible: false })}
          >
            <span
              onClick={() => {
                if (!detailDisabled) {
                  setKML(!kml);
                }
              }}
              className={`download-modal__checkbox-label${detailDisabled ? '--disabled' : ''}`}
            >
              <input
                data-testid="kml"
                className="download-modal__checkbox"
                type="checkbox"
                checked={kml}
                disabled={detailDisabled ? true : false}
                onChange={() => {
                  if (!detailDisabled) {
                    setKML(!kml);
                  }
                }}
              />
              KML file
            </span>
          </Tooltip>
          <span className="download-modal__option-description">
            Download track details in KML format
          </span>
        </div>
      </div>
    </Modal>
  );
};
