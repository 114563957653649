import { vectorTile } from './vectorTile';

export const lowMap = ({ getNormalizedCoorda, skyVectorView }) => {
  const vectorMapType = new window.google.maps.ImageMapType({
    getTileUrl: function tileUrl(coord, zoom) {
      const normalizedCoord = getNormalizedCoorda(coord, zoom);
      const viewUrl = vectorTile(normalizedCoord, skyVectorView, zoom);
      return viewUrl;
    },
    minZoom: 1,
    maxZoom: 10,
    tileSize: new window.google.maps.Size(256, 256),
    name: 'Low'
  });

  window.gmap.mapTypes.set('low', vectorMapType);
  window.gmap.setMapTypeId('low');
};

export default lowMap;
