import React, { memo, useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { GoogleMap, useLoadScript, DrawingManager, Data } from '@react-google-maps/api';
import { Spin } from 'antd';
import { debounce } from '../../../helpers/debounce';

// move to googlemapcontainer
const mapOptions = {
  minZoom: 2,
  mapTypeControl: false,
  panControl: false,
  streetViewControl: false,
  fullscreenControl: false,
  preserveViewport: true,
  rotateControl: false,
  center: { lat: 0, lng: 0 },
  zoom: 4
};

const shapeOptions = {
  strokeWeight: 1
};
const preDrawingManagerOptions = {
  drawingControlOptions: {
    position: 2,
    drawingModes: ['polygon', 'rectangle']
  },
  polygonOptions: shapeOptions,
  rectangleOptions: shapeOptions
};
const postDrawingManagerOptions = {
  drawingControlOptions: {
    position: 2,
    drawingModes: []
  },
  polygonOptions: shapeOptions,
  rectangleOptions: shapeOptions
};
const libraries = ['drawing'];

const GoogleMapContainer = forwardRef(
  ({ className, value = {}, onChange, drawingControl = true, selectedGeofence }, ref) => {
    const [drawingManagerOptions, setDrawingManagerOptions] = useState({
      ...preDrawingManagerOptions,
      drawingControl
    });
    const [drawingMode, setDrawingMode] = useState('polygon');

    const { isLoaded, loadError: isError } = useLoadScript({
      googleMapsApiKey: window.env.STL_GOOGLE_MAPS_API,
      libraries,
      version: '3.47' // Temporary fix see https://github.com/JustFly1984/react-google-maps-api/issues/2963
    });

    const convertShapeToGeoJSON = shape => {
      let GeoJSON = {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [[]]
        },
        properties: {}
      };
      if (shape.getBounds) {
        // Is a Rectangle
        const ne = shape.getBounds().getNorthEast();
        const sw = shape.getBounds().getSouthWest();
        const latLngArray = [
          [ne.lng(), sw.lat()],
          [ne.lng(), ne.lat()],
          [sw.lng(), ne.lat()],
          [sw.lng(), sw.lat()]
        ];
        GeoJSON.geometry.coordinates[0].push(...latLngArray);
      } else {
        // Is a Polygon
        for (let point of shape.getPath().getArray()) {
          GeoJSON.geometry.coordinates[0].push([point.lng(), point.lat()]);
        }
      }

      GeoJSON.geometry.coordinates[0].push(GeoJSON.geometry.coordinates[0][0]);
      return GeoJSON;
    };

    const triggerChange = debounce(changedValue => {
      if (onChange) {
        onChange({
          ...value,
          ...convertShapeToGeoJSON(changedValue)
        });
      }
    }, 200);

    const onShapeComplete = (shape, type) => {
      shape.setEditable(true);
      shape.setDraggable(true);

      if (type === 'rectangle') {
        shape.addListener('bounds_changed', () => {
          triggerChange(shape);
        });
      } else {
        shape.getPath().addListener('insert_at', () => {
          triggerChange(shape);
        });
        shape.getPath().addListener('set_at', () => {
          triggerChange(shape);
        });
      }

      triggerChange(shape);
      setDrawingManagerOptions(postDrawingManagerOptions);
      setDrawingMode();
    };
    const onPolygonComplete = polygon => {
      onShapeComplete(polygon, 'polygon');
    };
    const onRectangleComplete = rectangle => {
      onShapeComplete(rectangle, 'rectangle');
    };

    if (isError) {
      return <div>Map cannot be loaded right now, sorry.</div>;
    }

    const setGeoJsonToMap = data => {
      data.addGeoJson(selectedGeofence);
      const bounds = new window.google.maps.LatLngBounds();
      data.forEach(feature => {
        const geo = feature.getGeometry();
        geo.forEachLatLng(LatLng => bounds.extend(LatLng));
      });
      data.map.fitBounds(bounds);
    };

    return isLoaded ? (
      <React.Fragment>
        <GoogleMap ref={ref} mapContainerClassName={className} options={mapOptions}>
          {drawingControl && (
            <DrawingManager
              drawingMode={drawingMode}
              onPolygonComplete={onPolygonComplete}
              onRectangleComplete={onRectangleComplete}
              options={drawingManagerOptions}
            />
          )}
          {selectedGeofence && <Data onLoad={setGeoJsonToMap} />}
        </GoogleMap>
      </React.Fragment>
    ) : (
      <Spin />
    );
  }
);

GoogleMapContainer.propTypes = {
  className: PropTypes.string,
  value: PropTypes.object,
  onChange: PropTypes.func
};

export default memo(GoogleMapContainer);
