import React from 'react';
import { Modal } from 'antd';
import { convertMetresTo } from '../Flying/Map/utils/helper';
import HistoryTableHeader from './HistoryTableHeader';
import PropTypes from 'prop-types';

export function Title({
  selectedRowKeys,
  clearSelection,
  totalDuration,
  totalDistance,
  distanceUnit,
  dateFormat,
  timezone,
  downloadTracks,
  deleteTracks,
  history,
  selectedTracksInfo,
  downloadLimit
}) {
  return (
    <HistoryTableHeader
      selectedTracksInfo={selectedTracksInfo}
      selectedRowKeys={selectedRowKeys}
      deleteTracks={deleteTracks}
      history={history}
      trackIds={selectedRowKeys.join(',')}
      selectedTrackCount={selectedRowKeys.length}
      clearSelection={clearSelection}
      totalDuration={totalDuration.format('d [days] h [hrs] m [min]')}
      totalDistance={convertMetresTo(totalDistance, distanceUnit)[0]}
      dateFormat={dateFormat}
      timezone={timezone}
      distanceUnit={distanceUnit}
      downloadCSV={() => downloadTracks({ type: 'csv' })}
      downloadCSVSummary={() => downloadTracks({ type: 'csvsummary' })}
      downloadKML={() => downloadTracks({ type: 'kml' })}
      openModal={() =>
        Modal.confirm({
          title: 'Do you want to delete these items?',
          content: `Clicking the OK button will delete the ${selectedRowKeys.length} selected tracks`,
          okText: 'delete',
          cancelText: 'cancel',
          onOk: deleteTracks
        })
      }
      downloadLimit={downloadLimit}
    />
  );
}

Title.propTypes = {
  history: PropTypes.object,
  selectedRowKeys: PropTypes.array,
  clearSelection: PropTypes.func,
  totalDuration: PropTypes.object,
  totalDistance: PropTypes.number,
  distanceUnit: PropTypes.string,
  dateFormat: PropTypes.string,
  timezone: PropTypes.string,
  downloadTracks: PropTypes.func,
  deleteTracks: PropTypes.func,
  selectedTracksInfo: PropTypes.array
};

export default Title;
