export const MAP_CONTAINER = 'mapContainer';
export const GOOGLE_MAP_CONTAINER = 'googleMapContainer';

export const FIFTEEN_MINUTES = 900000; // in milliseconds

export const POINT_INFO_PANEL = {
  header: 'Aircraft',
  time: 'Time',
  position: 'Position',
  altitude: 'Altitude',
  speed: 'Speed',
  direction: 'Direction',
  degree: 'T',
  utc: 'UTC',
  description: 'Description',
  fsiEventName: 'Event',
  fsiEventThreshold: 'Threshold',
  fsiEventValue: 'Value'
};

export const SVG_MARKER_ICON_SIZE = {
  width: 200,
  height: 200
};

export const BARON = {
  BASE_URL: 'https://api.velocityweather.com/v1',
  KEY: 'Rf55lhYIwpcS',
  SECRET: 'E2hXoOBDVCuTFjXmdUV1fdDQ89eYF0n2Ly2jhniJ8k'
};

export const MAP_ZOOM = {
  SELECTED_MAX_MAP_ZOOM: 14
};

export const ACTIVE_ICON_CIRCLE_OPACITY = 0.2;

export const LAST_POINT = 'LAST_POINT';
export const FULL_TRACK = 'FULL_TRACK';

export const NORMAL_BUTTON_MODES = [
  0,
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  69,
  75,
  90,
  91,
  92,
  93,
  94,
  95,
  96,
  97,
  100,
  101,
  102,
  120,
  131,
  132,
  135,
  136,
  140,
  141,
  145,
  146,
  150,
  151
];
