import React from 'react';
import PropTypes from 'prop-types';
import { Form, Row } from 'antd';
import { Divider } from '../../common/styled/Divider';
import { getValidationRules, getThresholdInputLabel } from './utils';
import { InputNumber } from 'antd';

export const Parameter = ({ parameter, getFieldDecorator, setFieldsValue }) => {
  const validationRules = getValidationRules(parameter);
  const detail = getThresholdInputLabel(parameter);
  const fieldName = `displayThresholds.${parameter.name}`;

  return (
    <Form.Item label={detail} name={parameter.name} style={{ textAlign: 'left' }} className="mt-3">
      <Row>
        {getFieldDecorator(fieldName, {
          initialValue: parameter.displayValue,
          validateFirst: true,
          rules: [{ message: 'Please enter a value', required: true }, ...validationRules]
        })(
          <InputNumber
            onChange={val => setFieldsValue({ [fieldName]: val })}
            placeholder="Enter a value"
            style={{ width: '230px' }}
          />
        )}
        <span style={{ fontSize: 16, paddingTop: 15, paddingLeft: 5 }}>
          {parameter.displayUnit}
        </span>
      </Row>
    </Form.Item>
  );
};

Parameter.propTypes = {
  parameter: PropTypes.object,
  getFieldDecorator: PropTypes.func,
  setFieldsValue: PropTypes.func
};

Parameter.displayName = 'Parameter';

export const ParameterThresholdFields = ({
  selectedParameter,
  getFieldDecorator,
  setFieldsValue
}) => {
  return selectedParameter.parameters.map((parameter, i) => {
    const showDivider = selectedParameter.parameters.length > 1 && i % 2 === 0;
    return (
      <React.Fragment key={`${selectedParameter.eventClass}#${selectedParameter.eventType}#${i}`}>
        <Parameter
          setFieldsValue={setFieldsValue}
          parameter={parameter}
          getFieldDecorator={getFieldDecorator}
        />
        {showDivider && (
          <Divider>
            <span style={{ color: '#9F9F9F' }}>AND</span>
          </Divider>
        )}
      </React.Fragment>
    );
  });
};

ParameterThresholdFields.propTypes = {
  unit: PropTypes.string,
  selectedParameter: PropTypes.object,
  threshold: PropTypes.object,
  getFieldDecorator: PropTypes.func
};

ParameterThresholdFields.displayName = 'ParameterThresholdFields';
