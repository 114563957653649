import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { DateFilter, getRanges } from '../../common/filters';
import { AircraftFilter } from '../filters/AircraftFilter';
import { Context as SisenseContext } from '../../../context/SisenseContext';
import { insightsDashboardUnavailableError } from '../../../helpers';
import { useSelector, useDispatch } from 'react-redux';
import {
  getSelectedAircraft,
  setSelectedAircraft,
  getDates,
  setDates,
  resetDates
} from '../../../redux/slice/insights';
import moment from 'moment';

/**
 * Todo: Add Sisense types when they become available
 */
const today = new Date().toISOString();

export const SisenseFilters = ({ datasource, aircraftdim }) => {
  const dates = useSelector(getDates);
  const selectedAircraft = useSelector(getSelectedAircraft);
  const dispatch = useDispatch();

  const { sisense, aircraftFilters } = useContext(SisenseContext);
  const [visible, setVisible] = useState(false);
  const [searchText, setSearchText] = useState('');

  const applyDateFilters = (from, to) => {
    try {
      sisense.dashboard.applyFilters({
        jaql: {
          dim: '[DimDates.Date (Calendar)]',
          datatype: 'datetime',
          level: 'days',
          filter: {
            from,
            to
          }
        }
      });
    } catch (e) {
      console.log(e);
      insightsDashboardUnavailableError();
    }
  };

  const applyAircraftFilter = aircraft => {
    try {
      sisense.dashboard.applyFilters({
        jaql: {
          datasource,
          dim: aircraftdim,
          datatype: 'type',
          filter: {
            members: [...aircraft]
          }
        }
      });
    } catch (e) {
      console.log(e);
      insightsDashboardUnavailableError();
    }
  };

  const updateDateFilter = dates => {
    if (!dates.length) {
      dispatch(resetDates());
      applyDateFilters('2019-01-01', today);
    }
    if (sisense && !!dates.length && dates !== null && dates[0] && dates[1]) {
      const from = dates[0].format('YYYY-MM-DD');
      const to = dates[1].format('YYYY-MM-DD');

      dispatch(
        setDates({
          dashboardFrom: from,
          dashboardTo: to
        })
      );
      applyDateFilters(from, to);
    }
  };

  const clearAircraftFilter = () => {
    dispatch(setSelectedAircraft([]));
    applyAircraftFilter([]);
    setVisible(false);
    setSearchText('');
  };

  const confirmFilter = () => {
    applyAircraftFilter(selectedAircraft);
    setVisible(false);
    setSearchText('');
  };

  const selectAircraft = aircraft => dispatch(setSelectedAircraft(aircraft));

  let calendarFieldsValues;

  // pass values prop if calendarFieldValues are valid dates
  if (!dates.dashboardFrom && !dates.dashboardTo) {
    calendarFieldsValues = [undefined, undefined];
  } else {
    calendarFieldsValues = [moment(dates.dashboardFrom), moment(dates.dashboardTo)];
  }
  const ranges = getRanges();

  return (
    <React.Fragment>
      <DateFilter
        ranges={ranges}
        handleChange={updateDateFilter}
        calendarFieldsValues={calendarFieldsValues}
      />
      <AircraftFilter
        searchText={searchText}
        setSearchText={setSearchText}
        visible={visible}
        setVisible={setVisible}
        filters={aircraftFilters}
        aircraftdim={aircraftdim}
        selectAircraft={selectAircraft}
        selectedAircraft={selectedAircraft}
        clearAircraftFilter={clearAircraftFilter}
        confirmFilter={confirmFilter}
      />
    </React.Fragment>
  );
};

SisenseFilters.propTypes = {
  datasource: PropTypes.string,
  datedim: PropTypes.string,
  aircraftdim: PropTypes.string
};

export default SisenseFilters;
