/* eslint-disable @typescript-eslint/no-unused-vars */
import { TableFullRowSelect } from '@spidertracks/components';
import { Button, ConfigProvider, PageHeader } from 'antd';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useReducer, useRef, useMemo } from 'react';
import { connect } from 'react-redux';
import {
  getDateTimeFormat,
  getDistanceUnit,
  getTimezone,
  getUserData
} from 'redux/selectors/userData';
import { getInstance } from '../../common/api/spidertracks-sdk';
import {
  historyTableDeleteError,
  historyTableDeleteError404,
  historyTableDeleteErrorAuth,
  replayError
} from '../../helpers';
import { initializeDuration } from '../../helpers/formatTime';
import { useFeatureFlag, useHistoryTableData } from '../../hooks';
import { getRowClassName } from '../common';
import {
  DateFilter,
  FilterIconFunnel,
  getFilterDropdown,
  getRanges,
  getTaggedLabel
} from '../common/filters';
import { DELETED } from '../Flying/AircraftList/constants';
import FlightPlayerModal from './FlightPlayerModal';
import { historyReducer } from './historyReducer';
import ReplayColumnCell from './ReplayColumnCell';
import ReplayColumnHeader from './ReplayColumnHeader';
import { Title } from './Title';
import { useHistory } from 'react-router-dom';
import { useHistoryParams } from './useHistoryParams';
import moment from 'moment';

const RESULTS_PER_PAGE = 30;
const DOWNLOAD_LIMIT = 200;

const sortAircraftFilterList = (a, b, aircraftFilteredValues = []) => {
  const aRegistration = a.registration.trim().toLowerCase(),
    bRegistration = b.registration.trim().toLowerCase(),
    includesA = aircraftFilteredValues.includes(a.value),
    includesB = aircraftFilteredValues.includes(b.value);

  if (a.deleted && b.deleted) return aRegistration > bRegistration ? 1 : -1;
  if (a.deleted) return 1;
  if (b.deleted) return -1;

  if (includesA && includesB) return aRegistration > bRegistration ? 1 : -1;
  if (includesA) return -1;
  if (includesB) return 1;

  return aRegistration > bRegistration ? 1 : -1;
};

export const HistoryContainer = ({
  dateFormat = 'DD/MM/YYYY HH:mm:ss',
  timezone = 'Pacific/Auckland',
  distanceUnit = 'Miles',
  userData
}) => {
  const history = useHistory();
  const tableContainer = useRef(null);
  const tableScrollHeight = useMemo(
    () => tableContainer?.current?.getBoundingClientRect().height - 300,
    [tableContainer.current]
  );

  const initialState = {
    totalDistance: 0,
    totalDuration: initializeDuration(),
    tableData: [],
    aircraftFilterList: [],
    selectedRowKeys: []
  };

  const SpidertracksSDK = getInstance();
  const [
    { totalDistance, totalDuration, tableData, selectedRowKeys, aircraftFilterList, viewingTrack },
    dispatch
  ] = useReducer(historyReducer, initialState);
  const dispatchAll = actions =>
    actions.forEach(action => {
      let type = action.type;
      let payload = action.payload;
      dispatch({ type, payload });
    });

  const clearSelection = useCallback(
    () =>
      dispatchAll([
        { type: 'selectedRowKeys', payload: [] },
        { type: 'totalDistance', payload: 0 },
        { type: 'totalDuration', payload: initializeDuration() }
      ]),
    []
  );

  const {
    aircraftFilteredValues = [],
    distanceFilteredValues,
    durationFilteredValues,
    endTime,
    page = 1,
    sosFilteredValues = [],
    startTime,
    updateParams
  } = useHistoryParams();

  const search = new URLSearchParams(history.location.search);
  search.set('resultsPerPage', RESULTS_PER_PAGE);
  const [fetchHistoryTracks, trackData, meta, loadingTrackHistory] = useHistoryTableData(
    search,
    {
      dateFormat,
      timezone,
      distanceUnit
    },
    false
  );

  const didMount = useRef(false);
  useEffect(() => {
    const search = new URLSearchParams(history.location.search);
    search.set('resultsPerPage', RESULTS_PER_PAGE);

    if (!didMount.current && !endTime && !startTime) {
      // This is the first render. Set a default time period in the URL.
      updateParams({
        endTime: moment(),
        startTime: moment().subtract(1, 'year')
      });
      didMount.current = true;
      return;
    }

    fetchHistoryTracks(search);
  }, [history.location.search]);

  useEffect(() => {
    if (meta) {
      dispatch({ type: 'tableData', payload: trackData });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meta, trackData]);

  const getAircraftFilterList = async () => {
    const aircraft = await getInstance().getAircraft();

    const aircraftFilterList = Object.values(
      aircraft.reduce((acc, val) => {
        let registration = val.registration;
        let deleted = false;
        if (val.status === 'DELETED') {
          deleted = true;
        }
        acc[registration] = {
          deleted,
          registration,
          label: deleted ? getTaggedLabel(registration, DELETED) : registration,
          value: val.id,
          key: val.id
        };
        return acc;
      }, {})
    );
    return aircraftFilterList;
  };

  useEffect(() => {
    getAircraftFilterList().then(aircraftFilterList =>
      dispatch({ type: 'aircraftFilterList', payload: aircraftFilterList })
    );
  }, []);

  const sortedAircraftFilterList = useMemo(() => {
    return (
      aircraftFilterList &&
      [...aircraftFilterList].sort((a, b) => sortAircraftFilterList(a, b, aircraftFilteredValues))
    );
  }, [aircraftFilterList, aircraftFilteredValues]);

  const downloadTracks = useCallback(
    async ({ type }) => {
      await SpidertracksSDK.getDownload({ type, trackIds: selectedRowKeys.join(',') });
    },
    [SpidertracksSDK, selectedRowKeys]
  );

  const deleteTracks = async () => {
    try {
      await SpidertracksSDK.deleteTracks(selectedRowKeys);
    } catch (e) {
      switch (e.httpResponse?.status) {
        case 404:
          historyTableDeleteError404();
          break;
        case 403:
          historyTableDeleteErrorAuth();
          break;
        default:
          historyTableDeleteError();
      }
    } finally {
      const search = new URLSearchParams(history.location.search);
      search.set('resultsPerPage', RESULTS_PER_PAGE);
      dispatch({ type: 'selectedRowKeys', payload: [] });
      fetchHistoryTracks(search);
      if (
        trackData.length === selectedRowKeys.length &&
        page === Math.ceil(meta.total / RESULTS_PER_PAGE)
      ) {
        updateParams({
          page: page - 1 || 1
        });
      }
    }
  };

  const handleTableChange = (pagination, { aircraft, distance, formattedTrackDuration, sos }) => {
    updateParams({
      aircraft,
      distanceFilteredValues: distance,
      durationFilteredValues: formattedTrackDuration,
      sosFilteredValues: sos,
      page: pagination.current
    });
  };

  const clearAllFilters = () => {
    dispatch({ type: 'selectedRowKeys', payload: [] });
    updateParams({
      distanceFilteredValues: false,
      durationFilteredValues: false,
      aircraftFilteredValues: [],
      sosFilteredValues: [],
      startTime: null,
      endTime: null,
      page: 1
    });
  };

  const onChange = selectedRowKeys => {
    dispatch({ type: 'selectedRowKeys', payload: selectedRowKeys });
  };
  const { orgs = [] } = userData;

  const onSelect = (record, selected) => {
    if (selected) {
      dispatchAll([
        { type: 'totalDuration', payload: totalDuration.add(record.unformattedTrackDuration) },
        { type: 'totalDistance', payload: totalDistance + record.rawDistance }
      ]);
    } else {
      dispatchAll([
        { type: 'totalDuration', payload: totalDuration.subtract(record.unformattedTrackDuration) },
        { type: 'totalDistance', payload: totalDistance - record.rawDistance }
      ]);
    }
  };

  const onSelectAll = (selected, selectedRows, changeRows) => {
    const [totalPageDuration, totalPageDistance] = changeRows.reduce(
      (acc, val) => [acc[0].add(val.unformattedTrackDuration), (acc[1] += val.rawDistance)],
      [initializeDuration(), 0]
    );
    if (selected) {
      dispatchAll([
        { type: 'totalDuration', payload: totalDuration.add(totalPageDuration) },
        { type: 'totalDistance', payload: totalDistance + totalPageDistance }
      ]);
    }

    if (!selected) {
      clearSelection();
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onChange,
    onSelect,
    onSelectAll
  };

  const userOrgIds = orgs.reduce((acc, o) => acc.concat(o.org.id), []);
  const isFlightReportEnabled = useFeatureFlag('flight-report', userOrgIds);
  const is3dReplayFeatureEnabled = useFeatureFlag('3d-replay', userOrgIds);

  const aircraftFilterDropdownProps = getFilterDropdown({
    onFilter: aircraftFilteredValues => updateParams({ aircraftFilteredValues, page: 1 }),
    displayName: 'aircraft',
    style: {
      width: 285
    },
    updateOnConfirm: true,
    showSearch: true
  });

  const durationFilterDropdownProps = getFilterDropdown({
    onFilter: durationFilteredValues => updateParams({ durationFilteredValues, page: 1 }),
    displayName: 'duration',
    updateOnConfirm: true
  });

  const distanceFilterDropdownProps = getFilterDropdown({
    onFilter: distanceFilteredValues => updateParams({ distanceFilteredValues, page: 1 }),
    displayName: 'distance',
    updateOnConfirm: true
  });

  const sosFilterDropdownProps = getFilterDropdown({
    onFilter: sosFilteredValues => updateParams({ sosFilteredValues, page: 1 }),
    displayName: 'SOS',
    updateOnConfirm: true
  });

  const columns = [
    {
      title: 'AIRCRAFT',
      dataIndex: 'aircraft',
      key: 'aircraft',
      filters: sortedAircraftFilterList,
      ...aircraftFilterDropdownProps(),
      filteredValue: aircraftFilteredValues,
      filterMultiple: true,
      width: '17%'
    },
    {
      title: 'FLIGHT ID',
      dataIndex: 'flightId',
      width: '10%'
    },
    {
      title: 'START TIME',
      dataIndex: 'startTime',
      width: '22%'
    },
    {
      title: 'DURATION',
      dataIndex: 'formattedTrackDuration',
      key: 'formattedTrackDuration',
      filters: [{ label: 'Greater than 1 min', value: true }],
      ...durationFilterDropdownProps(),
      filterIcon: FilterIconFunnel,
      filteredValue: durationFilteredValues ? [true] : [],
      width: '14%'
    },
    {
      title: 'DISTANCE',
      dataIndex: 'distance',
      key: 'distance',
      filters: [{ label: 'Aircraft moved', value: true }],
      ...distanceFilterDropdownProps(),
      filterIcon: FilterIconFunnel,
      filteredValue: distanceFilteredValues ? [true] : [],
      width: '17%'
    },
    {
      title: 'SOS',
      dataIndex: 'sosStatus',
      key: 'sos',
      filters: [
        { label: 'Active', value: 'active' },
        { label: 'Closed', value: 'closed' }
      ],
      filteredValue: sosFilteredValues,
      ...sosFilterDropdownProps(),
      filterIcon: FilterIconFunnel,
      width: '8.5%'
    }
  ];
  is3dReplayFeatureEnabled &&
    columns.push({
      title: <ReplayColumnHeader />,
      dataIndex: 'replayStatus',
      width: '11.5%',
      // eslint-disable-next-line react/display-name
      render: (replayStatus, rowData) => {
        return (
          <ReplayColumnCell
            {...{
              replayStatus,
              rowData,
              openPlayer: () => {
                const { aircraft, aircraftType, startTime } = rowData;
                if (aircraft === 'Registration unavailable' || aircraftType === null) {
                  return replayError();
                }

                dispatch({
                  type: 'viewTrack',
                  payload: {
                    trackId: rowData.id,
                    stId: rowData.key,
                    aircraft,
                    aircraftType,
                    startTime
                  }
                });
              }
            }}
          />
        );
      }
    });

  const onRow = ({ key: trackId }) => ({
    onClick: e => {
      e.preventDefault();
      isFlightReportEnabled
        ? history.push(`history/${trackId}/flight-report`)
        : history.push(`history/${trackId}`);
    }
  });

  const userOrgsRoleObj = orgs.reduce((acc, org) => ({ ...acc, [org.org.id]: org.role }), {});
  const selectedTracksInfo = trackData
    .filter(track => selectedRowKeys.includes(track.key))
    .map(track => {
      return { ...track, orgUserRole: userOrgsRoleObj[track.orgId] };
    });

  return (
    <React.Fragment>
      <div
        ref={tableContainer}
        className="px-5 py-1 pb-4"
        style={{ overflow: 'scroll', height: '100%', display: 'flex', flexDirection: 'column' }}
      >
        <PageHeader
          className="site-page-header"
          style={{ paddingLeft: '0px' }}
          title="Flight History"
          subTitle="View and manage flight history"
        />
        <div className="pb-3">
          <DateFilter
            ranges={getRanges()}
            calendarFieldsValues={[
              startTime === null ? undefined : startTime,
              endTime === null ? undefined : endTime
            ]}
            timezone={timezone}
            dateFormat={'YYYY-MM-DD'}
            handleChange={dates => {
              if (dates.length === 0) {
                updateParams({ startTime: null, endTime: null, page: 1 });
                return;
              }

              updateParams({ startTime: dates[0], endTime: dates[1], page: 1 });
            }}
          />
          <Button size="large" style={{ margin: '0 0 0 20px' }} onClick={clearAllFilters}>
            Clear Filters
          </Button>
        </div>
        <ConfigProvider getPopupContainer={() => document.body}>
          <Title
            history={history}
            selectedRowKeys={selectedRowKeys}
            clearSelection={clearSelection}
            totalDuration={totalDuration}
            totalDistance={totalDistance}
            distanceUnit={distanceUnit}
            dateFormat={dateFormat}
            timezone={timezone}
            downloadTracks={downloadTracks}
            deleteTracks={deleteTracks}
            selectedTracksInfo={selectedTracksInfo}
            downloadLimit={DOWNLOAD_LIMIT}
          />
          <div style={{ flex: '1' }}>
            <TableFullRowSelect
              bordered
              size="middle"
              dataSource={tableData}
              rowClassName={getRowClassName}
              columns={columns}
              loading={loadingTrackHistory}
              pagination={{
                current: page,
                pageSize: RESULTS_PER_PAGE,
                total: meta ? meta.total : 0
              }}
              onChange={handleTableChange}
              rowSelection={rowSelection}
              className="history-table"
              onRow={onRow}
              scroll={{ y: tableScrollHeight }}
            />
          </div>
        </ConfigProvider>
        <FlightPlayerModal
          {...{
            viewingTrack,
            onCancel: () => dispatch({ type: 'viewTrack', payload: undefined })
          }}
        ></FlightPlayerModal>
      </div>
    </React.Fragment>
  );
};

HistoryContainer.propTypes = {
  dateFormat: PropTypes.string,
  timezone: PropTypes.string,
  distanceUnit: PropTypes.string,
  userData: PropTypes.object
};

const mapStateToProps = state => ({
  dateFormat: getDateTimeFormat(state),
  timezone: getTimezone(state),
  distanceUnit: getDistanceUnit(state),
  userData: getUserData(state)
});

export default connect(mapStateToProps)(HistoryContainer);
