import useLocalStorage from './useLocalStorage';
import { useFeatureConfig } from './useFeatureConfig';

export const useFeatureFlag = (feature, organisationIds = []) => {
  const [iAmDeveloper] = useLocalStorage('iAmDeveloper', false);
  const featureConfig = useFeatureConfig();

  if (featureConfig.features[feature] === undefined) {
    console.info(
      'You have requested an undefined feature - Please define your feature in features.json',
      feature,
      featureConfig.features
    );
  }
  // has the browser been hijacked by a developer?
  if (iAmDeveloper) {
    return true;
  }

  // is the feature enabled globally?
  if (featureConfig.features[feature]) {
    return true;
  }

  // is the feature enabled at an org level?
  // Loop over all organisations in the feature config
  for (let orgid in featureConfig.organisations) {
    // check if we requested the feature for a matching org
    if (organisationIds.includes(orgid)) {
      // check if the matching org has the feature enabled
      if (featureConfig.organisations[orgid][feature]) {
        return true;
      }
    }
  }

  // else we have run out of things to check and the user still has no access
  return false;
};

// TODO: This should probably be considered temporary. It checks for a feature flag without considering
// `iAmDeveloper` status. Useful when you want to add a flag but don't want to break e2e tests that
// (currently, at least) use iAmDeveloper.
export const useNonDeveloperFlag = (feature, organisationIds = []) => {
  const featureConfig = useFeatureConfig();

  if (featureConfig.features[feature] === undefined) {
    console.info(
      'You have requested an undefined feature - Please define your feature in features.json'
    );
  }

  if (featureConfig.features[feature]) {
    return true;
  }

  for (let orgid in featureConfig.organisations) {
    if (organisationIds.includes(orgid)) {
      if (featureConfig.organisations[orgid][feature]) {
        return true;
      }
    }
  }

  return false;
};

export default useFeatureFlag;
