import React, { FunctionComponent, useEffect, useCallback } from 'react';
import io from 'socket.io-client';
import { connect } from 'react-redux';
import {
  populateUserData,
  populateUnreadSpidertxt,
  populateSosNotifications
} from '../../redux/reducers/navigationReducer/actions/thunk';
import { setUnreadSpidertxt } from '../../redux/reducers/navigationReducer/actions/index';
import { bindActionCreators } from 'redux';
import { THIRTY_SECONDS } from '../constants';
import { StandardDispatch, FullState } from '../../store';
import {
  getUserData,
  getSpiderTxtCount,
  getSosNotifications
} from '../../redux/selectors/userData';
import Pendo from '../../components/Pendo';
import { openNotification, notificationsToRemove } from '../../components/SosNotification';
import * as Sentry from "@sentry/browser";
import { setSentryUserContext } from '../../sentry';


const mapStateToProps = (state: FullState) => {
  const userData = getUserData(state);
  return {
    userData,
    userDataLoaded: userData && userData.id,
    spidertxtCount: getSpiderTxtCount(state),
    sosData: getSosNotifications(state)
  };
};

const mapDispatchToProps = (dispatch: StandardDispatch) => ({
  ...bindActionCreators(
    {
      populateUserData,
      setUnreadSpidertxt,
      populateUnreadSpidertxt,
      populateSosNotifications
    },
    dispatch
  )
});

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = ReturnType<typeof mapDispatchToProps>;
type IComponentProps = IStateProps & IDispatchProps;

export const PrivateDataLoader: FunctionComponent<IComponentProps> = ({
  sosData,
  userData,
  userDataLoaded,
  spidertxtCount,
  populateSosNotifications,
  populateUnreadSpidertxt,
  populateUserData,
  setUnreadSpidertxt
}) => {
  const socket: ReturnType<typeof io> = io(window.env.STL_NODE_ORIGIN, {
    transports: ['websocket']
  });

  const initSocket = useCallback(() => {
    socket.on('connect', () => {
      socket.emit('join', userData.id);
    });
    socket.on('spiderTxt', () => {
      setUnreadSpidertxt(spidertxtCount + 1);
    });
  }, [userData, spidertxtCount, setUnreadSpidertxt, socket]);

  useEffect(() => {
    populateUserData();
    populateUnreadSpidertxt();
    populateSosNotifications();
    // This setInterval is nessasary, PrivateDataLoader will poll every 30 secs for sosData and MapNavigation will compare newProps to prevProps and open a new open notification if needed
    const spidertxtInterval = setInterval(populateUnreadSpidertxt, THIRTY_SECONDS);
    const sosInterval = setInterval(populateSosNotifications, THIRTY_SECONDS);

    return () => {
      if (socket) {
        socket.close();
      }
      clearInterval(spidertxtInterval);
      clearInterval(sosInterval);
    };
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    initSocket();
  }, [userDataLoaded]);

  useEffect(() => {
    if (sosData && sosData.length > 0) {
      const idsArray = [];
      for (let i = 0; i < sosData.length; i += 1) {
        idsArray.push(sosData[i].id);
      }
      notificationsToRemove(idsArray);
      sosData.forEach(sosItem => {
        if (sosItem.tier === 'TWO') {
          openNotification(sosItem, true);
        } else {
          openNotification(sosItem, false);
        }
      });
    }
    if (sosData && sosData.length === 0) {
      notificationsToRemove([]);
    }
  }, [sosData]);

  if(userDataLoaded) {
    setSentryUserContext(userData)
  }

  return (userDataLoaded && <Pendo />) || null;
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateDataLoader);
