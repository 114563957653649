export const convertMetresTo = (metresValue, conversionUnit, decimalPlaces = 2) => {
  let convertedValue = metresValue;
  switch (conversionUnit) {
    case 'Nautical Miles': {
      convertedValue = (metresValue * 0.000539957).toFixed(decimalPlaces);
      break;
    }
    case 'Kilometres': {
      convertedValue = (metresValue / 1000).toFixed(decimalPlaces);
      break;
    }
    case 'Miles': {
      convertedValue = (metresValue / 1609.344).toFixed(decimalPlaces);
      break;
    }
    case 'Feet':
    case 'ft': {
      convertedValue = (metresValue * 3.28084).toFixed(decimalPlaces);
      break;
    }
    case 'Metres':
    case 'm': {
      convertedValue = parseFloat(metresValue).toFixed(decimalPlaces);
      break;
    }
    default: {
      throw new Error('unknown unit');
    }
  }
  return [Number(convertedValue), conversionUnit];
};

export const convertToMetres = (unitValue, conversionUnit, decimalPlaces = 2) => {
  let convertedValue = unitValue;
  switch (conversionUnit) {
    case 'Nautical Miles': {
      convertedValue = (unitValue / 0.000539957).toFixed(decimalPlaces);
      break;
    }
    case 'Kilometres': {
      convertedValue = (unitValue * 1000).toFixed(decimalPlaces);
      break;
    }
    case 'Miles': {
      convertedValue = (unitValue * 1609.344).toFixed(decimalPlaces);
      break;
    }
    case 'Feet':
    case 'ft': {
      convertedValue = (unitValue / 3.28084).toFixed(decimalPlaces);
      break;
    }
    case 'Metres':
    case 'm': {
      convertedValue = parseFloat(unitValue).toFixed(decimalPlaces);
      break;
    }
    default: {
      throw new Error('unknown unit');
    }
  }
  return [Number(convertedValue), conversionUnit];
};

export const abbreviateDistanceUnit = unit => {
  switch (unit) {
    case 'Nautical Miles':
      return 'NM';
    case 'Kilometres':
      return 'km';
    case 'Miles':
      return 'mi';
    case 'Feet':
      return 'ft';
    case 'Metres':
      return 'm';
    default: {
      return unit;
    }
  }
};

export const getGeofenceInfoAltitude = ({ altitudeUnit, altitudeContext, altitudeLimit }) => {
  if (!altitudeUnit || !altitudeContext) return '-';
  if (altitudeContext.toLowerCase().trim() === 'any') {
    return 'Any';
  } else {
    const altitudeConvertedToUserUnit = convertMetresTo(altitudeLimit, altitudeUnit);
    const context = altitudeContext[0].toUpperCase() + altitudeContext.substring(1).toLowerCase();
    return `${context} ${altitudeConvertedToUserUnit[0]} ${altitudeConvertedToUserUnit[1]}`;
  }
};

export const displayEventType = triggeringEvent => {
  switch (triggeringEvent) {
    case 'breach_in':
      return 'Entry';
    case 'breach_out':
      return 'Exit';
    default:
      return 'Both';
  }
};

export const convertEventType = triggeringEvent => {
  switch (triggeringEvent) {
    case 'entry':
      return 'breach_in';
    case 'exit':
      return 'breach_out';
    default:
      return 'breach_any';
  }
};

// ToDo: Since the API is doing a ceiling function and point speed in tracks is rounded up, we need to do the same.
// Once the API has been refactored to avoid this conversion, remove the ceiling and add the decimalPlaces support
// speedValue: speed in knots, conversionUnit: unit to convert to
export const convertKnotsTo = (speedValue, conversionUnit) => {
  if (isNaN(speedValue)) {
    return 0;
  }

  switch (conversionUnit) {
    case 'knots':
      return Math.ceil(speedValue);
    case 'km/h':
      return Math.ceil(speedValue * 1.852);
    case 'm/s':
      return Math.ceil(speedValue / 1.94384449);
    case 'mi/h':
      return Math.ceil(speedValue * 1.151);
    default: {
      throw new Error('unknown unit');
    }
  }
};

export default {
  convertMetresTo,
  getGeofenceInfoAltitude,
  displayEventType,
  convertEventType,
  convertKnotsTo,
  abbreviateDistanceUnit
};
