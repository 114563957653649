import { notification } from 'antd';
import React, { CSSProperties, FC, useEffect } from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { getSelectedDisplayPoint } from '../../../../redux/selectors/eventData';
import { getAircraftListVisibility } from '../../../../redux/selectors/mapData';
import { clearSelectedDisplayPoint } from '../../../../redux/slice/events';
import store from '../../../../store';
import { CloseIcon } from './CloseIcon';
import EventsPointInfoPanel from './EventsPointInfoPanel';
import PointInfoPanelParent from './styles/js/styled';
import './styles/scss/styles.scss';

const NOTIFICATION_KEY = 'PointInfoPanel';

const notificationStyle: CSSProperties = {
  borderRadius: '10px',
  position: 'relative',
  marginBottom: 0
};

interface EventsPointInfoPanelContainerProps {
  hidePanelOffset: boolean;
}

export const EventsPointInfoPanelContainer: FC<EventsPointInfoPanelContainerProps> = ({
  hidePanelOffset
}) => {
  const dispatch = useDispatch();

  const aircraftListVisibility = useSelector(getAircraftListVisibility);
  const selectedDisplayPoint = useSelector(getSelectedDisplayPoint);

  useEffect(() => {
    const openPointInfoPanel = () => {
      notification.open({
        closeIcon: <CloseIcon />,
        description: (
          <Provider store={store}>
            <EventsPointInfoPanel />
          </Provider>
        ),
        duration: 0,
        getContainer: () => document.getElementById(NOTIFICATION_KEY)!,
        key: NOTIFICATION_KEY,
        message: undefined, // annoying workaround
        onClose: closePointInfoPanel,
        placement: 'bottomLeft',
        style: notificationStyle
      });
    };

    if (selectedDisplayPoint) {
      openPointInfoPanel();
    } else {
      // Destroy if displayPoint ever set to undefined
      notification.destroy();
    }
  }, [selectedDisplayPoint]);

  useEffect(() => {
    return () => {
      // Seems to help prevent issues where the PIP occasionally will not render on flight report...
      // possibly a leftover panel still in the DOM from a previous render, uncertain what AntD is
      // doing with direct DOM access behind the scenes.
      notification.destroy();
    };
  }, []);

  const aircraftListVisible = hidePanelOffset ? false : aircraftListVisibility;
  const closePointInfoPanel = () => {
    notification.destroy();
    dispatch(clearSelectedDisplayPoint());
  };

  return <PointInfoPanelParent id={NOTIFICATION_KEY} aircraftListVisible={aircraftListVisible} />;
};

export default EventsPointInfoPanelContainer;
