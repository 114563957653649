import { FlightData, FlightDataPart } from './types/FlightData';

export function getCzmlParts(response: FlightData, duration: number = 300) {
  const parts: { [key: string]: FlightDataPart } = {};
  if (!!response) {
    const { trackStartTime, trackEndTime, from, to } = response;
    if (from && to && trackStartTime && trackEndTime) {
      parts[from - trackStartTime] = {
        range: [from - trackStartTime, to - trackStartTime],
        from,
        to
      };
      let partFrom = to;
      while (partFrom < trackEndTime) {
        const partTo = Math.min(partFrom + duration, trackEndTime);
        parts[partFrom - trackStartTime] = {
          range: [partFrom - trackStartTime, partTo - trackStartTime],
          from: partFrom,
          to: partTo
        };
        partFrom = partTo;
      }
    }
  }
  return parts;
}
